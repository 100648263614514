import React, {Fragment} from "react";
import './MediaList.css';


export default class IconItem extends React.Component {
    state = {
        isAvatarEditorActive: false,
    };

    render() {
        const {media} = this.props;
        if (!media || !media.isIcon) return null;

        const childWithProps = this.props.children ?
            React.cloneElement(this.props.children, this.props)
            : <Fragment/>;

        const language = media.language
        const flagImg = language ? <img className='SpeekFlag' src={`/flags/${language}.png`} alt='' /> : null

        return(
            <Fragment>
                <div className={'ObjectLISpeekContainer AvatarItemContainer' + (!media.isEnabled ? ' disabledBG' : '')}>
                    <div className='AIImgBlock likeBtn'>
                        <img className='AvatarItemImg' alt='' src={media.imageData} />
                    </div>
                    {flagImg}
                    Icon ({media.getDescription})

                    {childWithProps}
                </div>
            </Fragment>
        )
    }
}
