import React from "react";
import DescriptionItem from "./DescriptionItem";


export default class DescriptionList extends React.Component{

    render() {

        const {object, keySuffix = ''} = this.props;

        return (
            object.languageDepDescriptionList.map(description => {
                return (
                    <DescriptionItem
                        key={'Description_' + keySuffix + description.language + '_' + object.objectId}
                        object={object}
                        description={description.description}
                        language={description.language}>
                        {
                            this.props.children
                        }
                    </DescriptionItem>
                )
            })
        )
    }
}
