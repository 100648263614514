import React from 'react'
import './OptionsBar.css'
import {connect} from "react-redux";


class OptionsBar extends React.Component {

    render() {
        const {isLoggedIn} = this.props;

        return (
            <div id='OptionsBar' className={isLoggedIn ? '' : 'hided'}>
                {this.props.children}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    isLoggedIn: state.isLoggedIn
});
export default connect(mapStateToProps)(OptionsBar);
