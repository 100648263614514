import React from "react";
import "./AuthorPopUpInfo.css";
import StoreManager from "../../managers/StoreManager";
type PropsType = {
    authors: Record<string, any>,
    userId: string,
    onExit: Function,
    left: number,
    top: number,
    avatarData: string,
    onAuthorClick: Function
};


export default class AuthorPopUpInfo extends React.Component {

    constructor(props: PropsType) {
        super(props);
        this.props = props;
    }

    props: PropsType;

    componentDidUpdate(prevProps: any, prevState: any): void {
        const {authors, userId} = this.props;
        if (!userId || !authors) return;

        if (!authors[userId]) {
            StoreManager.updateAuthor(userId);
        }
    }

    render() {
        const {authors, userId, onExit, left, top, avatarData, onAuthorClick} = this.props;
        if (!userId || !authors) return <React.Fragment />;

        const user: Record<string, any> = authors[userId];
        if (!user) return <React.Fragment />;

        const name = user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : user.nickname;

        return (
            <div className='AuthorPopupBlock'
                 style={{left: left, top: top}}
                 onMouseLeave={e => onExit(e)}>
                    <div className="APAvatarBlock"
                         onClick={e => onAuthorClick(e)}>
                        <img src={avatarData} className="APAvatar" alt=''/>
                    </div>

                    <div className="APInfoBlock">
                        <b>{name}</b>
                        <br/><i>Objects:</i> {user.objectCount}
                        <br/><i>Rank:</i> {user.rate}%

                        <br/><i>Home page:</i> {user.homePage}
                        <br/><i>about:</i> {user.about}

                    </div>
            </div>
        );
    }
}





