import React from 'react';
import './ProgressBar.css';


export default class ProgressBar extends React.Component {

    render() {
        let {progress = null, label = "Progress"} = this.props;
        if (progress === null) return <React.Fragment/>;

        const fullWidth = 355;
        if (progress > 100) progress = 100;
        if (progress < 0) progress = 0;
        const doneWidth = Math.round(fullWidth / 100 * progress);

        return (
            <div className="ProgressContainer">
                <b>{label}</b> <br/>
                <div className="ProgressBG"/>
                <div className="ProgressDone"
                     style={{width: doneWidth}}/>
            </div>
        )
    }
}
