import React from "react";
import ModalWindow from "../ModalWindow/ModalWindow";
import {connect} from "react-redux";
import storeDispatcher from "../../storeDispatcher";
import './ImportJSON.css';
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import ObjectData from "../../models/ObjectData";


class ImportJSON extends React.Component {

    onClose() {
        storeDispatcher.updateImportingStructure(null);
        storeDispatcher.updateImportingChecks(null);
    }

    async serverCheckStructure(changedStructure) {
        if (!this.props.importingStructure) return;
        const {importingStructure: {_structure: structure}} = this.props;

        const actualStructure = changedStructure || structure;

        try{
            const structureForChecks = {
                parents: actualStructure.parents,
                authors: actualStructure.authors,
                groups: actualStructure.groups
            };

            const checks = await ServerManager.instance.checkImportingStructure(structureForChecks);
            storeDispatcher.updateImportingChecks(checks);
        } catch (e) {
            console.log(e);
        }
    }

    elementFound(blockName, id) {
        const {importingChecks} = this.props;
        if (!importingChecks) return false;

        return !!importingChecks[blockName][id];

    }

    onEditItem(blockName, id) {
        const label = blockName === 'authors' ? 'Enter email' : 'Enter id';

        InfoManager.waitTextDialog(label, '', 'Put here').then(
            newValue => {
                this.onChangeItemIdAndCheck(blockName, id, newValue);
            }
        ).catch(e => e);
    }

    onChangeItemIdAndCheck(blockName, id, value = null) {
        if (!this.props.importingStructure) return;
        const structureCopy = {...this.props.importingStructure};

        if (blockName === 'objects') {
            delete structureCopy._structure[blockName][id];
            // ... rebuild authors, parents list

        }

        if (blockName === 'groups') {
            if (!value) {
                delete structureCopy._structure[blockName][id];
            } else {
                structureCopy._structure[blockName][id].groupId = value;
                structureCopy._structure[blockName][value] = structureCopy._structure[blockName][id];
                delete structureCopy._structure[blockName][id];
            }

            for (let objectId in structureCopy._structure.objects) {
                if (structureCopy._structure.objects[objectId].tags.UserGroupId === id) {
                    structureCopy._structure.objects[objectId].tags.UserGroupId = value;
                    if (!value) delete structureCopy._structure.objects[objectId].tags.UserGroupId;
                }

            }
        }

        if (blockName === 'parents') {
            const index = structureCopy._structure[blockName].indexOf(id);
            structureCopy._structure[blockName][index] = value;

            for (let objectId in structureCopy._structure.objects) {
                if (structureCopy._structure.objects[objectId].tags.ParentObjectId === id) {
                    structureCopy._structure.objects[objectId].tags.ParentObjectId = value;
                }
            }
        }

        if (blockName === 'authors') {
            structureCopy._structure[blockName][id] = value;
        }

        storeDispatcher.updateImportingStructure(structureCopy);
        this.serverCheckStructure(structureCopy._structure).then();
    }

    elementFoundEditUI(blockName, id) {
        return  (
            <span>
                {this.elementFound(blockName, id) ? ' - found ' : <b className='ISDanger'>{' - not found '}</b>  }
                <i className='fas fa-pencil-alt likeBtn' onClick={() => this.onEditItem(blockName, id)}/>
            </span>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.importingStructure && this.props.importingStructure) {
            this.serverCheckStructure().then();
        }
    }

    onStartImport() {
        ServerManager.instance.startImportStructure(this.props.importingStructure._structure).then(
            answer => {
                if (answer) {
                    InfoManager.showMsg('Imported successfully !');
                    this.onClose();
                } else {
                    InfoManager.showMsg('Import error!');
                }
            }
        );
    }

    isImportSafe() {
        const {importingChecks} = this.props;
        if (!importingChecks) return false;

        if (Object.values(importingChecks.parents).includes(false)) return false;
        if (Object.values(importingChecks.groups).includes(false)) return false;
        if (Object.values(importingChecks.authors).includes(false)) return false;

        return true;
    }

    ImportButton() {
        if (this.isImportSafe()) return (
            <div className="likeBtn ISButton ISGreenBorder" onClick={()=>this.onStartImport()}>
                <i className="fas fa-file-upload"/> IMPORT
            </div>
        );

        return (
            <div className="likeBtn ISButton" onClick={()=>this.onStartImport()}>
                <i className="fas fa-exclamation-triangle"/> UNSAFE IMPORT
            </div>
        )
    }

    render() {
        if (!this.props.importingStructure) return <React.Fragment/>;
        const {importingStructure: {_structure: structure}, importingChecks} = this.props;

        console.log(structure, importingChecks);

        return (
            <ModalWindow isActive={true} onClose={() => this.onClose()}>
                <div className="centered ImportStructureContainer">
                    <h3>Import .JSON backup</h3>

                    <div className="ImportStructureBlock">
                        <b>objects</b>
                        <br/>
                        {
                            Object.values(structure.objects).map(object =>
                                <div className="ISObjectItem" key={'ISObject' + object.objectId}>
                                    <img src={ObjectData.findImage(object)}
                                         alt=""
                                         className={ObjectData.findImage(object) ? 'wrappedImg': 'hided'}/>

                                    <i className='far fa-image' />
                                    <i className="far fa-trash-alt likeBtn ISTopRight"
                                       onClick={()=>this.onChangeItemIdAndCheck('objects', object.objectId)}/>
                                </div>
                            )
                        }
                    </div>

                    <div className="ImportStructureBlock">
                        <b>parents</b>
                        <br/>
                        {
                            structure.parents.map(objectId =>
                                <span className="ISParentItem" key={'ISParent' + objectId}>
                                    {objectId}
                                    {this.elementFoundEditUI('parents', objectId)}
                                </span>
                            )
                        }
                    </div>

                    <div className="ImportStructureBlock">
                        <b>projects</b>
                        <br/>
                        {
                            Object.keys(structure.groups).map(groupId => {
                                const group = structure.groups[groupId];
                                return (
                                    <span className="ISParentItem" key={'ISProject' + groupId}>
                                        <i className="far fa-trash-alt likeBtn"
                                           onClick={()=>this.onChangeItemIdAndCheck('groups', groupId)}/>
                                        {group.description}
                                        {this.elementFoundEditUI('groups', groupId)}
                                    </span>
                                )
                            })
                        }
                    </div>

                    <div className="ImportStructureBlock">
                        <b>creators</b>
                        <br/>
                        {
                            Object.keys(structure.authors).map(userId => {
                                const email = structure.authors[userId];
                                return (
                                    <span className="ISParentItem" key={'ISCreator' + userId}>
                                    {email}
                                    {this.elementFoundEditUI('authors', userId)}
                                </span>
                                )
                            })
                        }
                    </div>

                    {this.ImportButton()}

                </div>
            </ModalWindow>
        )
    }

}


const mapStateToProps = state => ({
    importingStructure: state.importingStructure,
    importingChecks: state.importingChecks,
});
export default connect(mapStateToProps)(ImportJSON);