import React from "react";
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import md5 from "js-md5";
import Utils from "../../managers/Utils";
import ObjectSelector from "../ObjectSelector";


export default class ProfileInfo extends React.Component {

    ref = {
        nickname: React.createRef(),
        email: React.createRef(),
        firstName: React.createRef(),
        lastName: React.createRef(),
        homepage: React.createRef(),
        isPublic: React.createRef(),
        about: React.createRef(),

        password: React.createRef(),
        newPassword: React.createRef(),
        newPassword2: React.createRef(),
    };

    onSave() {
        const {user: {userId}} = this.props;

        console.log(this.ref);

        const information = {
            nickname: this.ref.nickname.current.value,
            email: this.ref.email.current.value,
            firstName: this.ref.firstName.current.value,
            lastName: this.ref.lastName.current.value,
            homePage: this.ref.homepage.current.value,
            public: this.ref.isPublic.current.checked,
            about: this.ref.about.current.value,
        };
        console.log(information);
        ServerManager.instance.editUserInfo(information, userId).then();
    }

    onPersonObjectSelect = objectId => {
        const {user: {userId}} = this.props;
        ServerManager.instance.assignObject(objectId, userId, null).then();
    };


    onChangePassword() {
        const password = this.ref.password.current.value;
        const newPassword = this.ref.newPassword.current.value;
        const newPassword2 = this.ref.newPassword2.current.value;

        if (newPassword.length < 5 || newPassword.length > 100) {
            InfoManager.showMsg('Wrong password length');
            return;
        }

        if (newPassword !== newPassword2) {
            InfoManager.showMsg('Retype new password');
            return;
        }

        ServerManager.instance.changeUserPassword(md5(password), md5(newPassword))
            .then(answer => {
                console.log(answer);

                if (!answer.status) {
                    InfoManager.showMsg(answer.message || 'Error');
                    return;
                }
                InfoManager.showMsg('Password changed');
                this.ref.password.current.value = '';
                this.ref.newPassword.current.value = '';
                this.ref.newPassword2.current.value = '';
            });

    }

    onRemoveProfile() {
        const {user: {userId}, itsMe} = this.props;

        InfoManager.confirmDialog('Are you sure ?')
            .then(() => {
                ServerManager.instance.removeUser(userId).then(answer => {
                    if (answer.status) {
                        InfoManager.showMsg('Profile was removed');
                        if (itsMe) {
                            Utils.signOut().then()
                        }
                    }
                })
            })
            .catch(() => {});
    }

    render() {
        const {user, isActive, itsMe} = this.props;
        if (!isActive) return <React.Fragment />;
        console.log(user)
        return (
            <div className="DoubleRightSide">
                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon fas fa-pencil-alt"/> <b>Profile information </b>
                    <br/>
                    <div className="DoubleInputBlock">
                        Nickname
                        <br/>
                        <input id="ProfileNicknameInput" className="DoubleInput" type="text"
                               ref={this.ref.nickname}
                               placeholder="Nickname" defaultValue={user.name}/>
                    </div>
                    <div className="DoubleInputBlock">
                        Email
                        {
                            user._data.emailChanging
                                ? <>
                                    &nbsp;
                                    <small> (Check <u>{user._data.emailChanging.email}</u> for confirmation)</small>
                                </>
                                : null
                        }
                        <br/>
                        <input id="ProfileEmailInput" className="DoubleInput" type="email"
                               ref={this.ref.email}
                               placeholder="name@domain.com" defaultValue={user.email}/>
                    </div>

                    <div className="DoubleInputBlock">
                        First name
                        <br/>
                        <input id="ProfileFirstNameInput" className="DoubleInput" type="text"
                               ref={this.ref.firstName}
                               placeholder="John" defaultValue={user.firstName}/>
                    </div>
                    <div className="DoubleInputBlock">
                        Last name
                        <br/>
                        <input id="ProfileLastNameInput" className="DoubleInput" type="text"
                               ref={this.ref.lastName}
                               placeholder="Smith" defaultValue={user.lastName}/>
                    </div>

                    <div className="DoubleFullWidth DoubleInputBlock">
                        Home page

                        <br/>
                        <input className="DoubleFullWidthInput DoubleInput"
                               placeholder="https://my-home-page.net" type="text"
                               ref={this.ref.homepage}
                               defaultValue={user.homePage}/>
                    </div>

                    <div className="DoubleInputBlock">
                        About
                        <span className='DoubleIBRightText' title="Make your account public">
                                Public author
                                <input id="ProfilePublic" type="checkbox"
                                       ref={this.ref.isPublic}
                                       defaultChecked={user.isPublic}/>
                             </span>
                        <br/>
                        <textarea className="DoubleInput"
                                  maxLength="250"
                                  ref={this.ref.about}
                                  defaultValue={user.aboutText}/>
                    </div>

                    <div className="DoubleInputBlock">
                        <div>
                            Object with personal information
                        </div>

                        <ObjectSelector selectedObjectId={user.userObjectId}
                                        filterTags={{ObjectClass: 'person'}}
                                        placeholder="Choose object that describe yourself"
                                        onSelect={this.onPersonObjectSelect} />

                    </div>

                    <div className="likeBtn DoubleButton" onClick={() => this.onSave()}>
                        Save changes
                    </div>
                </div>

                <div className={"DoubleRightBlock" + (itsMe ? '' : ' hided')}>
                    <i className="DoubleTextIcon fas fa-key"/> <b>Password change</b>
                    <br/>
                    <form>
                        <div className="nonFloat DoubleInputBlock">
                            Current password
                            <br/>
                            <input className="DoubleInput" type="password"
                                   ref={this.ref.password}
                                   placeholder="Type your password" autoComplete="current-password"/>
                        </div>
                        <div className="nonFloat DoubleInputBlock">
                            New password
                            <br/>
                            <input className="DoubleInput" type="password"
                                   ref={this.ref.newPassword}
                                   placeholder="Chose a new password" autoComplete="new-password"/>
                        </div>
                        <div className="nonFloat DoubleInputBlock">
                            New password again
                            <br/>
                            <input className="DoubleInput" type="password"
                                   ref={this.ref.newPassword2}
                                   placeholder="Repeat your new password" autoComplete="new-password"/>
                        </div>
                    </form>
                    <div className="likeBtn nonFloat DoubleButton"
                         onClick={() => this.onChangePassword()}>
                        Save new password
                    </div>
                </div>

                <div className="DoubleRightBlock ">
                    <i className="DoubleTextIcon far fa-trash-alt"/> <b>Profile removal</b>
                    <br/>
                    <div id="removeUserButton" data-user-id="5d8cd7b52807228b932416238e9eba70"
                         onClick={() => this.onRemoveProfile()}
                         className="likeBtn DoubleButton">
                        Remove profile
                    </div>
                </div>
            </div>
        )
    }
}
