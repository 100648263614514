
export type Structure = {
    objects: Record<string, any>,
    parents: Array<any>,
    groups: Record<string, any>,
    authors: Record<string, any>
}


export default class ImportedStructure {

    constructor(structure: Structure) {
        this._structure = structure;

        // for (let objectId in this._structure.objects) {
        //     if (!this._structure.objects.hasOwnProperty(objectId)) continue;
        //     this._structure.objects[objectId] = new ObjectData(this._structure.objects[objectId]);
        // }
    }

    private _structure: Structure;



}





