import React from "react";
import './Project.css';
import {connect} from "react-redux";
import StoreManager from "../../managers/StoreManager";
import ServerManager from "../../managers/ServerManager";


class MembershipRequestItem extends React.Component {

    onAccept() {
        const {request: {projectId, userId}} = this.props;
        ServerManager.instance.acceptMembership(projectId, userId).then();
    }

    onDecline() {
        const {request: {projectId, userId}} = this.props;
        ServerManager.instance.declineMembership(projectId, userId).then();
    }

    checkAndPreloadUserData() {
        const {users, request: {userId}} = this.props;
        const user = users[userId] || null;

        if (!user) {
            StoreManager.updateUser(userId);
        } else {
            this.user = user;
        }
    }

    render() {
        this.checkAndPreloadUserData();

        const {request} = this.props;
        if (!this.user) return <React.Fragment />;

        return(
            <div className="DoubleSmallBlock">
                <div className='DoubleSmallBlockAvatar' >
                    <img src={this.user.avatarData} alt=''/>
                </div>

                <div style={{textAlign: "center"}}>
                    <b>{this.user.fullName}</b>
                    <br/>
                    <u>Reason: {request.reason}</u>
                </div>
                <br/>

                <div className='likeBtn ProjectMembershipAccept'
                     onClick={() => this.onAccept()}>

                    Accept
                </div>

                <div className='likeBtn ProjectMembershipDecline'
                     onClick={() => this.onDecline()}>

                Decline
                </div>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    users: state.users
});
export default connect(mapStateToProps)(MembershipRequestItem);