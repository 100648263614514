import React from "react";
import './Option.css';
import ObjectData from "../../models/ObjectData";
import ServerManager from "../../managers/ServerManager";
import ContentFlagList from "./MediaEditor/ContentFlagList";
import ObservableSelect from "./ObservableSelect";
import AvatarEditor from "../AvatarEditor/AvatarEditor";
import ObjectSelector from "../ObjectSelector";
import PublicIdMenu from "./PublicIdMenu";
import InfoManager from "../../managers/InfoManager";
import storeDispatcher from "../../storeDispatcher";
import MapPreview from "../CommonMap/MapPreview";
import TargetPlatformList from "./MediaEditor/TargetPlatformList";


export default class Options extends React.Component {

    state = {
        isAvatarEditorActive: false,
    };

    onMapShow = (e, isActive = false, isReadOnly = true) => {
        try {
            e.stopPropagation();
            e.preventDefault();
        } catch (e) {}

        const position = this.props.object ? this.props.object.locationForMap : null;

        storeDispatcher.updateMapParams({
            isActive,
            isReadOnly,
            position
        });

    };

    onAvatarEditorShow(value = true) {
        this.setState({
            isAvatarEditorActive: value
        });
    }

    uploadAvatar(data) {
        const {object: {objectId}} = this.props;
        ServerManager.instance.uploadObjectAvatar(objectId, data.split('base64,')[1])
            .then(
                answer => console.log(answer)
            );
    }

    onInputChange(name, event) {
        try {
            const initialValue = this.props.object.tags[name];
            const value = event.target.value;
            const newValue = (value === initialValue) ? null : value;

            this.setState({
                ...this.state,
                [name]: newValue
            });
        } catch (e) {
            console.log(e);
        }
    }

    onInputLeave(name) {
        try {
            // send directly to the server
            if ([null, undefined].includes(this.state[name])){
                return;
            }

            const tagList = [{[name]: this.state[name] || false}];

            this.setTag(this.props.object.objectId, tagList);
            this.setState({
                ...this.state,
                [name]: null
            });
            console.log(this.state);
        } catch (e) {
            console.log(e);
        }
    }

    onInputCheck(name, event, onState, offState) {
        try {
            // send directly to the server
            const nextState = event.target.checked ? onState : offState;

            const tagList = [{[name]: nextState}];
            this.setTag(this.props.object.objectId, tagList);

        } catch (e) {
            console.log(e);
        }
    }

    onInputSelect(name, value) {
        try {
            // send directly to the server
            if (value === 'false') value = false;
            if (value === 'Undefined') value = undefined;

            const tagList = [{[name]: value}];
            this.setTag(this.props.object.objectId, tagList);
        } catch (e) {
            console.log(e);
        }
    }

    onKeyPressed(event, name) {
        if (event.key === "Enter") {
            this.onInputLeave(name);
        }
    }

    onPublicIdClick() {
        const {object} = this.props;

        if (object.publicId) {
            InfoManager.copyToClipboard(object.publicId);
            InfoManager.showMsg('Public Id copied to clipboard');
        } else {
            ServerManager.instance.setPublicId(object.objectId).then(
                answer => {
                    if (answer.status && answer.data) {
                        InfoManager.copyToClipboard(answer.data);
                        InfoManager.showMsg('Public Id copied to clipboard');
                    } else {
                        InfoManager.showMsg(answer.msg || 'Server error');
                        console.log(answer);
                    }
                }
            )
        }

    }

    setTag(objectId, tagList = []) {
        ServerManager.instance.setTags(this.props.object.objectId, null, null, null, null, tagList).then();
    }

    renderObservableInput = (name, inputType = 'text', placeholder='Type here ...') => {
        const {object} = this.props;
        const value = ![null, undefined].includes(this.state[name]) ? this.state[name] : object.tags[name] || '';

        return(
            <input className="ObjectLinkInput EditorInput"
                   placeholder={placeholder}
                   type='text'
                   value={value}
                   onBlur={() => {this.onInputLeave(name)}}
                   onKeyPress={(e) => {this.onKeyPressed(e, name)}}
                   onChange={(e) => {this.onInputChange(name, e)}}
            />
        )
    };

    renderObservableCheckbox = (name, onState, offState) => {
        const {object} = this.props;

            return (
                <input
                    type='checkbox'
                    checked={object.tags[name] === onState}
                    onChange={(e) => {
                        this.onInputCheck(name, e, onState, offState)
                    }}
                />
            )

    };

    onParentSelect(objectId) {
        const tagList = [{ParentObjectId: objectId || false}];
        this.setTag(this.props.object.objectId, tagList);
    }

    render() {
        const {object, projectList} = this.props;
        const project = projectList.find(item => item.projectId === object.projectId);
        const {contentFlagList} = project || {};
        const position = this.props.object ? this.props.object.locationForMap : [];

        // const selectedObjectId = this.state.hasOwnProperty('AuthorObjectId') ? this.state.AuthorObjectId : object.AuthorObjectId;

        return(
            <React.Fragment>
                <div className="EditorOption">
                    <span className="OptionKey">Object description</span>
                    <span className="OptionValue">
                        {this.renderObservableInput('Description')}
                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey">Hidden comment</span>
                    <span className="OptionValue">
                        {this.renderObservableInput('Comment')}
                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey">Object type</span>
                    <span className="OptionValue">
                        <ObservableSelect name='ObjectClass'
                                          value={object.class}
                                          optionList={ObjectData.classes.map(item => ({id: item[1], name: item[0]}))}
                                          keyPrefix='OptionSelect'
                                          onChange={(name, value) => this.onInputSelect(name, value)} />

                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey">User group</span>
                    <span className="OptionValue">
                        <ObservableSelect name='UserGroupId'
                                          value={object.projectId || 'GLOBAL'}
                                          optionList={projectList.map(item => ({id: item.projectId, name: item.description}))}
                                          keyPrefix='OptionSelect'
                                          onChange={(name, value) => this.onInputSelect(name, value)} />
                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey">Activation role</span>
                    <span className="OptionValue">
                        <ObservableSelect name='ActivationRole'
                                          value={object.tags.ActivationRole}
                                          optionList={ObjectData.activationRoles}
                                          keyPrefix='OptionSelect'
                                          onChange={(name, value) => this.onInputSelect(name, value)} />
                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey">Is part of</span>
                    <span className="OptionValue">
                        <ObjectSelector selectedObjectId={object.tags.ParentObjectId}
                                        sortByEditingObjectPreferences={true}
                                        placeholder="Choose parent object"
                                        onSelect={val => this.onParentSelect(val)}/>
                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey"><i className="fas fa-map-pin"/> Object location</span>
                    <span className="OptionValue">
                        <MapPreview location={position || []}
                                    onClick={(e) => this.onMapShow( e,true, false)}/>

                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey"><i className="fas fa-headphones-alt"/> Silent mode</span>
                    <span className="OptionValue">
                        {this.renderObservableCheckbox('RequireHeadset', 'true', undefined)}
                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey"><i className="far fa-eye"/> Is enabled</span>
                    <span className="OptionValue">
                        {this.renderObservableCheckbox('IsEnabled', undefined, 'false')}
                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey"><i className="far fa-lock"/> Need unlock</span>
                    <span className="OptionValue">
                        {this.renderObservableCheckbox('NeedUnlock', 'true', undefined)}
                    </span>
                </div>

                <div className="EditorOption">
                    <span className="OptionKey"><i className="fas fa-qrcode"/> QR Code, public id</span>
                    <span className="OptionValue">
                        <b className='likeBtn'
                           onClick={() => this.onPublicIdClick()}
                           title={object.publicId ? 'Click to copy' : 'Click to generate Public Id'}>
                            {object.publicId || '******'}
                        </b>
                    </span>
                    {object.publicId ? <PublicIdMenu object={object} /> : null}
                </div>

                <div className="EditorOption">
                    <ContentFlagList
                        contentFlagList={contentFlagList}
                        tags={object.tags}
                        onInputCheck={(name, event, on, off) => this.onInputCheck(name, event, on, off)}
                    />
                </div>

                <div className="EditorOption">
                    <TargetPlatformList
                        contentFlagList={contentFlagList}
                        tags={object.tags}
                        onInputCheck={(name, event, on, off) => this.onInputCheck(name, event, on, off)}
                    />
                </div>

                <AvatarEditor
                    onSave={data => this.uploadAvatar(data)}
                    onClose={() => this.onAvatarEditorShow(false)}
                    isActive={this.state.isAvatarEditorActive}
                    src={object.avatarImageData}
                />

            </React.Fragment>
        )
    }

}
