import React from "react";
import {connect} from "react-redux";
import StoreManager from "../../managers/StoreManager";
import TaggableData from "../../models/TaggableData";
import ObjectData from "../../models/ObjectData";
import ServerManager from "../../managers/ServerManager";
import MediaData from "../../models/MediaData";
import SpeekItem from "../ObjectList/standard-mode/SpeekItem";
import InfoManager from "../../managers/InfoManager";


class PendingAction extends React.Component {

    acceptAction(id, isApproved = true) {
        if (this.props.isActive) {
            console.log(id, isApproved);
            ServerManager.instance[isApproved ? 'acceptPending' : 'declinePending'](id)
                .then(() => StoreManager.updatePendingData(this.props.object.objectId));
        } else {
            InfoManager.showMsg(
                'You should accept pending actions in order'
            );
        }
    }

    _renderActionName(name) {
        switch (name) {
            case 'addNewObject': return 'adding object';
            case 'addNewSoundtrack': return 'adding soundtrack';
            case 'setSoundtrackData': return 'rerecord of soundtrack';
            case 'addNewSight': return 'add activator';
            case 'remove': return 'removal';
            case 'setTags': return  'setting tags';

            default: return name;
        }
    }

    _renderOperandType() {
        let operand;
        const {action: {queryName, data}} = this.props;

        switch(queryName) {
            case 'remove': operand = data.operand; break;
            case 'addNewSight': operand = null; break;
            case 'setTags': operand = data.mediaId ? 'media' : data.activatorId ? 'activator' : 'object'; break;

            default: operand = 'object';
        }


        return operand ? <div>Operand: <b>{operand}</b> <br/></div> : null
    }

    _renderPreviewTags() {
        // const tags = Object.keys(item.data.data).map(key => ({'name': key, 'value': item.data.data[key]}) );

        const tags = Object.entries(this.props.action.data.data);
        const check = <i className="font8 space5 fas fa-check green" />;
        const uncheck = <i className="font8 space5 fas fa-times red" />;

        const content = tags.map(([name, value]) => {

            if (name.includes('Author_')) {

                const lang = name.split('_')[1];
                return (<div className='ObjectDescription'>
                            <img className="DescriptionHead" src={`flags/${lang}.png`}  alt=''/>
                            Author {value ? '- ' + value : uncheck}
                        </div>);
            } else if (name.includes('Description_')) {

                const lang = name.split('_')[1];
                return <div className='ObjectDescription'>
                    <img className="DescriptionHead" src={`flags/${lang}.png`}  alt=''/>
                    Description {value ? '- ' + value : uncheck}
                </div>;
            } else if (name === 'RequireHeadset') {

                return <div className='ObjectDescription'>
                        <i className="DescriptionHead fas fa-headphones-alt" />
                        Silent mode {value ? check : uncheck}
                       </div>;

            } else if (name.includes('ContentFlag_')) {

                const flag = name.split('_')[1];
                return <div className='ObjectDescription'>
                        {flag} -
                        {value ? check : uncheck}
                       </div>;

            } else if (name === 'VideoUrl') {

                return <div className='ObjectDescription'>
                        <i className="DescriptionHead fab fa-youtube" />
                        Video - {value ? value : uncheck}
                       </div>;
            } else if (name === 'ObjectPediaUrl') {

                return <div className='ObjectDescription'>
                            <i className="DescriptionHead fab fa-wikipedia-w" />
                            Wikipedia - {value ? value : uncheck}
                       </div>
            } else if (name === 'AuthorPediaUrl') {

                return <div className='ObjectDescription'>
                        <i className="DescriptionHead fas fa-feather-alt" />
                        Author page - {value ? value : uncheck}
                       </div>;
            } else if (name === 'AuthorPediaUrl') {

                return <div className='HomePageUrl'>
                        <i className="DescriptionHead fas fa-feather-alt" />
                        Home page - {value ? value : uncheck}
                       </div>;
            } else if (name === 'UserGroupId') {

                const button = <i className="OpenGroupPage likeBtn fas fa-link"> Open</i>;
                return <div className='ObjectDescription'>
                          <i className="DescriptionHead fas fa-users" />
                          Project - {value ? ` - ${button}` : uncheck}
                       </div>;
            } else if (name === 'AvatarImageUrl') {

                const preview = <img className="PendingAvatar likeBtn ObjectAvatarImg" src={value} alt=''/>;

                return <div className='ObjectDescription'>
                    ${!value ? <i className="DescriptionHead far fa-image" /> : ''}
                    Avatar URL {value ? preview : uncheck}
                </div>;
            } else if (name === 'ParentObjectId') {

                const button = <i title="Search" className="FindObjectById likeBtn fas fa-search" data-object-id={value} />;
                return <div className='ObjectDescription'>
                           <i className="DescriptionHead fas fa-arrow-up" />
                           Parent {value ? `- ${button} ${value}` : uncheck}
                       </div>;
            } else if (name === 'ObjectCoords') {

                const coords = value; //ObjectLocationMap.coordsFromTag(value);
                return <div className='ObjectDescription'>
                           <i data-coords={coords} className="DescriptionHead objectOnMap likeBtn fas fa-map-marker-alt" />
                           Location
                       </div>;
            } else if (name === 'ObjectClass') {

                const classes = ObjectData.classes;
                const className = classes.reduce((acc, cur) => {
                    if (cur[1] === value) acc =  cur[3];
                    return acc;
                }, null);

                return <div className='ObjectDescription'>
                           <i className={`DescriptionHead fas fa-${className}`} />
                           Class - {value ?  value : uncheck}
                       </div>;
            } else {

                return <div className='ObjectDescription'>
                           <i className="DescriptionHead fas fa-code" />
                           {name} - {value ? value : uncheck}
                       </div>;
            }
        });

        return <div className='pendTagsSet'>
                   {content.map((it, i) => (
                       <React.Fragment key={this.props.action.id + i}>
                           {it}
                       </React.Fragment>
                   ))}
               </div>;
    }

    _renderAddSpeak() {
        if (!this.props.action.data || !this.props.action.data.mediaId) {
            console.log(this.props.action);
            return null;
        }
        const media = new MediaData(this.props.action.data);

        return <SpeekItem media={media} />
    }

    _renderRemoval() {
        const {object, action: {data: {operand, id}}} = this.props;
        if (!object) return;

        if (operand === 'soundtrack' || operand === 'media') {
            console.log(object);
            const media = object.mediaEntries.find(it => it.mediaId === id);

            return <React.Fragment>
                       {!media ? 'Media not found in this object' : null}
                       <SpeekItem media={media} />
                   </React.Fragment>
        } else if (operand === 'object') {

        }

    }

    renderChangeList() {
        const {queryName} = this.props.action;

        switch (queryName) {
            case 'setTags': return this._renderPreviewTags();
            case 'remove': return this._renderRemoval();
            case 'addNewSoundtrack': return this._renderAddSpeak();

            default: return 'Unknown changes type.';
        }

    }

    render() {
        const {isActive, action, creators} = this.props;
        if (!action) return null;

        const {id, data: {id: actionId}, /*object,*/ comment, userId, queryName, timestamp = 0} = action;

        const timeMark = TaggableData.isoDateTime(timestamp);

        const creator = creators[userId];
        if (!creator) StoreManager.updateCreator(userId);

        return (
            <div className='PendingActionBlock'>
                <div className='PendingActionAuthor'>
                    <div className='PendingActionAvatar'>
                        <img alt='' src={creator ? creator.avatarImageData : null}/>
                    </div>
                    <div className='PendingActionAuthorName'>
                        {creator ? creator.name : 'Unknown'}
                    </div>
                </div>

                <div className='PendingActionContent'>
                    <div className='PendingActionComment PendingActionCommentSmall'>
                        {comment}
                        <small>
                            {timeMark}
                        </small>
                    </div>

                    <div>
                        Action: <b>{this._renderActionName(queryName)}</b>
                    </div>

                    {this._renderOperandType()}

                    <div>
                        {
                            this.renderChangeList()
                        }
                    </div>
                </div>

                <div className='PendingActionControl'>
                    <div className={'PendingActionControlOk' + (isActive ? ' likeBtn' : ' PendingDisabled')}
                         onClick={() => this.acceptAction(id || actionId, true)}>
                        <i className="fas fa-check" />
                    </div>
                    <div className={'PendingActionControlNo' + (isActive ? ' likeBtn' : ' PendingDisabled')}
                         onClick={() => this.acceptAction(id || actionId, false)}>
                        <i className="fas fa-times" />
                    </div>
                </div>

            </div>
        )
    }
}


const mapStateToProps = ({creators, objects}) => ({creators, objects});
export default connect(mapStateToProps)(PendingAction);
