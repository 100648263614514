import React from "react";
import {connect} from "react-redux";
import Invitation from "../components/Invitation";


class InvitationPage extends React.Component {

    render() {
        const {me, match: {params: {code}}} = this.props;
        return <Invitation loggedIn={!!me.userId} code={code} me={me}/>
    }
}


const mapStateToProps = state => ({
    me: state.me
});

export default connect(mapStateToProps)(InvitationPage);
