import React from "react";
import {connect} from "react-redux";
import './AudioPlayer.css';
import AudioManager from "../../managers/AudioManager";
import InfoManager from "../../managers/InfoManager";


class AudioPlayer extends React.Component {

    onRepeat() {
        AudioManager.repeat();
    }

    onStop() {
        AudioManager.stop();
    }

    onDownload() {
        const {mediaId} = this.props;
        if (!mediaId) return;

        const fileName = mediaId + (AudioManager.isAudio ? '.mp3' : '.txt');
        InfoManager.downloadDataAsFile(AudioManager.downloadData, fileName);
    }

    render() {
        const {mediaId} = this.props;
        if (!mediaId) return <React.Fragment/>;

        return (
            <div className='APContainer'>
                <i className="likeBtn far fa-play-circle" onClick={() => this.onRepeat()}/>
                <i className="likeBtn far fa-stop-circle" onClick={() => this.onStop()}/>
                <i className="likeBtn far fa-arrow-alt-circle-down" onClick={() => this.onDownload()}/>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    mediaId: state.playingSpeekId
});
export default connect(mapStateToProps)(AudioPlayer);