import React from "react";
type PropsType = {
    isActive: boolean,
    onClose: Function,
    children?: any,
    className?: string
};


export default class ModalWindow extends React.Component<PropsType> {

    onClose(e: any) {
        const {onClose} = this.props;
        if (e.target && e.target.className && e.target.className.includes && e.target.className.includes('modalWindow')) onClose(e);
    }

    render() {
        const {isActive, onClose, children, className = ''} = this.props;
        if (!isActive) return null

        return (
            <div className={'modalWindow' + className} onMouseDown={e => this.onClose(e)}>
                {children}
                <i className="likeBtn closeButton fas fa-times" onMouseDown={e => onClose(e)}/>
            </div>
        )
    }
}
