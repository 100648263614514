import React from "react";
import './Activator/Activator.css';
import './ObjectEditor.css';
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import storeDispatcher from "../../storeDispatcher";
import StoreManager from "../../managers/StoreManager";
import MediaControl from "../../modelControllers/MediaControl";
import PopupMenu from "../PopupMenu";


export default class MediaMenu extends React.Component {

    onHide() {
        const {media} = this.props;
        MediaControl.hide(media).then();
    }

    onRemove() {
        const {media} = this.props;
        const objectId = media.parent.objectId || media.parent.parent.objectId;

        console.log(media);
        if (media.hasChildren) {
            InfoManager.showMsg('You should remove all child soundtracks firstly.', 4000);
            return;
        }

        InfoManager.confirmDialog('Are you sure ?')
            .then(() => {
                ServerManager.instance.removeItem('track', media.mediaId, objectId)
                    .then();
            })
            .catch(() => {});
    }

    onEdit(e) {
        const { media: {mediaId, objectId, fileId, fileData, textFileId, textData}} = this.props;

        if ((fileId && !fileData) || (textFileId && !textData)) {
            StoreManager.updateObjectSpeekData(objectId, mediaId).then(() => storeDispatcher.mediaEdit(mediaId));
        }
        else {
            storeDispatcher.mediaEdit(mediaId);
        }

        if (e) {
            e.preventDefault();
        }
    }

    onMoveUp() {
        const {media} = this.props;
        MediaControl.moveUp(media).then();
    }

    onMoveDown() {
        const {media} = this.props;
        MediaControl.moveDown(media).then();
    }

    onAddSub() {
        const {media} = this.props;
        storeDispatcher.mediaEdit({mediaId: false, parentId: media.mediaId});
    }

    onSetMainAvatar() {
        const {media} = this.props

        const objectId = (media.parentObject || {}).objectId
        const mediaId = media.mediaId

        if (!objectId || !mediaId) {
            return console.error("onSetMainAvatar() error")
        }

        const tagList = [{
            "AvatarMediaId": media.isMainAvatar ? false : mediaId
        }]
        ServerManager.instance.setTags(objectId, null, null, null, null, tagList).then();
    }

    render() {
        const {media} = this.props

        if (!media.isSpeak) return (
            <PopupMenu iconClassName='ActivatorMenuIcon SpeekMenuIcon likeBtn fas fa-bars'
                       onContextMenu={(e) => this.onEdit(e)}
                       className='ItemShortMenu SpeekShortMenu'>
                {
                    media.isAvatar &&
                        <i className="likeBtn far fa-image"
                           title={`${media.isMainAvatar ? 'Unset' : 'Set'} as main avatar`}
                           onClick={() => this.onSetMainAvatar()} />
                }
                <i className="likeBtn far fa-trash-alt" onClick={() => this.onRemove()} />
                <i className="likeBtn far fa-eye-slash" title='Hide / show' onClick={() => this.onHide()} />
                <i className="likeBtn fas fa-pencil-alt"  onClick={() => this.onEdit()} />
            </PopupMenu>
        );

        return (
            <PopupMenu iconClassName='ActivatorMenuIcon SpeekMenuIcon likeBtn fas fa-bars'
                       onContextMenu={(e) => this.onEdit(e)}
                       className='ItemShortMenu SpeekShortMenu'>
                <i className="likeBtn far fa-trash-alt" onClick={() => this.onRemove()} />
                <i className="likeBtn fas fa-long-arrow-alt-up" title='Move up' onClick={() => this.onMoveUp()} />
                <i className="likeBtn fas fa-long-arrow-alt-down" title='Move down' onClick={() => this.onMoveDown()} />
                <i className="likeBtn far fa-eye-slash" title='Hide / show' onClick={() => this.onHide()} />
                <i className={media.isSubTrack ? 'hided' : "likeBtn fas fa-plus"}
                   title='Add sub media'
                   onClick={() => this.onAddSub()} />
                <i className="likeBtn fas fa-pencil-alt"  onClick={() => this.onEdit()} />
            </PopupMenu>
        );
    }

}
