import React from "react";
import './Profile.css';
import ProfileInfo from "./ProfileInfo";
import InfoManager from "../../managers/InfoManager";
import FilterManager from "../../managers/FilterManager";
import {Link} from "react-router-dom";
import ProfileAdministrative from "./ProfileAdministrative";
import ProfileEmail from "./ProfileEmail";
import ServerManager from "../../managers/ServerManager";
import AvatarEditor from "../AvatarEditor/AvatarEditor";
import AdditionalTagEditor from "../AdditionalTagEditor/AdditionalTagEditor";
import {Rights} from "../../models/TaggableData";


export default class Profile extends React.Component {

    state = {
        activePage: 'info',  // info, administrative, email
        isAvatarEditorActive: false,
        isTagsEditorActive: false
    };

    onAvatarEditorShow(value = true) {
        this.setState({
            isAvatarEditorActive: value
        });
    }

    onTagsEditorShow(value = true) {
        this.setState({
            isTagsEditorActive: value
        });
    }

    uploadAvatar(data) {
        const {user: {userId}} = this.props;
        ServerManager.instance.uploadUserAvatar(userId, data.split('base64,')[1])
            .then();
    }

    changePage(name) {
        this.setState({activePage: name});
    }

    showQRCode() {
        const {user: {userId}} = this.props;
        InfoManager.showLoginQRCode(userId);
    }

    filterByUser() {
        const {user: {userId}} = this.props;
        FilterManager.set('userId', userId);
    }

    render() {
        const {user, itsMe, me} = this.props;
        const isAdmin = me && me._data && me.hasRight(Rights.ADMIN);
        if (!user) return <React.Fragment />;

        return (
            <div className='MainContainer'>

                <div className="DoubleLeftSide">
                    <div className="DoubleLeftHead">
                        <span className="DoubleAvatar" onClick={() => this.onAvatarEditorShow(true)}>
                            <img src={user.avatarData} alt=''/>
                        </span>

                        <div className="DoubleHeadInfo">
                            <b>{user.fullName.toUpperCase()}</b> <br/>
                            <small>Registered {user.registrationDate}</small><br/>
                            <small>Last seen {user.lastVisitDate}</small>
                        </div>

                        <i id='ProfileAuthQRCode' className={"likeBtn fas fa-qrcode" + (itsMe || me.isSuperAdmin ? '' : ' hided')}
                           onClick={() => this.showQRCode()}
                           title="QR Code for authorization from mobile application"/>

                        <Link to='/objects' id="ProfileAddFilter" onClick={() => this.filterByUser()}>
                            <i title="Show user objects" className="likeBtn fas fa-filter"/>
                        </Link>

                        <i id='ProfileTagsEditorIcon'
                           className={"likeBtn fas fa-tags" + (me.isSuperAdmin ? '' : ' hided')}
                           onClick={() => this.onTagsEditorShow(true)}
                           title="Tags"/>

                    </div>

                    <div className=" likeBtn DoubleLeftBlock"
                         onClick={() => this.changePage('info')} >
                        <i className="fas fa-pencil-alt ProfileLeftIcon"/>
                        Profile edit page
                    </div>

                    <div className={"likeBtn DoubleLeftBlock" + (isAdmin?'':' hided')}
                         onClick={() => this.changePage('administrative')} >
                        <i className="fas fa-user-shield ProfileLeftIcon"/>
                        Administrative page
                    </div>

                    <div className={"likeBtn DoubleLeftBlock" + (isAdmin?'':' hided')}
                         onClick={() => this.changePage('email')} >
                        <i className="fas fa-at ProfileLeftIcon"/>
                        Send email
                    </div>
                </div>

                <AdditionalTagEditor userId={user.userId}
                                     isActive={this.state.isTagsEditorActive}
                                     onClose={() => this.onTagsEditorShow(false)}/>

                <AvatarEditor onSave={data => this.uploadAvatar(data)}
                              onClose={() => this.onAvatarEditorShow(false)}
                              isActive={this.state.isAvatarEditorActive}
                              src={user.avatarData}/>

                <ProfileInfo user={user} itsMe={itsMe} isActive={this.state.activePage === 'info'} />
                <ProfileAdministrative user={user} isActive={this.state.activePage === 'administrative'} />
                <ProfileEmail user={user} isActive={this.state.activePage === 'email'} />

            </div>
        );
    }

}
