// UI components without relations to the React
// Written on pure JS-DOM

import Utils from "./Utils";
import ServerManager from "./ServerManager";


export default class InfoManager {

    static usedLanguages = []

    static lastAddedObjectGroupId = null

    static get countryList() {
        return [
            {'code': 'independent', 'name': 'Independent'},
            {'code': 'AF', 'name': 'Afghanistan'},
            {'code': 'AX', 'name': 'Aland Islands'},
            {'code': 'AL', 'name': 'Albania'},
            {'code': 'DZ', 'name': 'Algeria'},
            {'code': 'AS', 'name': 'American Samoa'},
            {'code': 'AD', 'name': 'Andorra'},
            {'code': 'AO', 'name': 'Angola'},
            {'code': 'AI', 'name': 'Anguilla'},
            {'code': 'AQ', 'name': 'Antarctica'},
            {'code': 'AG', 'name': 'Antigua And Barbuda'},
            {'code': 'AR', 'name': 'Argentina'},
            {'code': 'AM', 'name': 'Armenia'},
            {'code': 'AW', 'name': 'Aruba'},
            {'code': 'AU', 'name': 'Australia'},
            {'code': 'AT', 'name': 'Austria'},
            {'code': 'AZ', 'name': 'Azerbaijan'},
            {'code': 'BS', 'name': 'Bahamas'},
            {'code': 'BH', 'name': 'Bahrain'},
            {'code': 'BD', 'name': 'Bangladesh'},
            {'code': 'BB', 'name': 'Barbados'},
            {'code': 'BY', 'name': 'Belarus'},
            {'code': 'BE', 'name': 'Belgium'},
            {'code': 'BZ', 'name': 'Belize'},
            {'code': 'BJ', 'name': 'Benin'},
            {'code': 'BM', 'name': 'Bermuda'},
            {'code': 'BT', 'name': 'Bhutan'},
            {'code': 'BO', 'name': 'Bolivia'},
            {'code': 'BA', 'name': 'Bosnia And Herzegovina'},
            {'code': 'BW', 'name': 'Botswana'},
            {'code': 'BV', 'name': 'Bouvet Island'},
            {'code': 'BR', 'name': 'Brazil'},
            {'code': 'IO', 'name': 'British Indian Ocean Territory'},
            {'code': 'BN', 'name': 'Brunei Darussalam'},
            {'code': 'BG', 'name': 'Bulgaria'},
            {'code': 'BF', 'name': 'Burkina Faso'},
            {'code': 'BI', 'name': 'Burundi'},
            {'code': 'KH', 'name': 'Cambodia'},
            {'code': 'CM', 'name': 'Cameroon'},
            {'code': 'CA', 'name': 'Canada'},
            {'code': 'CV', 'name': 'Cape Verde'},
            {'code': 'KY', 'name': 'Cayman Islands'},
            {'code': 'CF', 'name': 'Central African Republic'},
            {'code': 'TD', 'name': 'Chad'},
            {'code': 'CL', 'name': 'Chile'},
            {'code': 'CN', 'name': 'China'},
            {'code': 'CX', 'name': 'Christmas Island'},
            {'code': 'CC', 'name': 'Cocos (Keeling) Islands'},
            {'code': 'CO', 'name': 'Colombia'},
            {'code': 'KM', 'name': 'Comoros'},
            {'code': 'CG', 'name': 'Congo'},
            {'code': 'CD', 'name': 'Congo, Democratic Republic'},
            {'code': 'CK', 'name': 'Cook Islands'},
            {'code': 'CR', 'name': 'Costa Rica'},
            {'code': 'CI', 'name': 'Cote D\'Ivoire'},
            {'code': 'HR', 'name': 'Croatia'},
            {'code': 'CU', 'name': 'Cuba'},
            {'code': 'CY', 'name': 'Cyprus'},
            {'code': 'CZ', 'name': 'Czech Republic'},
            {'code': 'DK', 'name': 'Denmark'},
            {'code': 'DJ', 'name': 'Djibouti'},
            {'code': 'DM', 'name': 'Dominica'},
            {'code': 'DO', 'name': 'Dominican Republic'},
            {'code': 'EC', 'name': 'Ecuador'},
            {'code': 'EG', 'name': 'Egypt'},
            {'code': 'SV', 'name': 'El Salvador'},
            {'code': 'GQ', 'name': 'Equatorial Guinea'},
            {'code': 'ER', 'name': 'Eritrea'},
            {'code': 'EE', 'name': 'Estonia'},
            {'code': 'ET', 'name': 'Ethiopia'},
            {'code': 'FK', 'name': 'Falkland Islands (Malvinas)'},
            {'code': 'FO', 'name': 'Faroe Islands'},
            {'code': 'FJ', 'name': 'Fiji'},
            {'code': 'FI', 'name': 'Finland'},
            {'code': 'FR', 'name': 'France'},
            {'code': 'GF', 'name': 'French Guiana'},
            {'code': 'PF', 'name': 'French Polynesia'},
            {'code': 'TF', 'name': 'French Southern Territories'},
            {'code': 'GA', 'name': 'Gabon'},
            {'code': 'GM', 'name': 'Gambia'},
            {'code': 'GE', 'name': 'Georgia'},
            {'code': 'DE', 'name': 'Germany'},
            {'code': 'GH', 'name': 'Ghana'},
            {'code': 'GI', 'name': 'Gibraltar'},
            {'code': 'GR', 'name': 'Greece'},
            {'code': 'GL', 'name': 'Greenland'},
            {'code': 'GD', 'name': 'Grenada'},
            {'code': 'GP', 'name': 'Guadeloupe'},
            {'code': 'GU', 'name': 'Guam'},
            {'code': 'GT', 'name': 'Guatemala'},
            {'code': 'GG', 'name': 'Guernsey'},
            {'code': 'GN', 'name': 'Guinea'},
            {'code': 'GW', 'name': 'Guinea-Bissau'},
            {'code': 'GY', 'name': 'Guyana'},
            {'code': 'HT', 'name': 'Haiti'},
            {'code': 'HM', 'name': 'Heard Island & Mcdonald Islands'},
            {'code': 'VA', 'name': 'Holy See (Vatican City State)'},
            {'code': 'HN', 'name': 'Honduras'},
            {'code': 'HK', 'name': 'Hong Kong'},
            {'code': 'HU', 'name': 'Hungary'},
            {'code': 'IS', 'name': 'Iceland'},
            {'code': 'IN', 'name': 'India'},
            {'code': 'ID', 'name': 'Indonesia'},
            {'code': 'IR', 'name': 'Iran, Islamic Republic Of'},
            {'code': 'IQ', 'name': 'Iraq'},
            {'code': 'IE', 'name': 'Ireland'},
            {'code': 'IM', 'name': 'Isle Of Man'},
            {'code': 'IL', 'name': 'Israel'},
            {'code': 'IT', 'name': 'Italy'},
            {'code': 'JM', 'name': 'Jamaica'},
            {'code': 'JP', 'name': 'Japan'},
            {'code': 'JE', 'name': 'Jersey'},
            {'code': 'JO', 'name': 'Jordan'},
            {'code': 'KZ', 'name': 'Kazakhstan'},
            {'code': 'KE', 'name': 'Kenya'},
            {'code': 'KI', 'name': 'Kiribati'},
            {'code': 'KR', 'name': 'Korea'},
            {'code': 'KW', 'name': 'Kuwait'},
            {'code': 'KG', 'name': 'Kyrgyzstan'},
            {'code': 'LA', 'name': 'Lao People\'s Democratic Republic'},
            {'code': 'LV', 'name': 'Latvia'},
            {'code': 'LB', 'name': 'Lebanon'},
            {'code': 'LS', 'name': 'Lesotho'},
            {'code': 'LR', 'name': 'Liberia'},
            {'code': 'LY', 'name': 'Libyan Arab Jamahiriya'},
            {'code': 'LI', 'name': 'Liechtenstein'},
            {'code': 'LT', 'name': 'Lithuania'},
            {'code': 'LU', 'name': 'Luxembourg'},
            {'code': 'MO', 'name': 'Macao'},
            {'code': 'MK', 'name': 'Macedonia'},
            {'code': 'MG', 'name': 'Madagascar'},
            {'code': 'MW', 'name': 'Malawi'},
            {'code': 'MY', 'name': 'Malaysia'},
            {'code': 'MV', 'name': 'Maldives'},
            {'code': 'ML', 'name': 'Mali'},
            {'code': 'MT', 'name': 'Malta'},
            {'code': 'MH', 'name': 'Marshall Islands'},
            {'code': 'MQ', 'name': 'Martinique'},
            {'code': 'MR', 'name': 'Mauritania'},
            {'code': 'MU', 'name': 'Mauritius'},
            {'code': 'YT', 'name': 'Mayotte'},
            {'code': 'MX', 'name': 'Mexico'},
            {'code': 'FM', 'name': 'Micronesia, Federated States Of'},
            {'code': 'MD', 'name': 'Moldova'},
            {'code': 'MC', 'name': 'Monaco'},
            {'code': 'MN', 'name': 'Mongolia'},
            {'code': 'ME', 'name': 'Montenegro'},
            {'code': 'MS', 'name': 'Montserrat'},
            {'code': 'MA', 'name': 'Morocco'},
            {'code': 'MZ', 'name': 'Mozambique'},
            {'code': 'MM', 'name': 'Myanmar'},
            {'code': 'NA', 'name': 'Namibia'},
            {'code': 'NR', 'name': 'Nauru'},
            {'code': 'NP', 'name': 'Nepal'},
            {'code': 'NL', 'name': 'Netherlands'},
            {'code': 'AN', 'name': 'Netherlands Antilles'},
            {'code': 'NC', 'name': 'New Caledonia'},
            {'code': 'NZ', 'name': 'New Zealand'},
            {'code': 'NI', 'name': 'Nicaragua'},
            {'code': 'NE', 'name': 'Niger'},
            {'code': 'NG', 'name': 'Nigeria'},
            {'code': 'NU', 'name': 'Niue'},
            {'code': 'NF', 'name': 'Norfolk Island'},
            {'code': 'MP', 'name': 'Northern Mariana Islands'},
            {'code': 'NO', 'name': 'Norway'},
            {'code': 'OM', 'name': 'Oman'},
            {'code': 'PK', 'name': 'Pakistan'},
            {'code': 'PW', 'name': 'Palau'},
            {'code': 'PS', 'name': 'Palestinian Territory, Occupied'},
            {'code': 'PA', 'name': 'Panama'},
            {'code': 'PG', 'name': 'Papua New Guinea'},
            {'code': 'PY', 'name': 'Paraguay'},
            {'code': 'PE', 'name': 'Peru'},
            {'code': 'PH', 'name': 'Philippines'},
            {'code': 'PN', 'name': 'Pitcairn'},
            {'code': 'PL', 'name': 'Poland'},
            {'code': 'PT', 'name': 'Portugal'},
            {'code': 'PR', 'name': 'Puerto Rico'},
            {'code': 'QA', 'name': 'Qatar'},
            {'code': 'RE', 'name': 'Reunion'},
            {'code': 'RO', 'name': 'Romania'},
            {'code': 'RU', 'name': 'Russian Federation'},
            {'code': 'RW', 'name': 'Rwanda'},
            {'code': 'BL', 'name': 'Saint Barthelemy'},
            {'code': 'SH', 'name': 'Saint Helena'},
            {'code': 'KN', 'name': 'Saint Kitts And Nevis'},
            {'code': 'LC', 'name': 'Saint Lucia'},
            {'code': 'MF', 'name': 'Saint Martin'},
            {'code': 'PM', 'name': 'Saint Pierre And Miquelon'},
            {'code': 'VC', 'name': 'Saint Vincent And Grenadines'},
            {'code': 'WS', 'name': 'Samoa'},
            {'code': 'SM', 'name': 'San Marino'},
            {'code': 'ST', 'name': 'Sao Tome And Principe'},
            {'code': 'SA', 'name': 'Saudi Arabia'},
            {'code': 'SN', 'name': 'Senegal'},
            {'code': 'RS', 'name': 'Serbia'},
            {'code': 'SC', 'name': 'Seychelles'},
            {'code': 'SL', 'name': 'Sierra Leone'},
            {'code': 'SG', 'name': 'Singapore'},
            {'code': 'SK', 'name': 'Slovakia'},
            {'code': 'SI', 'name': 'Slovenia'},
            {'code': 'SB', 'name': 'Solomon Islands'},
            {'code': 'SO', 'name': 'Somalia'},
            {'code': 'ZA', 'name': 'South Africa'},
            {'code': 'GS', 'name': 'South Georgia And Sandwich Isl.'},
            {'code': 'ES', 'name': 'Spain'},
            {'code': 'LK', 'name': 'Sri Lanka'},
            {'code': 'SD', 'name': 'Sudan'},
            {'code': 'SR', 'name': 'Suriname'},
            {'code': 'SJ', 'name': 'Svalbard And Jan Mayen'},
            {'code': 'SZ', 'name': 'Swaziland'},
            {'code': 'SE', 'name': 'Sweden'},
            {'code': 'CH', 'name': 'Switzerland'},
            {'code': 'SY', 'name': 'Syrian Arab Republic'},
            {'code': 'TW', 'name': 'Taiwan'},
            {'code': 'TJ', 'name': 'Tajikistan'},
            {'code': 'TZ', 'name': 'Tanzania'},
            {'code': 'TH', 'name': 'Thailand'},
            {'code': 'TL', 'name': 'Timor-Leste'},
            {'code': 'TG', 'name': 'Togo'},
            {'code': 'TK', 'name': 'Tokelau'},
            {'code': 'TO', 'name': 'Tonga'},
            {'code': 'TT', 'name': 'Trinidad And Tobago'},
            {'code': 'TN', 'name': 'Tunisia'},
            {'code': 'TR', 'name': 'Turkey'},
            {'code': 'TM', 'name': 'Turkmenistan'},
            {'code': 'TC', 'name': 'Turks And Caicos Islands'},
            {'code': 'TV', 'name': 'Tuvalu'},
            {'code': 'UG', 'name': 'Uganda'},
            {'code': 'UA', 'name': 'Ukraine'},
            {'code': 'AE', 'name': 'United Arab Emirates'},
            {'code': 'GB', 'name': 'United Kingdom'},
            {'code': 'EN', 'name': 'English'},
            {'code': 'UM', 'name': 'United States Outlying Islands'},
            {'code': 'UY', 'name': 'Uruguay'},
            {'code': 'UZ', 'name': 'Uzbekistan'},
            {'code': 'VU', 'name': 'Vanuatu'},
            {'code': 'VE', 'name': 'Venezuela'},
            {'code': 'VN', 'name': 'Viet Nam'},
            {'code': 'VG', 'name': 'Virgin Islands, British'},
            {'code': 'VI', 'name': 'Virgin Islands, U.S.'},
            {'code': 'WF', 'name': 'Wallis And Futuna'},
            {'code': 'EH', 'name': 'Western Sahara'},
            {'code': 'YE', 'name': 'Yemen'},
            {'code': 'ZM', 'name': 'Zambia'},
            {'code': 'ZW', 'name': 'Zimbabwe'}
        ];
    }

    static get languageList() {
        return [
            {'code': 'independent', 'name': 'Independent', 'nativeName': 'Independent'},
            //{"code":"ab","name":"Abkhaz","nativeName":"аҧсуа"},
            //{"code":"aa","name":"Afar","nativeName":"Afaraf"},
            {"code":"af","name":"Afrikaans","nativeName":"Afrikaans"},
            //{"code":"ak","name":"Akan","nativeName":"Akan"},
            {"code":"sq","name":"Albanian","nativeName":"Shqip"},
            //{"code":"am","name":"Amharic","nativeName":"አማርኛ"},
            {"code":"ar","name":"Arabic","nativeName":"العربية"},
            {"code":"an","name":"Aragonese","nativeName":"Aragonés"},
            {"code":"hy","name":"Armenian","nativeName":"Հայերեն"},
            {"code":"as","name":"Assamese","nativeName":"অসমীয়া"},
            // {"code":"av","name":"Avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
            {"code":"ae","name":"Avestan","nativeName":"avesta"},
            // {"code":"ay","name":"Aymara","nativeName":"aymar aru"},
            {"code":"az","name":"Azerbaijani","nativeName":"azərbaycan dili"},
            {"code":"bm","name":"Bambara","nativeName":"bamanankan"},
            {"code":"ba","name":"Bashkir","nativeName":"башҡорт теле"},
            //{"code":"eu","name":"Basque","nativeName":"euskara, euskera"},
            {"code":"be","name":"Belarusian","nativeName":"Беларуская"},
            {"code":"bn","name":"Bengali","nativeName":"বাংলা"},
            {"code":"bh","name":"Bihari","nativeName":"भोजपुरी"},
            {"code":"bi","name":"Bislama","nativeName":"Bislama"},
            {"code":"bs","name":"Bosnian","nativeName":"bosanski jezik"},
            {"code":"br","name":"Breton","nativeName":"brezhoneg"},
            {"code":"bg","name":"Bulgarian","nativeName":"български език"},
            {"code":"my","name":"Burmese","nativeName":"ဗမာစာ"},
            {"code":"ca","name":"Catalan; Valencian","nativeName":"Català"},
            {"code":"ch","name":"Chamorro","nativeName":"Chamoru"},
            // {"code":"ce","name":"Chechen","nativeName":"нохчийн мотт"},
            // {"code":"ny","name":"Chichewa","nativeName":"chiCheŵa, chinyanja"},
            {"code":"zh","name":"Chinese","nativeName":"中文 (Zhōngwén), 汉语, 漢語"},
            {"code":"cv","name":"Chuvash","nativeName":"чӑваш чӗлхи"},
            {"code":"kw","name":"Cornish","nativeName":"Kernewek"},
            {"code":"co","name":"Corsican","nativeName":"corsu, lingua corsa"},
            {"code":"cr","name":"Cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
            {"code":"hr","name":"Croatian","nativeName":"hrvatski"},
            {"code":"cs","name":"Czech","nativeName":"česky, čeština"},
            {"code":"da","name":"Danish","nativeName":"dansk"},
            // {"code":"dv","name":"Divehi","nativeName":"ދިވެހި"},
            {"code":"nl","name":"Dutch","nativeName":"Nederlands, Vlaams"},
            {"code":"en","name":"English","nativeName":"English"},
            //{"code":"eo","name":"Esperanto","nativeName":"Esperanto"},
            {"code":"et","name":"Estonian","nativeName":"eesti, eesti keel"},
            {"code":"ee","name":"Ewe","nativeName":"Eʋegbe"},
            {"code":"fo","name":"Faroese","nativeName":"føroyskt"},
            {"code":"fj","name":"Fijian","nativeName":"vosa Vakaviti"},
            {"code":"fi","name":"Finnish","nativeName":"suomi, suomen kieli"},
            {"code":"fr","name":"French","nativeName":"français, langue française"},
            // {"code":"ff","name":"Fula","nativeName":"Fulfulde, Pulaar, Pular"},
            // {"code":"gl","name":"Galician","nativeName":"Galego"},
            {"code":"ka","name":"Georgian","nativeName":"ქართული"},
            {"code":"de","name":"German","nativeName":"Deutsch"},
            {"code":"el","name":"Greek","nativeName":"Ελληνικά"},
            {"code":"gn","name":"Guaraní","nativeName":"Avañeẽ"},
            {"code":"gu","name":"Gujarati","nativeName":"ગુજરાતી"},
            {"code":"ht","name":"Haitian","nativeName":"Kreyòl ayisyen"},
            // {"code":"ha","name":"Hausa","nativeName":"Hausa, هَوُسَ"},
            // {"code":"he","name":"Hebrew (modern)","nativeName":"עברית"},
            // {"code":"hz","name":"Herero","nativeName":"Otjiherero"},
            {"code":"hi","name":"Hindi","nativeName":"हिन्दी, हिंदी"},
            // {"code":"ho","name":"Hiri Motu","nativeName":"Hiri Motu"},
            {"code":"hu","name":"Hungarian","nativeName":"Magyar"},
            // {"code":"ia","name":"Interlingua","nativeName":"Interlingua"},
            {"code":"id","name":"Indonesian","nativeName":"Bahasa Indonesia"},
            // {"code":"ie","name":"Interlingue","nativeName":"Interlingue"},
            {"code":"ga","name":"Irish","nativeName":"Gaeilge"},
            // {"code":"ig","name":"Igbo","nativeName":"Asụsụ Igbo"},
            // {"code":"ik","name":"Inupiaq","nativeName":"Iñupiaq"},
            {"code":"io","name":"Ido","nativeName":"Ido"},
            {"code":"is","name":"Icelandic","nativeName":"Íslenska"},
            {"code":"it","name":"Italian","nativeName":"Italiano"},
            // {"code":"iu","name":"Inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
            {"code":"ja","name":"Japanese","nativeName":"日本語 (にほんご／にっぽんご)"},
            // {"code":"jv","name":"Javanese","nativeName":"basa Jawa"},
            {"code":"kl","name":"Kalaallisut, Greenlandic","nativeName":"kalaallisut"},
            {"code":"kn","name":"Kannada","nativeName":"ಕನ್ನಡ"},
            // {"code":"kr","name":"Kanuri","nativeName":"Kanuri"},
            // {"code":"ks","name":"Kashmiri","nativeName":"कश्मीरी, كشميري‎"},
            // {"code":"kk","name":"Kazakh","nativeName":"Қазақ тілі"},
            {"code":"km","name":"Khmer","nativeName":"ភាសាខ្មែរ"},
            {"code":"ki","name":"Kikuyu, Gikuyu","nativeName":"Gĩkũyũ"},
            {"code":"rw","name":"Kinyarwanda","nativeName":"Ikinyarwanda"},
            {"code":"ky","name":"Kirghiz, Kyrgyz","nativeName":"кыргыз тили"},
            // {"code":"kv","name":"Komi","nativeName":"коми кыв"},
            {"code":"kg","name":"Kongo","nativeName":"KiKongo"},
            {"code":"ko","name":"Korean","nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"},
            // {"code":"ku","name":"Kurdish","nativeName":"Kurdî, كوردی‎"},
            // {"code":"kj","name":"Kwanyama","nativeName":"Kuanyama"},
            {"code":"la","name":"Latin","nativeName":"latine, lingua latina"},
            {"code":"lb","name":"Luxembourgish, Letzeburgesch","nativeName":"Lëtzebuergesch"},
            // {"code":"lg","name":"Luganda","nativeName":"Luganda"},
            {"code":"li","name":"Limburgish","nativeName":"Limburgs"},
            // {"code":"ln","name":"Lingala","nativeName":"Lingála"},
            // {"code":"lo","name":"Lao","nativeName":"ພາສາລາວ"},
            {"code":"lt","name":"Lithuanian","nativeName":"lietuvių kalba"},
            {"code":"lu","name":"Luba-Katanga","nativeName":""},
            {"code":"lv","name":"Latvian","nativeName":"latviešu valoda"},
            // {"code":"gv","name":"Manx","nativeName":"Gaelg, Gailck"},
            {"code":"mk","name":"Macedonian","nativeName":"македонски јазик"},
            {"code":"mg","name":"Malagasy","nativeName":"Malagasy fiteny"},
            {"code":"ms","name":"Malay","nativeName":"bahasa Melayu, بهاس ملايو‎"},
            {"code":"ml","name":"Malayalam","nativeName":"മലയാളം"},
            {"code":"mt","name":"Maltese","nativeName":"Malti"},
            // {"code":"mi","name":"Māori","nativeName":"te reo Māori"},
            {"code":"mr","name":"Marathi (Marāṭhī)","nativeName":"मराठी"},
            {"code":"mh","name":"Marshallese","nativeName":"Kajin M̧ajeļ"},
            {"code":"mn","name":"Mongolian","nativeName":"монгол"},
            {"code":"na","name":"Nauru","nativeName":"Ekakairũ Naoero"},
            // {"code":"nv","name":"Navajo","nativeName":"Diné bizaad"},
            {"code":"nb","name":"Norwegian Bokmål","nativeName":"Norsk bokmål"},
            // {"code":"nd","name":"North Ndebele","nativeName":"isiNdebele"},
            {"code":"ne","name":"Nepali","nativeName":"नेपाली"},
            {"code":"ng","name":"Ndonga","nativeName":"Owambo"},
            // {"code":"nn","name":"Norwegian Nynorsk","nativeName":"Norsk nynorsk"},
            // {"code":"no","name":"Norwegian","nativeName":"Norsk"},
            // {"code":"ii","name":"Nuosu","nativeName":"ꆈꌠ꒿ Nuosuhxop"},
            {"code":"nr","name":"South Ndebele","nativeName":"isiNdebele"},
            // {"code":"oc","name":"Occitan","nativeName":"Occitan"},
            // {"code":"oj","name":"Ojibwe, Ojibwa","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
            {"code":"cu","name":"Old Church Slavonic","nativeName":"ѩзыкъ словѣньскъ"},
            {"code":"om","name":"Oromo","nativeName":"Afaan Oromoo"},
            // {"code":"or","name":"Oriya","nativeName":"ଓଡ଼ିଆ"},
            // {"code":"os","name":"Ossetian, Ossetic","nativeName":"ирон æвзаг"},
            {"code":"pa","name":"Panjabi, Punjabi","nativeName":"ਪੰਜਾਬੀ, پنجابی‎"},
            // {"code":"pi","name":"Pāli","nativeName":"पाऴि"},
            // {"code":"fa","name":"Persian","nativeName":"فارسی"},
            {"code":"pl","name":"Polish","nativeName":"polski"},
            {"code":"ps","name":"Pashto, Pushto","nativeName":"پښتو"},
            {"code":"pt","name":"Portuguese","nativeName":"Português"},
            // {"code":"qu","name":"Quechua","nativeName":"Runa Simi, Kichwa"},
            // {"code":"rm","name":"Romansh","nativeName":"rumantsch grischun"},
            // {"code":"rn","name":"Kirundi","nativeName":"kiRundi"},
            {"code":"ro","name":"Romanian","nativeName":"română"},
            {"code":"ru","name":"Russian","nativeName":"русский язык"},
            {"code":"sa","name":"Sanskrit (Saṁskṛta)","nativeName":"संस्कृतम्"},
            {"code":"sc","name":"Sardinian","nativeName":"sardu"},
            // {"code":"sd","name":"Sindhi","nativeName":"सिन्धी, سنڌي، سندھی‎"},
            {"code":"se","name":"Northern Sami","nativeName":"Davvisámegiella"},
            {"code":"sm","name":"Samoan","nativeName":"gagana faa Samoa"},
            {"code":"sg","name":"Sango","nativeName":"yângâ tî sängö"},
            {"code":"sr","name":"Serbian","nativeName":"српски језик"},
            {"code":"gd","name":"Scottish Gaelic; Gaelic","nativeName":"Gàidhlig"},
            {"code":"sn","name":"Shona","nativeName":"chiShona"},
            {"code":"si","name":"Sinhala, Sinhalese","nativeName":"සිංහල"},
            {"code":"sk","name":"Slovak","nativeName":"slovenčina"},
            {"code":"sl","name":"Slovene","nativeName":"slovenščina"},
            {"code":"so","name":"Somali","nativeName":"Soomaaliga, af Soomaali"},
            {"code":"st","name":"Southern Sotho","nativeName":"Sesotho"},
            {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"},
            {"code":"su","name":"Sundanese","nativeName":"Basa Sunda"},
            // {"code":"sw","name":"Swahili","nativeName":"Kiswahili"},
            // {"code":"ss","name":"Swati","nativeName":"SiSwati"},
            {"code":"sv","name":"Swedish","nativeName":"svenska"},
            // {"code":"ta","name":"Tamil","nativeName":"தமிழ்"},
            // {"code":"te","name":"Telugu","nativeName":"తెలుగు"},
            {"code":"tg","name":"Tajik","nativeName":"тоҷикӣ, toğikī, تاجیکی‎"},
            {"code":"th","name":"Thai","nativeName":"ไทย"},
            // {"code":"ti","name":"Tigrinya","nativeName":"ትግርኛ"},
            {"code":"bo","name":"Tibetan Standard","nativeName":"བོད་ཡིག"},
            {"code":"tk","name":"Turkmen","nativeName":"Türkmen, Түркмен"},
            {"code":"tl","name":"Tagalog","nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
            {"code":"tn","name":"Tswana","nativeName":"Setswana"},
            {"code":"to","name":"Tonga (Tonga Islands)","nativeName":"faka Tonga"},
            {"code":"tr","name":"Turkish","nativeName":"Türkçe"},
            // {"code":"ts","name":"Tsonga","nativeName":"Xitsonga"},
            {"code":"tt","name":"Tatar","nativeName":"татарча, tatarça, تاتارچا‎"},
            {"code":"tw","name":"Twi","nativeName":"Twi"},
            //{"code":"ty","name":"Tahitian","nativeName":"Reo Tahiti"},
            {"code":"ug","name":"Uighur, Uyghur","nativeName":"Uyƣurqə, ئۇيغۇرچە‎"},
            {"code":"uk","name":"Ukrainian","nativeName":"українська"},
            // {"code":"ur","name":"Urdu","nativeName":"اردو"},
            {"code":"uz","name":"Uzbek","nativeName":"zbek, Ўзбек, أۇزبېك‎"},
            {"code":"ve","name":"Venda","nativeName":"Tshivenḓa"},
            {"code":"vi","name":"Vietnamese","nativeName":"Tiếng Việt"},
            // {"code":"vo","name":"Volapük","nativeName":"Volapük"},
            // {"code":"wa","name":"Walloon","nativeName":"Walon"},
            {"code":"cy","name":"Welsh","nativeName":"Cymraeg"},
            // {"code":"wo","name":"Wolof","nativeName":"Wollof"},
            // {"code":"fy","name":"Western Frisian","nativeName":"Frysk"},
            // {"code":"xh","name":"Xhosa","nativeName":"isiXhosa"},
            // {"code":"yi","name":"Yiddish","nativeName":"ייִדיש"},
            // {"code":"yo","name":"Yoruba","nativeName":"Yorùbá"},
            {"code":"za","name":"Zhuang, Chuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"}
        ]
    }

    static get sortedLanguageList() {
        const list = [...this.languageList]

        if (this.usedLanguages.length > 0) {
            list.sort((a, b) => {
                const aIndex = this.usedLanguages.indexOf(a.code)
                const bIndex = this.usedLanguages.indexOf(b.code)

                return  bIndex - aIndex
            })
        }

        return list
    }

    static confirmDialog(msg) {
        return new Promise(function (resolve, reject) {
            let confirmed = window.confirm(msg);
            return confirmed ? resolve(true) : reject(false);
        });
    }

    static showMsg(msg, interval = 2000) {
        const oldDiv = document.getElementById('infoMessageBlock');
        if (oldDiv) oldDiv.remove();

        const msgDiv = document.createElement('div');
        msgDiv.id = 'infoMessageBlock';
        msgDiv.innerText = msg;
        document.body.append(msgDiv);

        setTimeout(() => {
            msgDiv.remove();
        }, interval);
    }

    static waitTextDialog(textLabel = 'Enter name', value = '', placeholder = 'Enter name') {
        return new Promise((resolve, reject) => {
            const id = 'waitTextDialog';

            const oldDiv = document.getElementById(id);
            if (oldDiv) oldDiv.remove();

            const waitTextDialog = document.createElement('div');
            waitTextDialog.id = id;
            waitTextDialog.innerHTML = `
                <b>${textLabel}</b> <br/>
                <input id="waitTextInput" type='text' value="${value}" placeholder="${placeholder}" /> 
                <i id="waitTextSave" class="likeBtn fas fa-check" > </i>
                <i id="waitTextClose" class="likeBtn closeButton fas fa-times"> </i>
            `;
            document.body.append(waitTextDialog);

            const waitTextInput = document.getElementById('waitTextInput');
            const waitTextSave = document.getElementById('waitTextSave');
            const waitTextClose = document.getElementById('waitTextClose');

            waitTextInput.focus();
            waitTextInput.value = value;
            // waitTextInput.maxLength = 50;
            waitTextInput.selectionStart = waitTextInput.value.length;

            waitTextInput.addEventListener('keyup', e => {
                if(e.keyCode === 13 && waitTextInput.value.length > 0) {
                    resolve(waitTextInput.value);
                    waitTextDialog.remove();
                }
            });

            waitTextSave.addEventListener('click', e => {
                resolve(waitTextInput.value);
                waitTextDialog.remove();
            });

            waitTextClose.addEventListener('click', e => {
                waitTextDialog.remove();
                reject();
            });

        });
    }

    static waitSelectDialog(textLabel = 'Enter name', entries = [], value = '') {
        return new Promise((resolve, reject) => {
            const id = 'waitTextDialog';

            const oldDiv = document.getElementById(id);
            if (oldDiv) oldDiv.remove();

            const waitTextDialog = document.createElement('div');
            waitTextDialog.id = id;
            waitTextDialog.innerHTML = `
                <b>${textLabel}</b> <br/>
                <select id="waitSelectInput" >
                </select> 
                <i id="waitTextSave" class="likeBtn fas fa-check" > </i>
                <i id="waitTextClose" class="likeBtn closeButton fas fa-times"> </i>
            `;
            document.body.append(waitTextDialog);

            const waitSelectInput = document.getElementById('waitSelectInput');
            const waitTextSave = document.getElementById('waitTextSave');
            const waitTextClose = document.getElementById('waitTextClose');

            entries.forEach( ([name, val]) =>
                waitSelectInput.options.add(new Option(name, val, val === value))
            );
            waitSelectInput.focus();

            waitSelectInput.addEventListener('change', e => {
                console.log(waitSelectInput.value);
                resolve(waitSelectInput.value);
                waitTextDialog.remove();
            });

            waitTextSave.addEventListener('click', e => {
                resolve(waitSelectInput.value);
                waitTextDialog.remove();
            });

            waitTextClose.addEventListener('click', e => {
                waitTextDialog.remove();
                reject();
            });

        });
    }

    static waitTextLanguageDialog(textLabel = 'Enter name', value = '', placeholder = 'Enter name') {
        return new Promise((resolve, reject) => {

            const options = this.sortedLanguageList.map((item) => {
                const selected = item.code === 'en' ? `selected` : '';
                const flag = `<img src="/flags/${item.code}.png" alt=''>`;

                return `<option value="${item.code.toLowerCase()}" ${selected}>${flag}${item.nativeName}</option>`;
            });
            const selector = `<select id="waitLanguageSelector">${options.join('')}</select>`;

            const id = 'waitTextDialog';

            const oldDiv = document.getElementById(id);
            if (oldDiv) oldDiv.remove();

            const waitTextDialog = document.createElement('div');
            waitTextDialog.id = id;
            waitTextDialog.innerHTML = `
                <b>${textLabel}</b> <br/>
                ${selector}
                <input id="waitTextInput" type='text' value="${value}" placeholder="${placeholder}" /> 
                <i id="waitTextSave" class="likeBtn fas fa-check" > </i>
                <i id="waitTextClose" class="likeBtn closeButton fas fa-times"> </i>
            `;

            document.body.append(waitTextDialog);

            const waitTextInput = document.getElementById('waitTextInput');
            const waitLanguageSelector = document.getElementById('waitLanguageSelector');
            const waitTextSave = document.getElementById('waitTextSave');
            const waitTextClose = document.getElementById('waitTextClose');

            waitTextInput.focus();
            waitTextInput.value = value;
            // waitTextInput.maxLength = 50;
            waitTextInput.selectionStart = waitTextInput.value.length;

            waitTextInput.addEventListener('keyup', e => {
                if(e.keyCode === 13 && waitTextInput.value.length > 0) {
                    resolve([waitTextInput.value, waitLanguageSelector.value]);
                    waitTextDialog.remove();
                }
            });

            waitTextSave.addEventListener('click', e => {
                resolve([waitTextInput.value, waitLanguageSelector.value]);
                waitTextDialog.remove();
            });

            waitLanguageSelector.addEventListener('change', e => {
                const language = waitLanguageSelector.value;
                waitLanguageSelector.style.backgroundImage = `url(/flags/${language}.png)`;
            });

            waitTextClose.addEventListener('click', e => {
                waitTextDialog.remove();
                reject();
            });

        });
    }

    static waitFileDialog2(multiple = false, isSimpleText = false, extensions = '*', needFileName = false) {
        return new Promise((resolve, reject) => {
            const id = 'waitFileDialog';

            const oldFileInput = document.getElementById(id);
            if (oldFileInput) oldFileInput.remove();

            const fileInput = document.createElement('input');
            fileInput.id = id;
            fileInput.multiple = multiple;
            fileInput.type = 'file';
            fileInput.className = 'hided';
            fileInput.accept = extensions;

            document.body.append(fileInput);

            fileInput.onchange = (e) => {
                if (multiple) {
                    resolve(e);
                    return;
                }

                const file = e.target.files[0];
                const fileReadAs = isSimpleText ? 'readTextFile' : 'readFileWithoutRejection';
                InfoManager[fileReadAs](file).then(
                    answer => resolve(needFileName ? [answer, file] : answer)
                ).catch(e => e);
            };

            fileInput.click();

        });
    }

    static downloadDataAsFile(data, fileName) {
        const a = document.createElement("a");
        a.href = data;
        a.target = '_blank';
        a.download = fileName;
        a.click();
        a.remove();
    }

    static readFileWithoutRejection(file) {

        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result);
            };
            fr.readAsDataURL(file);
        });
    }

    static readTextFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result);
            };
            fr.readAsText(file);
        });
    }

    static downloadExported(content, fileName, contentType = 'text/plain') {
        const file = new Blob([content], {type: contentType});
        const data = URL.createObjectURL(file);
        InfoManager.downloadDataAsFile(data, fileName)
    }

    static showObjectQRCode(objectId) {
        const line = `https://admin${Utils.devMode ? 'sb' : ''}.explainitapp.com/play/?` + objectId;
        InfoManager.getAndShowQRCode(line, `QRCode_${objectId}.svg`);
    }

    static showProjectQRCode(projectId) {
        const line = `https://admin${Utils.devMode ? 'sb' : ''}.explainitapp.com/join/?` + projectId;
        InfoManager.getAndShowQRCode(line, `QRCode_join_${projectId}.svg`);
    }

    static showLoginQRCode(userId) {
        ServerManager.instance.authQRCode(userId)
            .then(data => InfoManager.showQRCode(data, 'AuthQRCode.svg'));
    }

    static getAndShowQRCode(text, downloadName = 'QRCode') {
        ServerManager.instance.getQRCode(text).then(
            data => {
                console.log(data);
                InfoManager.showQRCode(data, downloadName);
            }
        )
    }

    static showQRCode(data, downloadName) {
        const qrDiv = document.createElement('div');
        qrDiv.className = 'modalWindow';
        qrDiv.innerHTML = `
            <a href="${data}" download=${downloadName} class="centered">
                <img style="width:200px" src="${data}" alt="">
            </a>
            <i class="closeButton fas fa-times" />
        `;

        document.body.append(qrDiv);
        qrDiv.onclick = (e) => {
            qrDiv.remove();
        }

    }

    static copyToClipboard(text) {
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }

    static bytes(count) {

        if (count > 1000000000) {
            return (count / 1000000000.0)
                .toPrecision(3) + " GB";
        } else if (count > 1000000) {
            return (count / 1000000.0)
                .toPrecision(3) + " MB";
        } else if (count > 1000) {
            return (count / 1000.0)
                .toPrecision(3) + " KB";
        } else {
            return count + " B"
        }
    }

    static camelToSentenceCase(camelText) {
        const result = camelText.replace( /([A-Z])/g, " $1" );
        return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    }

}
