import storeDispatcher from '../storeDispatcher'
import StoreManager from "./StoreManager";


export default class AudioManager {

    static Audio = new Audio();
    static Speech = new SpeechSynthesisUtterance();
    static lastPlayedIsAudio = null;

    static repeat() {
        if (AudioManager.lastPlayedIsAudio) {
            AudioManager.playAudio(null);
        } else {
            AudioManager.speak(null);
        }
    }

    static async play(media) {
        AudioManager.stop();

        if ((media.fileId && !media.fileData) || (media.textFileId && !media.textData)) {
            console.log('Should update media data');
            const updatedSpeek = await StoreManager.updateObjectSpeekData(media.objectId, media.mediaId);
            this.play(updatedSpeek);
            return;
        }

        storeDispatcher.speekPlay(media.mediaId || true);

        if (media.fileData) {
            AudioManager.lastPlayedIsAudio = true;
            console.log(media.fileData.length);
            AudioManager.playAudio("data:audio/wav;base64," + media.fileData);
        } else if (media.textData) {
            AudioManager.lastPlayedIsAudio = false;
            AudioManager.speak(media.textData, media.language);
        }
    }

    static getAudioDuration(fileData) {
        const audio = document.createElement('audio');
        audio.src = fileData.includes('base64') ? fileData : "data:audio/wav;base64," + fileData;

        return new Promise((resolve) => {
            audio.addEventListener('loadedmetadata', function(){
                resolve(audio.duration);
            },false);
        });

    }

    static playAudio(data) {
        if (data) AudioManager.Audio.src = data;
        AudioManager.Audio.play().then();
    }

    static speak(data, lang = null) {
        if (data) AudioManager.Speech.text = data;
        if (lang) {
            const voices = window.speechSynthesis.getVoices();
            const targetVoice = voices.find(it => it.lang.toLowerCase().includes(lang));

            // console.log(voices, targetVoice);

            if (targetVoice) {
                AudioManager.Speech.voice = targetVoice;
            } else {
                AudioManager.Speech.lang = lang;
            }

        }
        window.speechSynthesis.speak(AudioManager.Speech);
    }

    static stop() {
        try {
            storeDispatcher.speekPlay(null);
            speechSynthesis.cancel();
            AudioManager.Audio.pause();
            AudioManager.Audio.currentTime = 0;
        } catch (e) {
            console.log('AudioManager.stop()', e);
        }
    }

    static get downloadData() {
        return AudioManager.lastPlayedIsAudio ? AudioManager.Audio.src : 'data:text/plain,' + AudioManager.Speech.text;
    }

    static get isAudio() {

        return AudioManager.lastPlayedIsAudio;
    }

}


