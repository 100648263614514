import React from "react";
import storeDispatcher from '../../../storeDispatcher'
import {store} from '../../../store'
import ServerManager from "../../../managers/ServerManager";
import InfoManager from "../../../managers/InfoManager";
import TaggableData from "../../../models/TaggableData";


export default class SelectionBar extends React.Component {

    cleanSelection() {
        storeDispatcher.setObjectSelection([]);
    }

    selectAll() {
        const objectIdList = Object.values(store.getState().objects).map(it => it.objectId);
        storeDispatcher.setObjectSelection(objectIdList);
    }

    disableSelected() {
        const {selected} = this.props;
        ServerManager.instance.enableDisable([], selected).then(
            status => {}
        );
    }

    enableSelected() {
        const {selected} = this.props;
        ServerManager.instance.enableDisable(selected, []).then(
            status => {}
        );
    }

    exportSelected() {
        const {selected} = this.props;

        ServerManager.instance.exportObjects(selected)
            .then(data => {
                if (!data.objects) {
                    InfoManager.showMsg(data.message || 'Server error');
                    return;
                }
                console.log(data);
                const date = TaggableData.isoNow().split(',')[0];
                InfoManager.downloadExported(JSON.stringify(data), `exported ${date}.json`);

                InfoManager.confirmDialog('Do you want to clean selection ?').then(
                    () => {
                        this.cleanSelection()
                    }
                ).catch(()=>{});
            });
    }

    removeSelected() {
        const {selected} = this.props;

        InfoManager.confirmDialog('Are you really want to delete this objects ?').then(ok => {
            if(!ok) return;

            selected.forEach(id => {
                ServerManager.instance.removeItem('object', null, id)
                    .then(() => {
                        this.cleanSelection();
                    });
            });
        }).catch(e => e);
    }

    render() {
        const {selected} = this.props;
        const isEnabled = selected.length > 0;

        return (
            <div id="SBBlock" className={isEnabled ? '' : 'hided'}>
                <span className="likeBtn selectedActionButton" onClick={() => this.exportSelected()}>
                    <i title="Start export from server" className="fas fa-file-download"/>
                    Start export
                </span>

                <span className="likeBtn selectedActionButton" onClick={this.cleanSelection}>
                    <i title="Clean selection" className="fas fa-times"/>
                    Clean selection
                </span>

                <span className="likeBtn selectedActionButton" onClick={this.selectAll}>
                    <i title="Select all objects" className="fas fa-check-double"/>
                    Select all
                </span>

                <span className="likeBtn selectedActionButton" onClick={() => this.removeSelected()}>
                    <i title="Remove selected" className="far fa-trash-alt"/>
                    Remove selected
                </span>

                <span className="likeBtn selectedActionButton" onClick={() => this.disableSelected()}>
                    <i title="Disable selected" className="far fa-eye-slash"/>
                    Disable selected
                </span>

                <span className="likeBtn selectedActionButton" onClick={() => this.enableSelected()}>
                    <i title="Enable selected" className="far fa-eye"/>
                    Enable selected
                </span>
            </div>
        )
    }
}