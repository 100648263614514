import React, {Component} from "react";
import '../Profile/Profile.css';
import '../Project/Project.css';
import Backup from "./Backup";
import Tags from "./Tags";
import './SuperAdmin.css';

export default class SuperAdmin extends Component {

    state = {
        activePage: 'tags',
    };

    changePage(name) {
        this.setState({activePage: name});
    }

    render() {
        const {me, page} = this.props;
        if (!me.isSuperAdmin) return null;
        if (page) this.changePage(page);

        return (
            <div className='MainContainer'>

                <div className="DoubleLeftSide">
                    <div className="DoubleLeftHead">
                        <i className='fas fa-cogs' /> Server managing
                    </div>

                    <div onClick={() => this.changePage('tags')}
                         className=" likeBtn DoubleLeftBlock">
                        <i className="fas fa-code ProfileLeftIcon"/>
                        Additional tags
                    </div>

                    <div onClick={() => this.changePage('backup')}
                         className="likeBtn DoubleLeftBlock">
                        <i className="fas fa-history ProfileLeftIcon"/>
                        Backups
                    </div>

                </div>

                <div className='DoubleRightSide'>
                    <Tags isActive={this.state.activePage === 'tags'} />
                    <Backup isActive={this.state.activePage === 'backup'} />
                </div>

            </div>
        );
    }

}