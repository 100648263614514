import React from "react";
import ServerManager from "../../managers/ServerManager";
import ObjectSelector from "../ObjectSelector";
import InfoManager from "../../managers/InfoManager";


export default class ProjectInfoSubPage extends React.Component {

    state = {
        ProjectSelectorIsActive: false
    };

    onProjectObjectSelect = objectId => {
        const {project: {projectId}} = this.props;
        ServerManager.instance.assignObject(objectId, null, projectId).then();
    };

    descriptionRef = React.createRef();

    commentRef = React.createRef();

    isPublicRef = React.createRef();

    onSave() {
        const {project: {projectId}} = this.props;

        const information = {
            description: this.descriptionRef.current.value,
            comment: this.commentRef.current.value,
            publicAccess: this.isPublicRef.current.checked,
        };

        ServerManager.instance.editProject(projectId, information).then();
    }

    onLeave() {
        const {project: {projectId}} = this.props;
        const decision = window.confirm("Are you sure?");
        if (decision) {
            console.log('leave', projectId);
            ServerManager.instance.excludeProjectMember(projectId).then(
                ({status, msg}) => {
                    if (status) {
                        window.location.href = "/projects";
                    } else {
                        InfoManager.showMsg("You can't leave the group");
                    }
                }
            )
        }

    }

    render() {

        const {project, isActive, isAdmin} = this.props;
        if (!isActive) return <React.Fragment />;

        return(
            <div className="DoubleRightSide">
                <div className="DoubleRightBlock">

                    <i className="DoubleTextIcon fas fa-pencil-alt"/> <b>Group information </b>
                    <br/>

                    <div className="DoubleFullWidth DoubleInputBlock">
                        Description
                        <br/>
                        <input className="DoubleFullWidthInput DoubleInput"
                               readOnly={!isAdmin}
                               type="text"
                               ref={this.descriptionRef}
                               placeholder="Academician" defaultValue={project.description}/>
                    </div>

                    <div className="DoubleFullWidth DoubleInputBlock">
                        About
                        <span className='DoubleIBRightText' title="Make your group public">
                                Public group &nbsp;
                            {
                                isAdmin ?
                                    <input type="checkbox"
                                           ref={this.isPublicRef}
                                           defaultChecked={project.isPublic}/>
                                    : <i className={project.isPublic ? "far fa-check-square" : "far fa-square"} />
                            }
                             </span>
                        <br/>
                        <textarea className="DoubleFullWidthInput DoubleInput"
                                  maxLength="250"
                                  readOnly={!isAdmin}
                                  ref={this.commentRef}
                                  defaultValue={project.comment}/>
                    </div>

                    <div className={isAdmin ? "DoubleFullWidth DoubleInputBlock" : "hided"}>
                        <div>
                            Object with information
                        </div>

                        <ObjectSelector selectedObjectId={project.projectObjectId}
                                        filterTags={{ObjectClass: 'project'}}
                                        placeholder="Choose object with group description"
                                        onSelect={this.onProjectObjectSelect} />

                    </div>

                    <div className={isAdmin ? "likeBtn DoubleButton" : "hided"} onClick={() => this.onSave()}>
                        Save changes
                    </div>
                </div>

                <div className="DoubleRightBlock">

                    <i className="DoubleTextIcon fas fa-door-open"/> <b>Leave the group </b>
                    <br/>

                    <div className={"likeBtn DoubleButton"} onClick={() => this.onLeave()}>
                        Leave
                    </div>
                </div>
            </div>
        )
    }
}
