import React from 'react';
import defaultItemAvatar from '../../../resources/qrBg.jpg';
import './style.css';
import storeDispatcher from "../../../storeDispatcher";
import ObjectLIInfoBlock from "./ObjectLIInfoBlock";
import DescriptionList from "./DescriptionList";
import SpeekList from "./SpeekList";
import InfoManager from "../../../managers/InfoManager";
import ServerManager from "../../../managers/ServerManager";
import StoreManager from "../../../managers/StoreManager";
import AuthorPopUpInfo from "../../AuthorPopUpInfo";
import Utils from "../../../managers/Utils";


export default class ObjectListItem extends React.Component {

    state = {
        isActiveContextMenu: false,
        authorPopup: null,
        authorPopupPosition: {
            left: 0,
            top: 0
        }
    };

    sightCounter() {
        const {object} = this.props;

        return object.sightCounter ? (
            <div>
                {object.sightCounter}
                <i className='far fa-clone'/>
            </div>
        ) : object.classDescription;
    }

    componentDidCatch(error, errorInfo) {
        console.log({error, errorInfo});
        return <React.Fragment/>
    }

    contextMenu() {
        if (!this.state.isActiveContextMenu) return;
        const {object} = this.props;
        if (!object) return;


        return (
            <div className='ObjectLIContextBlock'
                 onClick={e => e.stopPropagation()}>

                <div className='ObjectLIContextItem likeBtn'
                     title={object.objectId}
                     onClick={e => this.onCopyId(e)}>
                    <i className='fas fa-fingerprint' />
                    Copy id
                </div>

                <div className='ObjectLIContextItem likeBtn'
                     onClick={e => this.onShowQRCode(e)}>
                    <i className='fas fa-qrcode' />
                    QR code
                </div>

                <div className='ObjectLIContextItem likeBtn'
                     title={object.objectId}
                     onClick={e => this.onCopyLink(e)}>
                    <i className='fas fa-link' />
                    Copy play-link
                </div>

                <div className='ObjectLIContextItem likeBtn'
                     onClick={(e) => this.onShowObjectEditor(e, true)}>
                    <i className='fas fa-pencil-alt' />
                    Edit object
                </div>

                <div className={object.tags.ParentObjectId ? 'ObjectLIContextItem likeBtn' : 'hided'}
                     onClick={e => this.onShowParentEditor(e)}>
                    <i className='fas fa-level-up-alt' />
                    Edit parent
                </div>

                <div className='ObjectLIContextItem likeBtn'
                     onClick={e => this.onDisable(e)}>
                    <i className={object.isEnabled ? 'far fa-eye-slash' : 'far fa-eye'} />
                    {object.isEnabled ? 'Disable' : 'Enable'}
                </div>

                <div className='ObjectLIContextItem likeBtn'
                     onClick={e => this.onDelete(e)}>
                    <i className='far fa-trash-alt' />
                    Delete
                </div>

            </div>
        )
    }

    onShowContextMenu(e, display = true) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({
            isActiveContextMenu: display
        });
    }

    onShowObjectEditor(e) {
        if (e) e.preventDefault();
        storeDispatcher.objectEdit(this.props.object.objectId);
    }

    onShowParentEditor() {
        // console.log(this.props.object.tags.ParentObjectId);
        storeDispatcher.objectEdit(this.props.object.tags.ParentObjectId);
    }

    onShowQRCode() {
        const {objectId} = this.props.object;
        InfoManager.showObjectQRCode(objectId);
    }

    onCopyId() {
        console.log(this.props.object.objectId);
        InfoManager.copyToClipboard(this.props.object.objectId);
    }

    onCopyLink() {
        const line = `https://admin${Utils.devMode ? 'sb' : ''}.explainitapp.com/play/?` + this.props.object.objectId;
        InfoManager.copyToClipboard(line);
        InfoManager.showMsg('Link copied');
    }

    onDisable() {
        const {object} = this.props;
        ServerManager.instance.setTags(object.objectId, null, null, null, null, [{
            IsEnabled: object.isEnabled ? 'false' : false
        }]).then();
        console.log(object.objectId, object.isEnabled);
    }

    onDelete() {
        InfoManager.confirmDialog('Are you sure ?').then(
            () => {
                ServerManager.instance.removeItem('object', null, this.props.object.objectId)
                    .then();
            }
        ).catch(()=>{});
    }

    onMouseLeave() {
        this.onShowContextMenu(null, false);
    }

    onSightView(e) {
        e.stopPropagation();
        const {object} = this.props;
        if (!object) return;

        const activator = object.activators.find(item => item.isSight);
        storeDispatcher.sightView(activator ? activator.activatorId : null);
    }

    onSelectObject(e) {
        e.stopPropagation();
        storeDispatcher.selectObject(this.props.object.objectId);
    }

    onAuthorClick(userId, e) {
        e.stopPropagation();
        this.props.historyPush(`/profile/${userId}`);
    }

    addToFavourite(objectId, e) {
        e.stopPropagation();

        const favourite = !this.props.me.isFavouriteObject(objectId);
        ServerManager.instance.addToFavourite(objectId, favourite)
            .then(() => StoreManager.updateMe(this.props.me.userId));
    }

    onShowAuthorInfo(userId, e) {
        e.stopPropagation();
        if (userId) return; // temporary disabled !!!!!!!!! REMOVE THIS !

        if (e.nativeEvent.path.length < 6) return;

        let left = e.nativeEvent.clientX - e.nativeEvent.offsetX;
        let top = e.nativeEvent.clientY - e.nativeEvent.offsetY;

        if (!!userId) {
            const parent = e.nativeEvent.path.reduce((acc, cur) => {
                if (cur.className === 'ObjectListItem') return cur;
                return acc;
            }, null);

            if (parent) {
                left = parent.offsetLeft;
                top = parent.offsetTop;
            }
        }


        if (userId && e.nativeEvent.offsetX < 200) {
            this.setState({
                authorPopup: userId,
                authorPopupPosition: {left, top}
            });
        } else {
            this.setState({
                authorPopup: userId
            });
        }


    }

    render() {
        const {object, isSelected, me, creator} = this.props;
        if (!object || !me || !me.userId) return null;

        return (
            <div className={object.isEnabled ? 'ObjectListItem' : 'OLIDisabled ObjectListItem'}
                 onContextMenu={(e) => {this.onShowContextMenu(e, true)}}
                 onMouseLeave={() => {this.onMouseLeave()}}>

                <div className="ObjectLIHead"
                     onClick={(e) => {this.onSightView(e)}} >
                    <img className="centered ObjectLIImg"
                         title="Click for preview"
                         src={object.avatarImageData || defaultItemAvatar}
                         alt=''/>

                    <div className="ObjectLIHeadShadowBlock"/>

                    <div className="ObjectLIDescriptionBlock centered" title="Object description">
                        {object.description()}
                    </div>

                    <div className="ObjectLIAuthorAvatar likeBtn"
                         onMouseEnter={e => this.onShowAuthorInfo(object.tags.CreatorId, e)}
                         onClick={e => this.onAuthorClick(object.tags.CreatorId, e)}>
                        <img src={object.creatorAvatarData(creator)} alt=''/>
                    </div>

                    <i title="Favourites"
                       onClick={e => this.addToFavourite(object.objectId, e)}
                       className={"ObjectLIFavourite likeBtn fa-star " +
                            (me.isFavouriteObject(object.objectId) ? 'fas gold' : 'far')}/>

                    <i className={"ObjectLISelection likeBtn far " + (!isSelected ? "fa-square" : "fa-check-square")}
                       title="Select objects for more actions"
                       onClick={e => this.onSelectObject(e)}/>

                    <i className="ObjectLIMenuBtn likeBtn fas fa-bars"
                       title="Menu. Right click for fast edit"
                       onClick={(e) => {this.onShowContextMenu(e)}}
                       onContextMenu={(e) => {this.onShowObjectEditor(e, true)}}/>

                    <div className="ObjectLISightsCounter" title="Count of activators">
                        {this.sightCounter()}
                    </div>

                    <div className="ObjectLILinks">

                    </div>

                    {this.contextMenu()}
                </div>

                <div className="ObjectLIBody">
                    <div className="ObjectLIRecordings">
                        Speeks
                        <SpeekList object={object}/>
                    </div>

                    <div className="ObjectLIDescriptions">
                        Descriptions
                        <DescriptionList object={object} />
                    </div>

                </div>

                <div className='ObjectLIFooter'>
                    <ObjectLIInfoBlock object={object} />
                </div>

                <AuthorPopUpInfo userId={this.state.authorPopup}
                                 left={this.state.authorPopupPosition.left}
                                 top={this.state.authorPopupPosition.top}
                                 avatarData={object.creatorAvatarData(creator)}
                                 onAuthorClick={e => this.onAuthorClick(object.tags.CreatorId, e)}
                                 onExit={e => this.onShowAuthorInfo(null, e)}/>

            </div>
        )
    }

}
