import React from "react";


interface PropsType {
    onInputCheck: Function
    keySuffix: string | null
    tags: Record<string, string>
    usageTargetType?: TargetType
}

export enum TargetType {
    activator,
    media
}

interface PrefItem {
    tag: string
    description: string
    onState: string | undefined
    offState: string | undefined
    targetTypeList?: Array<TargetType>
}

const prefList: Array<PrefItem> = [
    {
        tag: "IsEnabled",
        description: "Is enabled",
        onState: undefined,
        offState: "false",
    },
    {
        tag: "UnlockObject",
        description: "Unlock object",
        onState: 'true',
        offState: undefined,
        targetTypeList: [TargetType.activator]
    },
    {
        tag: "RequireHeadset",
        description: "Require headset",
        onState: 'true',
        offState: undefined,
        targetTypeList: [TargetType.activator]
    },
    {
        tag: "AllowAutoPlay",
        description: "Allow autoplay",
        onState: undefined,
        offState: "false",
        targetTypeList: [TargetType.media]

    },
    {
        tag: "ShowGeneralInfo",
        description: "Show general information",
        onState: undefined,
        offState: "false",
        targetTypeList: [TargetType.media]
    },
    {
        tag: "IgnoreLang",
        description: "Ignore language",
        onState: 'true',
        offState: undefined,
        targetTypeList: [TargetType.media]

    },
    {
        tag: "NeedUnlock",
        description: "Need unlock",
        onState: 'true',
        offState: undefined,
        targetTypeList: [TargetType.media]
    },
];


export default class CommonPreferences extends React.Component<PropsType> {

    render() {
        const {tags, onInputCheck, keySuffix = '', usageTargetType} = this.props
        if (!tags || !onInputCheck) return <React.Fragment/>

        return (
            <React.Fragment>
                <b><u>Common preferences:</u></b><br/>
                {
                    prefList.map(preference => {
                        const name = preference.tag

                        if (preference.targetTypeList && (usageTargetType === undefined || !preference.targetTypeList.includes(usageTargetType))) {
                            return null
                        }

                        return (
                            <React.Fragment key={'ContentFlag_'+keySuffix+'_' + preference.tag}>
                                <span className='OptionKey'>
                                    {preference.description}
                                </span>
                                <span className='OptionValue'>
                                    <input
                                        type='checkbox'
                                        checked={tags[name] === preference.onState}
                                        onChange={(e) => {
                                            onInputCheck(name, e, preference.onState, preference.offState)
                                        }}
                                    />
                                </span>
                            </React.Fragment>
                        )
                    })
                }
            </React.Fragment>
        )
    }
}
