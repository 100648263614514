import React, {Component} from 'react';
import InfoManager from "../../managers/InfoManager";
import ServerManager from "../../managers/ServerManager";
import PopupMenu from "../PopupMenu";


interface RequestDataType {
    id: string,
    tags: Record<string, string>
}

interface PropsType {
    item: RequestDataType,
    refreshList: Function
}


export default class RequestDataItem extends Component<PropsType> {

    onAddTag() {
        const {item: {id, tags}, refreshList} = this.props;
        let name: string, value: string;

        InfoManager.waitTextDialog('Tag name','', 'Type here')
            .then(n => {
                if (!n) throw new Error('Tag name is empty');
                if (tags[n]) throw new Error('Tag already exists');
                name = n;
                return InfoManager.waitTextDialog('Tag value', '', 'Type here');
            })
            .then(v => {
                if (!v) throw new Error('Tag value is empty');
                value = v;
            })
            .finally(() => {
                ServerManager.instance.manageRequestDataList({id, tags: {[name]: value}})
                    .then(result => {
                        if (result) refreshList();
                    });
            })
            .catch(err => InfoManager.showMsg(err));
    }

    onRemoveTag(name: string) {
        const {item: {id}, refreshList} = this.props;

        InfoManager.confirmDialog('Are you sure?')
            .then(() => {
                return ServerManager.instance.manageRequestDataList({id, tags: {[name]: false}})
            }).then(result => {
            if (result) refreshList();
        });
    }

    onEditTag(name: string, e?: Event) {
        if (e) e.preventDefault();
        const {item: {id, tags}, refreshList} = this.props;
        const oldValue: string = tags[name];

        InfoManager.waitTextDialog('Tag value', oldValue, 'Type here')
            .then(value => {
                return ServerManager.instance.manageRequestDataList({id, tags: {[name]: value}})
            })
            .then(result => {
                if (result) refreshList();
            });
    }

    onRemoveTagList() {
        const {item: {id}, refreshList} = this.props;

        InfoManager.confirmDialog('Are you sure?')
            .then(() => {
                return ServerManager.instance.manageRequestDataList({id, tags: false})
            }).then(result => {
                if (result) refreshList();
            });
    }

    render() {
        const {item: {id, tags}} = this.props;

        return (
            <div className="DoubleMiddleBlock DoubleSmallBlock">
                <div className="DoubleSmallBlockHead">
                    <b>{id.toUpperCase()}</b>
                    <i className="likeBtn DSBlockHeadBtn far fa-trash-alt"
                       onClick={() => this.onRemoveTagList()}
                       title="Remove tag"/>
                </div>

                <b>Request ID:</b> <br/>
                <input type="text" readOnly={true} defaultValue={id}/><br/>

                <b>Tags:</b>
                <i className="ProjectFlagAddDesc likeBtn fas fa-plus"
                   onClick={() => this.onAddTag()}
                   title="Add tag"/>
                <br/>
                {
                    Object.entries(tags).map(([name, value]) =>
                        <div className='TagBlock' key={'tag' + id + name}>
                            <div className='TagName'>
                                {name}
                            </div>
                            <div className='TagValue'>
                                {value}
                            </div>

                            <PopupMenu iconClassName='ActivatorMenuIcon SpeekMenuIcon likeBtn fas fa-bars'
                                       onContextMenu={(e: Event) => this.onEditTag(name, e)}
                                       className='ItemShortMenu'>
                                <i className="likeBtn far fa-trash-alt" onClick={() => this.onRemoveTag(name)} />
                                <i className="likeBtn fas fa-pencil-alt"  onClick={() => this.onEditTag(name)} />
                            </PopupMenu>
                        </div>
                    )
                }

            </div>
        );
    }
}
