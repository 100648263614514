import HierarchicalObject from "./HierarchicalObject";
import noPhoto from "../resources/nophoto.gif";

export enum Rights {
    READ = 'read',
    CREATE = 'create',
    EXTEND = 'extend',
    EDIT = 'edit',
    TRUSTED = 'trusted',
    COOPERATIVE = 'cooperative',
    ADMIN = 'admin',
    DEVELOPER = 'developer',
}


export default class TaggableData extends HierarchicalObject {

    constructor(data: any) {
        super();
        this.tags = data.tags || {};
        this._data = {...data};
        this.creationTime = data.creationTime || 0;
    }

    creationTime: number = 0;

    _data: any;

    tags: Record<string, string> = {};

    get comment(): string | null {
        return this.tags.Comment || null
    }

    static checkTS(ts: number): number {
        if (ts !== Math.floor(ts)) return Math.floor(ts + 978307200);
        return ts;
    }

    static isoDate(ts: number) {
        const date = new Date(this.checkTS(ts) * 1000);

        const YYYY = date.getFullYear();
        let MM = (date.getMonth() + 1).toString();
        let DD = date.getDate().toString();

        if (MM.length < 2) MM ='0' + MM;
        if (DD.length < 2) DD ='0' + DD;

        return  `${YYYY}-${MM}-${DD}`;
    }

    static isoTime(ts: number) {
        const date = new Date(this.checkTS(ts) * 1000);

        let H = date.getHours().toString();
        let M = date.getMinutes().toString();
        let S = date.getSeconds().toString();

        if (H.length < 2) H ='0' + H;
        if (M.length < 2) M ='0' + M;
        if (S.length < 2) S ='0' + S;

        return  `${H}:${M}:${S}`;
    }

    static isoNow() {
        const tsNow: number = Math.floor( Date.now() / 1000);
        return this.isoDate(tsNow) + ', ' + this.isoTime(tsNow);
    }

    static isoDateTime(ts: number) {
        return TaggableData.isoDate(ts) + ', ' + TaggableData.isoTime(ts);
    }

    get isoDate() {
        return TaggableData.isoDate(this.creationTime);
    }

    get isoTime() {
        return TaggableData.isoTime(this.creationTime);
    }

    get isoDateTime() {
        return this.isoDate + ', ' + this.isoTime;
    }

    creatorName(creator: Record<string, any>) {
        try {
            return creator ? creator.name : 'unknown';
        } catch (e) {
            return 'UNKNOWN';
        }
    }

    creatorAvatarData(creator: any): string | null {
        try {
            return creator ? creator.avatarImageData : noPhoto;
        } catch (e) {
            return null;
        }
    }

    get isEnabled() {
        return !(this.tags.IsEnabled === "false");
    }

    get statistic() {
        const {views = 0, good = 0, bad = 0} = this._data.rang || {};

        return {
            views, good, bad
        }
    }

    get creatorId(): string | null {
        return this.tags.CreatorId || null;
    }

    get rank(): number {
        try {
            return this._data.rank * 100;
        } catch (e) {
            return 0;
        }
    }

    get viewsCount(): number {
        try {
            return this._data.rang && this._data.rang.views ? this._data.rang.views : 0;
        } catch (e) {
            return 0;
        }
    }

    get webViewsCount(): number {
        try {
            return this._data.rang && this._data.rang.webViews ? this._data.rang.webViews : 0;
        } catch (e) {
            return 0;
        }
    }

}
