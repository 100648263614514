import React from "react";
import ObservableSelect from "../../ObjectEditor/ObservableSelect/ObservableSelect";
import FilterManager from "../../../managers/FilterManager";
import ObjectData from "../../../models/ObjectData";
import ServerManager from "../../../managers/ServerManager";
import InfoManager from "../../../managers/InfoManager";
import {Rights} from "../../../models/TaggableData";
import PopupInputDialog from "../../PopupInputDialog/PopupInputDialog";

const createObjectInputData = [
    {
        id: 'language',
        type: 'language',
        allowUndefined: true,
        header: 'Select description language'
    },
    {
        id: 'description',
        type: 'text',
        header: 'Enter description'
    },
    {
        id: 'groupId',
        type: 'select',
        header: 'Select the group',
        options: {

        }
    }
]


export default class FilterBar extends React.Component {

    state = {
        isCreateObjectDialogActive: false
    }

    onChange = (name, value = null) => FilterManager.set(name, value);

    lineRef = React.createRef();

    onCleanFilter() {
        FilterManager.clean();
        this.lineRef.current.value = '';
    }

    onAddNewObject(data) {
        let {description, language, groupId} = data

        if (!description) {
            InfoManager.showMsg("You should add description")
            return
        }

        if (!groupId) {
            const {projects, me} = this.props;

            const group = Object.values(projects)
                .find(group => group.canUserAddObject(me))
            if (group) {
                groupId = group.groupId
            }
        }

        InfoManager.lastAddedObjectGroupId = groupId

        const object = {
            objectId: true,
            tags: {
                [language ? 'Description_' + language : 'Description']: description,
                UserGroupId: groupId
            }
        }

        ServerManager.instance.addNew(object).then(answer => {
            console.log({answer, object})
        })

    }

    onShowAddObjectDialog(show = true) {
        if (!show) {
            return this.setState({
                isCreateObjectDialogActive: show
            })
        }
        const {projects, me} = this.props;

        const userGroups = Object.values(projects)
            .filter(group => group.canUserAddObject(me))
            .map(group => [group.groupId, group.description])

        const index = createObjectInputData.findIndex(it => it.id === 'groupId')
        createObjectInputData[index].options = Object.fromEntries(userGroups)
        if (InfoManager.lastAddedObjectGroupId) {
            createObjectInputData[index].defaultValue = InfoManager.lastAddedObjectGroupId
            createObjectInputData[index].useDefaultValueOnSubmit = true
        }
        else {
            delete createObjectInputData[index].defaultValue
            delete createObjectInputData[index].useDefaultValueOnSubmit
        }

        this.setState({
            isCreateObjectDialogActive: show
        })
    }


    render() {
        const {projects, filterMap, objectCount, me} = this.props;
        const {filterObjectClass, filterObjectGroup, line} = filterMap;

        const objectClassOptions = ObjectData.classes.map(item => {
            if (item[1] === 'null') return {id: '', name: 'All classes'};
            return {id: item[1], name: item[0]}
        });

        const projectList = Object.values(projects).map(it => ({id: it.projectId || 'unassigned', name: it.description}) );
        const projectListOptions = [{id: 'all', name: 'All projects'}, ...projectList];

        const canAddObject = (me && me.hasRight && me.hasRight(Rights.CREATE))
            || Object.values(projects).find(group => group.canUserAddObject(me))

        return (
            <React.Fragment>
                Object inspector (<span>{objectCount}</span>)
                {
                    canAddObject &&
                        <i className='likeBtn FBIconButton fas fa-plus'
                           onClick={() => this.onShowAddObjectDialog(true)}
                           title='Add new object'
                           style={{color: 'green'}}
                        />
                }
                <ObservableSelect name='filterObjectClass'
                                  value={filterObjectClass}
                                  optionList={objectClassOptions}
                                  onChange={this.onChange}
                                  keyPrefix='FBClass'
                                  className='OptionSelect FBSelect' />

                <ObservableSelect name='filterObjectGroup'
                                  value={filterObjectGroup || 'unassigned'}
                                  optionList={projectListOptions}
                                  onChange={this.onChange}
                                  keyPrefix='FBProject'
                                  className='OptionSelect FBSelect' />

                <i className="fas fa-star likeBtn FBIconButton"
                   title='Show favourite objects'
                   onClick={() => this.onChange('filterFavourites', !filterMap.filterFavourites)} />

                <i className="fas fa-eye-slash likeBtn FBIconButton"
                   title='Show disabled objects'
                   style={{color: 'var(--danger)', verticalAlign: 'text-bottom'}}
                   onClick={() => this.onChange('filterDisabled', !filterMap.filterDisabled)} />

                <div className='FBSearchBlock' title='Search in description, id and other tags'>
                    <input type='text' className='FBSearchLine' placeholder='Search ...'
                           defaultValue={line || ''}
                           ref={this.lineRef}
                           onKeyPress={(e) => {if (e.key === "Enter") this.onChange('line', this.lineRef.current.value)}}/>
                    <i className='likeBtn fas fa-search'
                       onClick={e => this.onChange('line', this.lineRef.current.value)}/>
                </div>

                <span className={FilterManager.isEmpty ? 'hided' : 'likeBtn FBCleanAll'}
                      title='Clean filter'
                      onClick={ e => this.onCleanFilter()}>

                    <i className='fas fa-filter' />
                    <sup>
                        <i className='fas fa-times' />
                    </sup>
                </span>


                <PopupInputDialog
                    header="Add new object"
                    isActive={this.state.isCreateObjectDialogActive}
                    onClose={() => this.onShowAddObjectDialog(false)}
                    onSubmit={(data) => this.onAddNewObject(data)}
                    inputDataList={createObjectInputData} />

            </React.Fragment>
        )
    }
}
