import React from "react";
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";


export default class ProfileEmail extends React.Component {

    ref = {
        topic: React.createRef(),
        text: React.createRef(),
    };

    sendEmail() {
        const {user: {email: to}} = this.props;
        const topic = this.ref.topic.current.value;
        const text = this.ref.text.current.value;

        if (!text || text.length < 2) {
            InfoManager.showMsg('Text of email is to short');
            return;
        }

        ServerManager.instance.sendEmail(to, topic, text).then(done => {
            if (done) {
                InfoManager.showMsg('Email was sent');
                this.ref.text.current.value = '';
            } else {
                InfoManager.showMsg('Error');
            }
        });
    }

    render() {
        const {user, isActive} = this.props;
        if (!isActive) return <React.Fragment />;

        return (
            <div className="DoubleRightSide">
                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon fas fa-at"/> <b>Send email </b>
                    <br/>

                    <div className="DoubleInputBlock DoubleFullWidth">
                        Email address
                        <br/>
                        <input id="ProfileFirstNameInput" className="DoubleInput" type="text"
                               readOnly={true}
                               defaultValue={user.email}/>
                    </div>

                    <div className="DoubleInputBlock DoubleFullWidth">
                        Topic
                        <br/>
                        <input id="ProfileFirstNameInput" className="DoubleInput" type="text"
                               ref={this.ref.topic}
                               defaultValue="Message from administrator"
                               placeholder="Message text" />
                    </div>

                    <div className="DoubleInputBlock DoubleFullWidth">
                        Message
                        <br/>
                        <textarea className="DoubleInput"
                                  maxLength="250"
                                  ref={this.ref.text}
                                  defaultValue=""/>
                    </div>

                    <div className="likeBtn DoubleButton" onClick={() => this.sendEmail()}>
                        Send
                    </div>
                </div>

            </div>
        )
    }
}