import React from "react";
import PopupInputDialog, {DialogInputList, DialogInputType, InputDataType} from "../PopupInputDialog/PopupInputDialog";
import ActivatorData, {ActivatorType} from "../../models/ActivatorData";
import ServerManager from "../../managers/ServerManager";
import ModalWindow from "../ModalWindow/ModalWindow";
import AdditionalTagEditor from "../AdditionalTagEditor/AdditionalTagEditor";
import TargetPlatformList from "../ObjectEditor/MediaEditor/TargetPlatformList";
import CommonPreferences, {TargetType} from "../ObjectEditor/MediaEditor/CommonPreferences";
import InfoManager from "../../managers/InfoManager";
import {connect} from "react-redux";
import ObjectData from "../../models/ObjectData";

interface PropsType {
    isActive: boolean,
    onClose: Function,
    activator: ActivatorData,
    isAdmin?: boolean,
    extendableObjects?: Record<string, ObjectData>
}

class ActivatorDialogInputList extends DialogInputList {

    constructor(activatorType: ActivatorType, activationObjectList: Record<string, string>) {
        let data: Array<InputDataType> = [
            {
                id: "Description",
                type: DialogInputType.TEXT,
                header: "Description",
            },
            {
                id: "Description_$",
                type: DialogInputType.DESCRIPTIONS,
                options: {},
                header: "Descriptions in different languages",
            },
            {
                id: "Comment",
                type: DialogInputType.TEXT,
                header: "Hidden comment",
            },
            {
                id: "ActivationObjectId",
                type: DialogInputType.SELECT,
                options: activationObjectList,
                header: "Object activation required",
            }
        ]

        if (activatorType !== ActivatorType.SIGHT) {
            data.push({
                id: "ObjectCoords",
                type: DialogInputType.POSITION,
                header: "Object location",
            })
        }

        if (activatorType === ActivatorType.CODE || activatorType === ActivatorType.LOCATION || activatorType === ActivatorType.SIGHT) {
            data.push({
                id: "BeaconId",
                type: DialogInputType.NUMBER,
                header: "Beacon number",
                options: {
                    min: 1
                }
            })

            data.push({
                id: "BeaconsCount",
                type: DialogInputType.RANGE,
                header: "Beacons count",
                defaultValue: 1,
                options: {
                    min: 1,
                    max: 100,
                    step: 1
                }
            })

            data.push({
                id: "BeaconSignal",
                type: DialogInputType.RANGE,
                header: "Beacon signal",
                defaultValue: -60,
                options: {
                    min: -100,
                    max: -1,
                    step: 1
                }
            })

            data.push({
                id: "BeaconTolerance",
                type: DialogInputType.RANGE,
                header: "Beacon tolerance",
                defaultValue: 10,
                options: {
                    min: 1,
                    max: 100,
                    step: 1
                }
            })
        }

        if (activatorType === ActivatorType.CODE) {
            data.push({
                id: "ForeignCode",
                type: DialogInputType.TEXT,
                header: "Barcode number or QRCode text"
            })
        }

        super(data)
    }

    feelFromActivatorData(activator: ActivatorData, stateTags: Record<string, any>) {
        this._data = this._data.map(input => {
            const exactField = !input.id.includes('$')

            let defaultValue = null
            if (exactField) {
                defaultValue = stateTags.hasOwnProperty(input.id)
                    ? stateTags[input.id]
                    : activator.tags[input.id] || input.defaultValue || null
            }

            // for Description list
            let options = input.options
            if (!exactField) {
                const tagPrefix = input.id.split('$')[0]

                options = Object.entries({...activator.tags, ...stateTags})
                    .filter(([tagName, _]) => {
                        return tagName.includes(tagPrefix)
                    })
                    .reduce((acc:  Record<string, any>, [tagName, value]) => {
                        const l = tagName.split(tagPrefix)[1]
                        return {
                            ...acc,
                            [l]: value
                        }
                    }, {})
            }

            return {
                ...input,
                defaultValue,
                options
            }
        })

    }

}


class ActivatorEditor extends React.Component<PropsType> {

    state = this.getDefaultState()

    getDefaultState(): Record<string, any> {
        return {
            tags: {},
            isTagsEditorActive: false,
            _processing: false
        }
    }

    onTagsEditorShow(value = true) {
        this.setState({
            isTagsEditorActive: value
        });
    }

    setTags(tagList:Array<Record<string, any>> = []) {
        const {activator} = this.props;
        ServerManager.instance.setTags(
            activator.objectId, null, activator.activatorId, null, null, tagList).then();
    }

    onClose(e?: any) {
        if (e) e.stopPropagation()
        this.setState(this.getDefaultState())
        this.props.onClose();
    }

    onSave(e?: any) {
        if (e) e.stopPropagation()
        console.table(this.state.tags)

        const data: Record<string, any> = {}
        for (const tagName in this.state.tags) {
            if (!this.state.tags.hasOwnProperty(tagName)) {
                continue
            }

            data[tagName] = this.state.tags[tagName] || false
        }


        const {activator} = this.props

        if (activator.activatorId === null) {
            ServerManager.instance.addNew({...activator._data, activatorId: true, tags: {...activator.tags, ...data}}).then();
        }
        else {
            const tagList = Object.entries(data).map(([k, v]) => ({[k]: v || false }));
            this.setTags(tagList);
        }

        console.log(data);
        this.onClose(e)
    }

    onTagChange(name: string, value: any) {
        this.setState({
            tags: {
                ...this.state.tags,
                [name]: value
            }
        })
    }

    onInputCheck(name: any, event: any, onState: any, offState: any) {
        try {
            const nextState = event.target.checked ? onState : offState
            this.onTagChange(name, nextState)

        } catch (e) {
            console.log(e);
        }
    }

    renderSaveButton = () => {
        const {activator: {activatorId}} = this.props;
        const {_processing} = this.state;

        if (_processing) return (
            <div className='SESave'>
                Uploading <i className="fas fa-hourglass-half" />
            </div>
        );

        if (activatorId) return (
            <div className='SESave likeBtn' onClick={e => this.onSave(e)}>
                <i className='far fa-save' /> Save changes
            </div>
        );

        return (
            <div className='SESave likeBtn' onClick={e => this.onSave(e)}>
                <i className='fas fa-plus' /> Add activator
            </div>
        );
    };

    render() {
        const {activator, isActive, onClose, isAdmin = false, extendableObjects} = this.props;
        if (!activator) return null;

        const activationObjectList = Object.values(extendableObjects || {})
            .filter(ob => ob.tags.ActivationRole)
            .reduce((acc,cur) => {
                return {
                    ...acc,
                    [cur.objectId]: cur.description()
                }
            }, {undefined: "--undefined--"})

        const inputDataList = new ActivatorDialogInputList(activator.type, activationObjectList)
        inputDataList.feelFromActivatorData(activator, this.state.tags)

        return (
            <>
                <ModalWindow
                    isActive={isActive}
                    onClose={() => this.onClose()}>
                    <div className="centered PopupInputDialogWrap SpeekEditorContainer">
                        <div className='SEHeader'>
                            <h3>
                                Activator editor <small>({InfoManager.camelToSentenceCase(activator.type)})</small>
                            </h3>
                        </div>

                        {
                            isAdmin &&
                                <i className={"MediaTagsEditorIcon likeBtn fas fa-tags"}
                                   onClick={() => this.onTagsEditorShow(true)}
                                   title="Tags"/>
                        }
                        <div className='SELeft'>
                            <PopupInputDialog
                                isActive={isActive}
                                openAsModal={false}
                                header={"Activation information"}
                                onClose={() => {
                                }}
                                inputDataList={inputDataList}
                                // onSubmit={this.onSubmit.bind(this)}
                                onChange={this.onTagChange.bind(this)}
                            />
                        </div>

                        <div className='SERight'>
                            <CommonPreferences
                                usageTargetType={TargetType.activator}
                                onInputCheck={this.onInputCheck.bind(this)}
                                keySuffix={"ActComPref"}
                                tags={{...activator.tags, ...this.state.tags}}
                            />

                            <br/>
                            <br/>
                            <TargetPlatformList
                                tags={{...activator.tags, ...this.state.tags}}
                                onInputCheck={this.onInputCheck.bind(this)}
                            />
                        </div>

                        <div className='SEFooter'>
                            {
                                this.renderSaveButton()
                            }
                        </div>

                    </div>
                </ModalWindow>


                <AdditionalTagEditor activatorId={activator.activatorId}
                                     objectId={activator.objectId}
                                     isActive={this.state.isTagsEditorActive}
                                     onClose={(e?:any) => {
                                         if (e) e.stopPropagation()
                                         this.onTagsEditorShow(false)
                                     }}/>
            </>
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
        extendableObjects: state.extendableObjects
    }
}
export default connect(mapStateToProps)(ActivatorEditor)
