import React from "react";
import IconItem from "./IconItem";


export default class IconList extends React.Component {

    render() {
        const {object,  keySuffix=""} = this.props;

        return(
            object.mediaEntries
                .filter(item => item.isIcon)
                .map(item => {
                    return (
                        <React.Fragment key={'Fragment_' + keySuffix + item.mediaId}>
                            <IconItem media={item}>
                                {this.props.children}
                            </IconItem>
                        </React.Fragment>
                    )
                })
        )
    }
}
