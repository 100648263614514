import SpeekItem from "./SpeekItem";
import React from "react";


export default class SpeekList extends React.Component {

    render() {
        const {object,  keySuffix=""} = this.props;

        return(
                object.orderedSpeekList
                    .filter(item => !item.isSubTrack && item.isSpeak)
                    .map(item => {
                        const subSpeeks = object.orderedSpeekList
                            .filter(sub => sub.isChildOf(item) && item.isSpeak)
                            .map(sub => (<SpeekItem key={sub.mediaId} media={sub}>{this.props.children}</SpeekItem>));

                        return (
                            <React.Fragment key={'Fragment_' + keySuffix + item.mediaId}>
                                <SpeekItem media={item}>
                                    {this.props.children}
                                </SpeekItem>
                                {subSpeeks}
                            </React.Fragment>
                        )
                    })
        )
    }
}
