import React from "react"
import './SimpleHint.css'
import InfoManager from "../../managers/InfoManager"


export default class SimpleHint extends React.Component {

    render() {
        const hint = this.props.children;

        return (
            <i className='SimpleHint fas fa-info-circle'
               onClick={() => InfoManager.showMsg(hint, 5000)}
               title={hint} />
        )
    }

}