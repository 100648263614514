import React from "react";
import TextDocItem from "./TextDocItem";


export default class TextDocList extends React.Component {

    render() {
        const {object,  keySuffix=""} = this.props;

        return(
            object.mediaEntries
                .filter(item => item.isTextDoc)
                .map(item =>
                    <TextDocItem media={item} key={'Fragment_' + keySuffix + item.mediaId}>
                        {this.props.children}
                    </TextDocItem>
                )
        )
    }
}
