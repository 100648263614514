import React from "react";
import './ObjectSelector.css';
import ModalWindow from "../ModalWindow";
import {connect} from "react-redux";
import StoreManager from "../../managers/StoreManager";
import ObjectData from "../../models/ObjectData";

type PropsType = {
    selectedObjectId: string | null,
    onSelect: Function,
    children?: any,
    extendableObjects: Record<string, ObjectData>,
    placeholder?: string,
    filterTags?: Record<string, any>,
    sortByEditingObjectPreferences? : boolean,
    editingObjectData: ObjectData | null
};


class ObjectSelector extends React.Component {

    constructor(props: PropsType) {
        super(props);
        this.props = props;
    }

    props: PropsType

    state = {
        isActive: false,
        filter: null
    }

    onClose() {
        this.setState({
            isActive: false,
            filter: null
        })
    }

    componentDidMount() {
        const {extendableObjects} = this.props
        if (!extendableObjects) {
            return
        }

        if (Object.keys(extendableObjects).length < 1) {
            StoreManager.updateExtendableObjects()
        }
    }

    renderAvatarElement(object: ObjectData) {
        if (!object.avatarImageData) return <i className={object.class === 'person' ? 'fas fa-user' : 'fas fa-users'} />;
        return <img className='wrappedImg' src={object.avatarImageData} alt=""/>;
    }

    onSelect(objectId: string | null) {
        this.props.onSelect(objectId)
        this.onClose()
    }

    findSelectedObject() {
        const {selectedObjectId, extendableObjects} = this.props
        if (!selectedObjectId || !extendableObjects) return null

        return extendableObjects[selectedObjectId] || null
    }

    selectedObjectAvatarElement() {
        const selectedObject = this.findSelectedObject();

        if (!selectedObject || !selectedObject.avatarImageData) return <i className="fas fa-users"/>;

        const avatar = selectedObject.avatarImageData || '';
        return <img className='wrappedImg' src={avatar} alt=''/>;
    }

    selectedObjectDescription() {
        const selectedObject = this.findSelectedObject();

        if (!selectedObject) return this.props.placeholder || `Choose object`;
        return selectedObject.description();
    }

    render() {
        const {extendableObjects, selectedObjectId, filterTags, editingObjectData, sortByEditingObjectPreferences} = this.props;
        if (!extendableObjects) {
            return null
        }

        const filter = (this.state.filter || "").toLowerCase()

        const objectList = Object.values(extendableObjects)
            .filter((it: ObjectData) => {
                if (editingObjectData && it.objectId === editingObjectData.objectId){
                    return false
                }

                let conforms = true

                if (filter) {
                    conforms = it.description().toLowerCase().includes(filter)
                }

                if (!filterTags || !conforms) {
                    return conforms
                }

                for (const [key, value] of Object.entries(filterTags)) {
                    if (it.tags[key] != value) {
                        conforms = false
                    }
                }

                return conforms
            })

        if (sortByEditingObjectPreferences && editingObjectData) {
            const groupId = editingObjectData.projectId
            const objectClass = editingObjectData.class

            objectList.sort((a, b) => {
                let result = 0

                //By group id
                if (a.projectId === groupId && b.projectId !== groupId) {
                    result-=2
                }
                if (a.projectId !== groupId && b.projectId === groupId) {
                    result+=2
                }

                //By object class in [LogicalGroup, Place]
                if (["place", "logicalGroup"].includes(a.class || "") && !["place", "logicalGroup"].includes(b.class || "")) {
                    result-=1
                }
                if (!["place", "logicalGroup"].includes(a.class || "") && ["place", "logicalGroup"].includes(b.class || "")) {
                    result+=1
                }

                //By object class
                if (a.class === objectClass && b.class !== objectClass) {
                    result--
                }
                if (a.class !== objectClass && b.class === objectClass) {
                    result++
                }

                return result
            })
        }

        return (
            <React.Fragment>
                <div className="likeBtn ObjectSelectorBlock DoubleInput"
                     onClick={() => this.setState({isActive: true})}
                     onContextMenu={()=>{console.log('edit', selectedObjectId)}}
                     title="Right click to edit object">
                    {
                        this.selectedObjectAvatarElement()
                    }
                </div>

                <div className="ObjectSelectorText">
                    {
                        this.selectedObjectDescription()
                    }
                </div>

                <ModalWindow isActive={this.state.isActive}
                             onClose={() => this.onClose()}>
                    <div className='centered ObjectSelectorContainer'>
                        <b>Select object</b>
                        <input
                            type="text"
                            className="OSSearch"
                            placeholder="Search here ..."
                            onChange={(e) => {
                                console.log(e.target.value)
                                this.setState({
                                    filter: e.target.value
                                })
                            }}
                        />
                        <i className="closeButton far fa-trash-alt"
                           title='Unassign object'
                           onClick={() => this.onSelect(null)}/>

                        {
                            objectList.map((object: any) =>
                                <div className='OSItem likeBtn'
                                     onClick={() => this.onSelect(object.objectId)}
                                     key={'OSItem' + object.objectId}>
                                    &nbsp;
                                    {object.description()}
                                </div>
                            )
                        }
                    </div>
                </ModalWindow>
            </React.Fragment>
        )
    }

}


const mapStateToProps = (state: Record<string, any>) => {
    const editingObjectData = state.editingObjectId ? state.objects[state.editingObjectId] : null

    return {
        extendableObjects: state.extendableObjects,
        editingObjectData
    }
}
// @ts-ignore
export default connect(mapStateToProps)(ObjectSelector);
