import React from 'react';
import Repository from "../components/Repository";


export default class RepositoryPage extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Repository />
            </React.Fragment>
        )
    }
}