import React from "react";
import ContentFlagItem from "./ContentFlagItem";
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import FilterPresetItem from "./FilterPresetItem";
import ContentFlagList from "../../models/ContentFlagList";
import PopupInputDialog, {DialogInputType, InputDataType} from "../PopupInputDialog/PopupInputDialog";

enum EntryType {
    FLAG = "Flag",
    PRESET = "Preset"
}

const additionInputDataList: Array<InputDataType> = [
    {
      id: "Type",
      type: DialogInputType.SELECT,
      defaultValue: EntryType.FLAG,
      useDefaultValueOnSubmit: true,
      options: {
          [EntryType.FLAG]: "Content flag",
          [EntryType.PRESET]: "Preset"
      },
      header: "Type"
    },
    {
      id: "Identifier",
      type: DialogInputType.TEXT,
      options: {
          placeholder: "onlyLatinSymbols",
      },
      header: "Identifier"
    },
    {
      id: "Language",
      type: DialogInputType.LANGUAGE,
      header: "Description language"
    },
    {
      id: "Description",
      type: DialogInputType.TEXT,
      header: "Description text"
    },
]


export default class ProjectFlagsSubPage extends React.Component<any> {

    state = {
        additionInputDataList: null
    }

    onAddNew(type: EntryType): any {
        const dataList = additionInputDataList.map(dataEntry => {
            if (dataEntry.id === "Type") {
                return {
                    ...dataEntry,
                    defaultValue: type
                }
            }
            else {
                return dataEntry
            }
        })

        this.setState({additionInputDataList: dataList})
    }

    onFlagOrPresetAdded(data: any) {
        const {Identifier, Description, Language, Type} = data
        if (!Identifier || !Description || !Language || !Type) {
            return InfoManager.showMsg("Not enough parameters")
        }

        if (!Identifier.match(/^[a-z_][a-z_0-9]{1,20}$/i)) {
            InfoManager.showMsg("Wrong id format")
        }

        if (Description.length < 2) {
            InfoManager.showMsg("Wrong description")
        }

        switch (Type) {
            case EntryType.FLAG:
                this.assignFlag(Identifier, Description, Language)
                break
            case EntryType.PRESET:
                this.assignPreset(Identifier, Description, Language)
                break
        }
    }

    onStandardFlagAdd(id: string) {
        if (!this.props.project) return;
        const flag = ContentFlagList.standardFlagList.find(it => it.id === id);
        if (!flag) return;

        const mergedFlag = {
            ...flag,
            internal: false,
            commercial: false,
            defaultFilter: 'irrelevant'
        };
        this.assignFlag(null, null, null, mergedFlag);
    }

    onStandardPresetAdd(id: string) {
        if (!this.props.project) return;
        const preset = ContentFlagList.standardPresetList.find(it => it.id === id);
        if (!preset) return;

        this.assignPreset(null, null, null, preset);
    }

    assignFlag(id: string | null, description: string | null, language: string | null, flagObject: any = null) {
        const flag = flagObject ? flagObject :
            {
                id,
                internal: false,
                commercial: false,
                defaultFilter: 'irrelevant',
                [`description_${language}`]: description
            };

        ServerManager.instance.addContentFlag(this.props.project.projectId, flag).then(answer => {
            InfoManager.showMsg(answer.status ? 'Flag assigned' : answer.msg || 'Server error');
        });
    }

    assignPreset(id: string | null, description: string | null, language: string | null, presetObject: any | null = null) {
        const preset = presetObject || {
            id,
            [`description_${language}`]: description
        };

        ServerManager.instance.addPreset(this.props.project.projectId, preset).then(answer => {
            InfoManager.showMsg(answer.status ? 'Preset assigned' : answer.msg || 'Server error');
        });
    }

    render() {
        const {project, isActive} = this.props;
        if (!isActive || !project) return <React.Fragment />;

        return(
            <>
                <div className="DoubleRightSide">
                    <div className="DoubleRightBlock">

                        <i className="DoubleTextIcon fas fa-pencil-alt"/> <b>Content flags </b>
                        <i className="ProjectAddFlagBtn likeBtn fas fa-plus"
                           onClick={() => this.onAddNew(EntryType.FLAG)}
                           title="Add flag"/>

                        <br/>
                        <div className='PSFContainer'>
                            {
                                ContentFlagList.standardFlagList
                                    .filter(it => !project.contentFlagList.getFlag(it.id))
                                    .map(flag =>
                                            <span key={'DefaultFlagList' + flag.id}
                                                  onClick={() => this.onStandardFlagAdd(flag.id)}
                                                  className='ProjectStandardFlagItem likeBtn'>
                                    <i className="fas fa-plus"/>
                                                {flag.description_en}
                                </span>
                                    )
                            }
                        </div>
                        <br/>
                        {
                            project.contentFlagList.list.map(
                                (flag: any) => <ContentFlagItem project={project}
                                                         key={'ContentFlag' + flag.id}
                                                         flag={flag}/>
                            )
                        }
                    </div>


                    <div className="DoubleRightBlock">
                        <i className="DoubleTextIcon fas fa-filter"/> <b>Filter presets </b>
                        <i className="ProjectAddFlagBtn likeBtn fas fa-plus"
                           onClick={() => this.onAddNew(EntryType.PRESET)}
                           title="Add preset"/>
                        <br/>

                        <div className='PSFContainer'>
                            {
                                ContentFlagList.standardPresetList
                                    .filter(it => !project.filterPresets.find((f: any) => it.id === f.id))
                                    .map(preset =>
                                            <span key={'DefaultPresetList' + preset.id}
                                                  onClick={() => this.onStandardPresetAdd(preset.id)}
                                                  className='ProjectStandardFlagItem likeBtn'>
                                                <i className="fas fa-plus"/>
                                                            {preset.description_en}
                                            </span>
                                    )
                            }
                        </div>

                        <br/>
                        {
                            project.filterPresets.map(
                                (preset: any) => <FilterPresetItem
                                    project={project}
                                    key={'ContentFlag' + preset.id}
                                    preset={preset}/>
                            )
                        }
                    </div>
                </div>

                <PopupInputDialog
                    isActive={!!this.state.additionInputDataList}
                    onClose={() => this.setState({additionInputDataList: null})}
                    inputDataList={this.state.additionInputDataList || []}
                    onSubmit={(data: any) => this.onFlagOrPresetAdded(data)}
                />
            </>
        )
    }

}
