import ServerManager from "../managers/ServerManager";
import InfoManager from "../managers/InfoManager";
import StoreManager from "../managers/StoreManager";
import Utils from "../managers/Utils";


export default class MediaControl {

    static _requestedData = { // for prevention of requests duplication
        fileData: {}
    };

    static async moveUp(media) {
        const previousSpeek = media.nextSpeek(-1);
        if (!previousSpeek) return;
        this.moveDown(previousSpeek);
    }

    static async moveDown(media) {
        const nextSpeek = media.nextSpeek();
        if (!nextSpeek) return;

        const willNextSpeek = media.nextSpeek(2);
        const previousSpeek = media.nextSpeek(-1);

        ServerManager.instance.setTags(media.objectId, media.mediaId, null, null, null, [{
            PrefPrevSpeakId: nextSpeek.mediaId
        }, {
            PrefNextSpeakId: false
        }]);

        ServerManager.instance.setTags(media.objectId, nextSpeek.mediaId, null, null, null, [{
            PrefPrevSpeakId: previousSpeek ? previousSpeek.mediaId : false
        }, {
            PrefNextSpeakId: null
        }]);

        if (willNextSpeek) ServerManager.instance.setTags(media.objectId, willNextSpeek.mediaId, null, null, null, [{
            PrefPrevSpeakId: media.mediaId
        }, {
            PrefNextSpeakId: false
        }]);
    }

    static async hide(media) {
        const objectId = media.objectId;

        await ServerManager.instance.setTags(objectId, media.mediaId, null, null, null, [{
            IsEnabled: media.isEnabled ? 'false' : false
        }]);
    }

    static async setRelatedObjectId(media, value) {
        const objectId = media.objectId;
        if (!media.isRelatedObject) return;

        await ServerManager.instance.setTags(objectId, media.mediaId, null, null, null, [{
            RelatedObjectId: value ? value : false
        }]);
    }

    // fileData updating
    static async updateFileData(media) {
        const {objectId, mediaId, fileId} = media;
        if (!fileId ) return false;
        if (this._requestedData.fileData[fileId] && (Utils.timestamp() - this._requestedData.fileData[fileId]) < 3) return false;
        this._requestedData.fileData[fileId] = Utils.timestamp();

        const data = await ServerManager.instance.getSoundtrackData(fileId);
        if (!data) return console.log('Cant get fileData for fileId: ', fileId);

        StoreManager.setMediaFileData(objectId, mediaId, data).then();

    }

    static async setMediaChanges(media, changes) {
        const {objectId, mediaId} = media;
        if (!mediaId || !objectId) return;
        console.log(changes);
        return await ServerManager.instance.setMediaChanges(objectId, mediaId, changes).then(
            (answer) => {
                console.log(answer);
                if (!answer.status) InfoManager.showMsg(answer.msg || 'Error');
                return answer.status;
            }
        );
    }

}