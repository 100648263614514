import React from 'react';
import * as leaflet from "react-leaflet";
import "react-input-range/lib/css/index.css";
import './Map.css';

const {Map, TileLayer, CircleMarker} = leaflet;
const accessToken = 'pk.eyJ1IjoiZmlsaXBwMjgwOCIsImEiOiJjanJ6enh3NmUwMng2NDlycDZibDJoMmdrIn0.x2XkqMsSHXryZQqRPCp4aQ';
const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';


interface PropsType {
    location: Array<number>,
    className?: string,
    onClick?: Function
}


export default class MapPreview extends React.Component<PropsType> {

    render() {
        const {className = "MapPreviewWrapper likeBtn", location: [lat, lon, radius], onClick} = this.props;
        if (!lat || !lon) return <i onClick={() => onClick && onClick()}
                                    className="likeBtn fad fa-map-marked-alt" />;

        return (
            <div className={className} onClick={() => onClick && onClick()}>
                <Map className='centered'
                     dragging={false}
                     center={[lat, lon]}
                     zoom={15}>

                    <TileLayer access_token={accessToken}
                               url={tileUrl}/>

                    <CircleMarker center={[lat, lon]} radius={radius || 3} />
                </Map>

            </div>
        )
    }
}