import RelatedObjectItem from "./RelatedObjectItem";
import React from "react";


export default class RelatedObjectList extends React.Component {

    render() {
        const {object,  keySuffix=""} = this.props;

        return(
            object.mediaEntries
                .filter(item => item.isRelatedObject)
                .map(item => {
                    return (
                        <React.Fragment key={'Fragment_' + keySuffix + item.mediaId}>
                            <RelatedObjectItem media={item}>
                                {this.props.children}
                            </RelatedObjectItem>
                        </React.Fragment>
                    )
                })
        )
    }
}
