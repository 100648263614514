import React from 'react'
import './ProjectList.css'
import ServerManager from "../../managers/ServerManager";
import StoreManager from "../../managers/StoreManager";

interface PropsType
{
    invitations: Record<string, any>,
    userId: String
}


export default class GroupInvitationList extends React.Component<PropsType>
{
    state = {
        isShown: true
    }

    onAccept(code: string) {
        ServerManager.instance.joinGroup(code, true)
            .then(() => this.refreshInvitations())
    }

    onDecline(code:string) {
        ServerManager.instance.joinGroup(code, false)
            .then(() => this.refreshInvitations())
    }

    refreshInvitations() {
        StoreManager.updateGroupInvitations()
    }

    onClose() {
        this.setState({isShown: false})
    }

    render() {
        const {invitations: {list, hasInvitation: invCount}} = this.props

        if (!invCount || !list || !this.state.isShown) {
            return null
        }

        return (
            <div className='GroupInvitationsBlock'>
                <h3>
                    You got {invCount} new invitation{invCount > 1 && 's'}
                </h3>

                {
                    Object.values(list).map((inv: any) => {
                        console.log({inv})
                        const avatarComp = inv.avatar
                            ? <img alt="" src={'data:image/png;base64,' + inv.avatar}/>
                            : <i className={`fas fa-users`} />


                        return (
                            <div className='GroupInvitationsItem'
                                 key={`invitation_${inv.groupId}`}
                            >
                                <div className="GIIAvatarBlock">
                                    {avatarComp}
                                </div>

                                <div className="GIITextBlock">
                                    <div className="">
                                        <b>{inv.description}</b>
                                    </div>
                                    <div className="">
                                        <small>{inv.reason}</small>
                                    </div>
                                </div>

                                {/*<button onClick={() => this.onAccept(inv.code)}>Ok</button>
                                <button onClick={() => this.onDecline(inv.code)}>No</button>*/}

                                <div className="GIIButtonsBlock">
                                    <div className={'PendingActionControlOk likeBtn'}
                                         onClick={() => this.onAccept(inv.code)}>
                                        <i className="fas fa-check" />
                                    </div>
                                    <div className={'PendingActionControlNo likeBtn'}
                                         onClick={() => this.onDecline(inv.code)}>
                                        <i className="fas fa-times" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <i className="closeButton fal fa-times" onClick={() => this.onClose()} />
            </div>
        )
    }
}
