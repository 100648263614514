import React, {Component, Fragment} from 'react';
import RequestDataItem from "./RequestDataItem";
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";


export default class Tags extends Component {

    state = {
        list: [],
        requestList: []
    };

    refreshRequestDataList() {
        ServerManager.instance.getRequestDataList()
            .then(({list, requestList}) => {
                if (!list || !requestList) return;

                this.setState({list, requestList: requestList.sort()});
            });
    }

    addRequestData() {
        InfoManager.waitSelectDialog('Select request id', this.state.requestList.map(r => [r, r]), 'appStarted')
            .then(id => {
                return ServerManager.instance.manageRequestDataList({id, tags: true})
            })
            .then(result => {
                if (result) this.refreshRequestDataList();
            });
    }

    componentDidMount() {
        this.refreshRequestDataList();
    }

    render() {
        const {isActive} = this.props;
        if (!isActive) return null;

        return (
            <Fragment>
                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon fas fa-code"/> <b>Additional tags editor </b>

                    <i style={{"fontSize": "14px"}} className="ProjectFlagAddDesc likeBtn fas fa-plus"
                       onClick={() => this.addRequestData()}
                       title="Add request data"/>
                </div>

                <div className="DoubleRightBlock">
                    {
                        this.state.list.map(item =>
                            <RequestDataItem item={item}
                                             refreshList={() => this.refreshRequestDataList()}
                                             key={'requestData' + item.id}/>
                        )
                    }
                </div>
            </Fragment>

        );
    }
}
