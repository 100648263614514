import React, {Component, Fragment} from 'react';
import ServerManager from "../../managers/ServerManager";
import ImportJSON from "../ImportJSON";
import InfoManager from "../../managers/InfoManager";
import storeDispatcher from '../../storeDispatcher';
import ImportedStructure from "../../models/ImportedStructure";
import RestoreBackupManager from "./RestoreBackupManager";


export default class Backup extends Component {

    state = {
        list: [],
        _isRestorePageActive: false
    };

    componentDidMount() {
        ServerManager.instance.getBackupList().then(list => this.setState({list}) );
    }

    async onStartImportJSON() {
        try {
            const fileData = await InfoManager.waitFileDialog2(false, true, '.json');
            if (!fileData) return;

            const structure = JSON.parse(fileData.toString());

            storeDispatcher.updateImportingStructure(new ImportedStructure(structure));
        } catch (e) {
            console.log(e);
            InfoManager.showMsg('Import file error');
        }
    }

    onStartRestore(val = true) {
        this.setState({
            _isRestorePageActive: val
        });
    }

    render() {
        const {isActive} = this.props;
        if (!isActive) return null;

        return (
            <Fragment>
                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon fas fa-archive"/> <b>Import .JSON backup </b>
                </div>
                <div className="DoubleRightBlock">
                    <div className='BackupItem likeBtn'
                    style={{width: 'fit-content'}}
                         onClick={() => this.onStartImportJSON()}>
                        <i className="fas fa-file-import" /> Start importing .JSON file
                    </div>
                </div>


                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon fas fa-history"/> <b>Automatic backup list </b>
                </div>

                <div className="DoubleRightBlock">
                    <div className='BackupItem likeBtn'
                         style={{width: 'fit-content'}}
                         onClick={() => this.onStartRestore()}>
                        <i className="fas fa-history" /> Restore automatic backup file
                    </div>
                </div>
                <div className="DoubleRightBlock">
                    {
                        this.state.list.map(it => {
                            const time = (new Date(it.name.split('_')[0] * 1000)).toLocaleString();
                            const db = it.name.split('_')[2].split('.')[0];

                            return (

                                    <a href={it.url} className='BackupLink'
                                       key={'BackupItem' + it.name}
                                       target='_blank'
                                       rel='noopener noreferrer'>
                                        <div className='BackupItem likeBtn' >
                                            <i className="far fa-file-archive" /> {db} -> {time}
                                        </div>
                                    </a>

                            )
                        })
                    }

                    <ImportJSON />
                </div>

                <RestoreBackupManager isActive={this.state._isRestorePageActive}
                                      list={this.state.list}
                                      onClose={() => this.onStartRestore(false)} />
            </Fragment>
        )
    }
}
