import React, {Fragment} from "react";
import './style.css';
import ModalWindow from "../ModalWindow/ModalWindow";
import LanguageSelector from "../ObjectEditor/LanguageSelector";
import MapPreview from "../CommonMap/MapPreview";
import CommonMap from "../CommonMap/CommonMap";
import {serializeLocationTag, parseLocationTag} from "../../modelControllers/TaggableControl"
import {DescriptionEditor} from "../ObjectEditor/DescriptionsEditor/DescriptionEditor";


export enum DialogInputType {
    TEXT = 'text',
    NUMBER = 'number',
    EMAIL = 'email',
    CHECKBOX = 'checkbox',

    SELECT = 'select',
    TEXTAREA = 'textarea',

    LANGUAGE = 'language',
    RANGE = 'range',
    POSITION = 'position',
    DESCRIPTIONS = 'descriptions'
}

const standardInputTypes = [
    DialogInputType.TEXT,
    DialogInputType.NUMBER,
    DialogInputType.EMAIL,
    DialogInputType.CHECKBOX
]

export interface InputDataType {
    id: string,
    type: DialogInputType | string,
    header: string,
    defaultValue?: any,
    useDefaultValueOnSubmit?: boolean,
    readonly ?: boolean,
    allowUndefined?: boolean
    options? : Record<string, any>
}

export class DialogInputList {
    constructor(data: Array<InputDataType>) {
        this._data = data;
    }

    _data: Array<InputDataType>;

    map(f: any) {
        return this._data.map(f);
    }

    forEach(f: any) {
        return this._data.forEach(f);
    }

    find(f: any) {
        return this._data.find(f);
    }

}

interface PropsType {
    isActive: boolean,
    onClose: Function,
    inputDataList: DialogInputList | Array<any>,
    onSubmit?: Function,
    onChange?: Function,
    header?: string,
    submitText?: string,
    children?: any,
    openAsModal?: boolean
}


export default class PopupInputDialog extends React.Component<PropsType>
{
    static get cleanState() {
        return {
            data: {},
            map: {
                isActive: false,
                id: null,
                location: null
            }
        }
    }

    state: Record<string, any> = PopupInputDialog.cleanState;

    onClose() {
        this.setState(PopupInputDialog.cleanState);
        this.props.onClose();
    }

    onSubmit() {
        const data: Record<string, any> = this.state.data

        this.props.inputDataList.forEach(({id, defaultValue, useDefaultValueOnSubmit}) => {
            if (!data[id] && useDefaultValueOnSubmit) {
                data[id] = defaultValue
            }
        })

        if (this.props.onSubmit) {
            this.props.onSubmit(data)
        }

        this.onClose()
    }

    onFieldChange(e: any, name: string) {
        let value = (e && e.target) ? (e.target.type === 'checkbox' ? e.target.checked : e.target.value) || null : e

        // min-max for number input
        if (e && e.target &&e.target.type === 'number' ) {
            const inputItem = this.props.inputDataList.find(it => it.id === name)
            if (inputItem.options && (inputItem.options.min > value || inputItem.options.max < value) ) {
                value = inputItem.options.min > value
                    ? inputItem.options.min
                    : inputItem.options.max
            }
        }

        if (this.props.onSubmit) {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
        }
        else if (this.props.onChange) {
            this.props.onChange(name, value)
        }

    }

    onStartMap(show: boolean, id?: string) {
        if (!id) this.setState({map: PopupInputDialog.cleanState.map});

        const inputData = this.props.inputDataList.find((it: any) => it.id === id);
        if (!inputData) return this.setState({map: PopupInputDialog.cleanState.map});
        const mergedValue = this.state.data.hasOwnProperty(inputData.id) ? this.state.data[inputData.id] : inputData.defaultValue;

        this.setState({
            map: {
                isActive: true,
                id,
                location: parseLocationTag(mergedValue)
            }
        })
    }

    onSubmitMap(location: Array<number> | null) {
        this.onFieldChange(serializeLocationTag(location), this.state.map.id);
        this.onStartMap(false);
    }

    _renderInput(inputData: InputDataType) {

        const mergedValue = this.state.data.hasOwnProperty(inputData.id) ? this.state.data[inputData.id] : inputData.defaultValue;

        // For standard inputs
        if (standardInputTypes.includes(inputData.type as DialogInputType)) {
            return (
                <Fragment key={'dialogInput_' + inputData.id}>
                    {inputData.header}: {inputData.type === 'checkbox' ? '' : <br/>}
                    <input type={inputData.type.toString()}
                           className={inputData.type === 'checkbox' ? '' : 'PIDTextInput'}
                           defaultChecked={mergedValue || false}
                           value={mergedValue || ''}
                           placeholder={inputData.options ? inputData.options.placeholder || '' : inputData.defaultValue}
                           onChange={e => this.onFieldChange(e, inputData.id)} /><br/>
                </Fragment>
            )
        }

        // For select
        if (inputData.type === DialogInputType.SELECT && inputData.options) {
            return (
                <Fragment key={'dialogInput_' + inputData.id}>
                    {inputData.header}: <br/>
                    <select defaultValue={mergedValue || ''}
                            onChange={e => this.onFieldChange(e, inputData.id)}
                            className={'PIDTextInput'}>
                        {
                            Object.entries(inputData.options).map(([id, caption]) =>
                                <option value={id} key={id + inputData.id}>{caption}</option>
                            )
                        }
                    </select><br/>
                </Fragment>
            )
        }

        // For textarea
        if (inputData.type === DialogInputType.TEXTAREA) {
            return (
                <Fragment key={'dialogInput_' + inputData.id}>
                    {inputData.header}: <br/>
                    <textarea  defaultValue={mergedValue || ''}
                               onChange={e => this.onFieldChange(e, inputData.id)}
                               style={{height: 'auto'}}
                               className={'PIDTextInput'} /><br/>
                </Fragment>
            )
        }

        // For language selector
        if (inputData.type === DialogInputType.LANGUAGE) {
            return (
                <Fragment key={'dialogInput_' + inputData.id}>
                    {inputData.header}: &nbsp;
                    <LanguageSelector
                        onSelect={(code: string) => this.onFieldChange(code, inputData.id)}
                        readOnly={inputData.readonly || false}
                        initLanguageCode={mergedValue}
                        allowUndefined={!!inputData.allowUndefined}
                        className={"PIDLanguageIconBlock"}
                    />
                    <br/>
                </Fragment>
            )
        }

        // For range input
        if (inputData.type === DialogInputType.RANGE && inputData.options) {
            return (
                <Fragment key={'dialogInput_' + inputData.id}>
                    {inputData.header}: &nbsp;
                    {
                        mergedValue || 50
                    }
                    <input type="range"
                           min={inputData.options.min || 1}
                           style={{width: '100%', height: '1px'}}
                           max={inputData.options.max || 100}
                           step={inputData.options.step || 1}
                           value={mergedValue}
                           onChange={e => this.onFieldChange(e, inputData.id)}
                    />
                    <br/><br/>
                </Fragment>
            )
        }

        // For position input
        if (inputData.type === DialogInputType.POSITION) {
            const parsedLocation = parseLocationTag(mergedValue);
            const locationString = parsedLocation ? parsedLocation[0] + ', ' + parsedLocation[1] : null;

            return (
                <Fragment key={'dialogInput_' + inputData.id}>
                    {inputData.header}: &nbsp;
                    <small>
                        {
                            locationString || 'undefined'
                        }
                    </small> &nbsp;
                    <MapPreview location={parsedLocation || []}
                                onClick={() => this.onStartMap( true, inputData.id)}/>
                    <br/>
                    <br/>
                </Fragment>
            )
        }

        // Language dependent description list
        if (inputData.type === DialogInputType.DESCRIPTIONS && inputData.options) {
            const tagPrefix = inputData.id.split('$')[0]

            return (
                <Fragment key={'dialogInput_' + inputData.id}>
                    {inputData.header} <br/>

                    <DescriptionEditor
                        descriptions={inputData.options}
                        onChangeDescription={(lang, desc) => this.onFieldChange(desc, tagPrefix + lang) }/>
                    <br/>
                </Fragment>
            )
        }

        // Unknown
        return (
            <Fragment key={'dialogInput_' + inputData.id}>
                {inputData.header}: &nbsp;
                <i>Unknown input type</i>
            </Fragment>
        )
    }

    render() {
        const {isActive, inputDataList, openAsModal = true} = this.props;
        const {map} = this.state;

        if (!isActive) {
            return null
        }

        const inputListComponent =
            <>
                {
                    inputDataList.map((data: InputDataType) => this._renderInput(data))
                }

                {
                    this.props.onSubmit &&
                        <div className="likeBtn PIDOkButton"
                             onClick={() => this.onSubmit()}>
                            {this.props.submitText || "OK"}
                        </div>
                }

            </>

        const mapComponent =
            <CommonMap location={map.location || []}
                       isActive={map.isActive}
                       onClose={() => this.onStartMap(false)}
                       onSubmit={(location: Array<number> | null) => this.onSubmitMap(location)}
            />

        if (!openAsModal) {
            return (
                <>
                    {inputListComponent}
                    {mapComponent}
                </>
            )
        }

        return (
            <>
                <ModalWindow isActive={isActive} onClose={() => this.onClose()}>
                    <div className="centered PopupInputDialogWrap">
                        <h3>{this.props.header || 'Please enter information'}</h3>
                        {inputListComponent}
                    </div>
                </ModalWindow>

                {mapComponent}

            </>
        )
    }
}
