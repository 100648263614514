import React from 'react';
import {connect} from "react-redux";
import StoreManager from "../../managers/StoreManager";
import './PendingList.css';
import ObjectListItem from "../ObjectList/standard-mode/ObjectListItem";
import PendingActionList from "./PendingActionList";


class PendingList extends React.Component {

    componentDidMount() {
        this.updatePendingList();
    }

    updatePendingList() {
       StoreManager.updatePendingObjectList();
    }

    render() {
        const {me, pendingData, objects} = this.props;
        const {objectId, list} = pendingData;
        if (!objectId || !list) return null;

        const storedObject = objects[objectId];

        return (
            <div className='PendingContainer'>

                <div className="PendingBlock">
                    <ObjectListItem {...{object: storedObject, me}}/>
                </div>

                <div className="PendingBlock">
                    <PendingActionList {...{list, object: storedObject}} />
                </div>

            </div>
        );
    }
}


const mapStateToProps = ({pendingData, me, objects}) => ({pendingData, me, objects});
export default connect(mapStateToProps)(PendingList);