import React from 'react'
import OptionsBar from '../components/OptionsBar'
import {connect} from 'react-redux'
import ProjectList from '../components/ProjectList/ProjectList'
import StoreManager from '../managers/StoreManager'
import ProjectCreateForm from '../components/ProjectList/ProjectCreateForm'
import GroupInvitationList from '../components/ProjectList/GroupInvitationList.tsx'


class ProjectListPage extends React.Component {

    state = {
        createFormIsActive: false
    };

    showCreateForm(value = true) {
        this.setState({createFormIsActive: value});
    }

    componentDidMount() {
        // StoreManager.updateProjectList();
        if (this.props.count < 1) StoreManager.updateProjectList();
        StoreManager.updateGroupInvitations()
    }

    render() {
        const {count, groupInvitations, userId} = this.props;

        return (
            <React.Fragment>
                <OptionsBar>
                    Groups ({count})
                    <div className='likeBtn headBtn' onClick={() => this.showCreateForm()}>
                        <i className="far fa-plus-square"/> Create new
                    </div>
                </OptionsBar>
                <div className='MainContainer'>
                    {
                        userId &&
                            <GroupInvitationList
                                invitations={groupInvitations}
                                userId={userId}
                            />
                    }
                    <ProjectList history={this.props.history} />
                    <ProjectCreateForm isActive={this.state.createFormIsActive}
                                       onClose={() => this.showCreateForm(false)}/>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    const {projects, groupInvitations, me} = state;

    return {
        count: Object.keys(projects).length,
        groupInvitations,
        projects,
        userId: me && me.userId ? me.userId : null
    };
};

export default connect(mapStateToProps)(ProjectListPage);
