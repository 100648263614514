import {filterDefaultMap} from '../managers/FilterManager'


export const initialState = {
    objects: {},
    objectSelectorMap: {
        person: {},
        project: {},
        place: {},
        exclusive: {},
        logicalGroup: {}
    },
    extendableObjects: {},  // instead of 'objectSelectorMap'
    users: {},
    authors: {},    // should be merged with creators
    creators: {},
    projects: {},
    groupInvitations: {
      hasInvitation: 0,
      list: {}
    },
    me: {},
    isLoggedIn: false,
    editingObjectId: null,

    editingMediaId: null,
    newMediaType: null,
    editingSpeekParentId: null,

    viewerActivatorId: null,
    playingSpeekId: null,
    selectedObjectList: [],
    filterMap: {...filterDefaultMap},
    objectCount: 0,
    importingStructure: null,
    importingChecks: null,
    map: {
        isActive: false,
        isReadOnly: true,
        position: null,
        fromActivator: null
    },
    pendingData: {
        objectId: null,
        list: []
    }

};









