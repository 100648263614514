import React from "react";
import './Activator.css';
import storeDispatcher from '../../../storeDispatcher';
import InfoManager from "../../../managers/InfoManager";
import ServerManager from "../../../managers/ServerManager";
import PopupMenu from "../../PopupMenu";


export default class Activator extends React.Component {

    onEdit(e) {
        e.preventDefault();
        if (this.props.onEdit) this.props.onEdit();
    }

    sightPartRender() {
        const {activator} = this.props;
        if (!activator.srcData) return null;

        return (
            <div className='ActivatorContentBlock'>
                <img alt="" className="ActivatorSightImg"
                     onClick={() => this.onViewSight()}
                     src={activator.srcData}/>
            </div>
        )
    }

    foreignCodePartRender() {
        const {activator} = this.props;
        if (!activator.tags.ForeignCode) return null;

        return (
            <div className='ActivatorContentBlock'>
                <i className="ActivatorLogoImg fad fa-barcode-read"/>
                <div className="ActivatorInfoBlock">
                    <b>Barcode or QR Code</b><br/>
                    {activator.tags.ForeignCode}
                </div>
            </div>
        )
    }

    coordinatesPartRender() {
        const {activator} = this.props;
        if (!activator.locationData) return null;

        return (
            <div className='ActivatorContentBlock'>
                <i className="ActivatorLogoImg fad fa-map-marked-alt"/>
                <div className="ActivatorInfoBlock">
                    <b>Coordinates</b><br/>
                    {activator.locationData.lat}, {activator.locationData.long}
                </div>
            </div>
        )
    }

    beaconPartRender() {
        const {activator} = this.props;
        if (!activator.tags.BeaconId) return null;

        return (
            <div className='ActivatorContentBlock'>
                <i className="ActivatorLogoImg fad fa-broadcast-tower"/>
                <div className="ActivatorInfoBlock">
                    <b>iBeacon</b><br/>
                    {activator.tags.BeaconId}
                </div>
            </div>
        )
    }

    onViewSight() {
        const {activator: {activatorId}} = this.props;
        console.log(activatorId);
        storeDispatcher.sightView(activatorId);
    }

    onMenuLocation() {
        const position = this.props.activator ? [...this.props.activator.location, 1, true] : null;
        console.log(position);
        storeDispatcher.updateMapParams({
            isActive: true,
            isReadOnly: true,
            position
        });
    }

    onMenuRemove() {
        const {activator} = this.props;

        InfoManager.confirmDialog('Are you sure ?').then(() => {
            ServerManager.instance.removeItem('activator', activator.activatorId, activator.objectId)
                .then();
        }).catch(() => {
        });
    }

    onMenuDisable() {
        this.setTags([{
            IsEnabled: this.props.activator.isEnabled ? 'false' : false
        }]);
    }

    setTags(tagList = []) {
        const {activator} = this.props;
        console.log(activator);
        ServerManager.instance.setTags(
            activator.objectId, null, activator.activatorId, null, null, tagList).then();
    }

    ContextMenuItem(props) {
        return (
            <div className={`ObjectLIContextItem likeBtn ${props.display === false ? 'hided' : ''}`}
                 onClick={props.onClick}>
                <i className={props.className}/>
                {props.title || props.children}
            </div>
        )
    }

    render() {
        const {activator, creator} = this.props;
        const {ContextMenuItem} = this;

        return (
            <div className={`ActivatorContainer ${activator.isEnabled ? '' : 'disabledBG'}`}>

                {this.sightPartRender()}
                {this.foreignCodePartRender()}
                {this.coordinatesPartRender()}
                {this.beaconPartRender()}

                <div className='ActivatorCreatorBlock'>
                    <img className="ActivatorCreatorAvatar"
                         src={activator.creatorAvatarData(creator)} alt=""/>
                    {activator.creatorName(creator)}<br/>
                    {activator.isoDateTime}
                </div>

                <PopupMenu iconClassName='ActivatorMenuIcon SpeekMenuIcon likeBtn fas fa-bars'
                           onContextMenu={(e) => this.onEdit(e)}
                           className='ObjectLIContextBlock'>

                    <ContextMenuItem className='fad fa-pencil'
                                     title='Edit'
                                     onClick={(e) => this.onEdit(e)}/>

                    {
                        activator.isSight &&
                        <ContextMenuItem className='far fa-map-marker-alt'
                                         title='Sight location'
                                         onClick={() => this.onMenuLocation()}/>
                    }

                    <ContextMenuItem className='far fa-trash-alt'
                                     onClick={() => this.onMenuRemove()}
                                     title='Remove activator'/>

                    <ContextMenuItem className={`far fa-eye${activator.isEnabled ? '-slash' : ''}`}
                                     onClick={() => this.onMenuDisable()}
                                     title={`${activator.isEnabled ? 'Dis' : 'En'}able activator`}/>

                </PopupMenu>


                <i className={"ActivatorTypeIcon " + activator.typeIconClass} />

            </div>
        )
    }

}