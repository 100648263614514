import React from "react"
import {connect} from "react-redux"
import ServerManager, {ServerSocketEvents} from "../../managers/ServerManager"
import storeDispatcher from "../../storeDispatcher"
import InfoManager from "../../managers/InfoManager";
import ModalWindow from "../ModalWindow/ModalWindow";


class ConnectionError extends React.Component<any, any>
{

    componentDidMount() {
        ServerManager.instance.dispatchEventHandler(ServerSocketEvents.CONNECT_ERROR, () => {
            InfoManager.showMsg('Connection error');
            storeDispatcher.logIn(undefined)
        })
    }

    render() {
        const {isLoggedIn} = this.props
        console.log({isLoggedIn})

        if (isLoggedIn !== undefined) {
            return null
        }

        console.log("Connection error! render()")

        return (
            <>
                <ModalWindow isActive={true} onClose={() => {}}>
                    <div className={'centered'} style={{textAlign: 'center'}}>
                        <h3>
                            Oops! A connection error has occurred
                        </h3>

                        Please check your internet connection or contact your network administrator to open port {ServerManager.productivePort}
                    </div>


                </ModalWindow>
            </>
        )
    }
}


const mapStateToProps = ({isLoggedIn}: any) => {
    return {
        isLoggedIn
    }
}
export default connect(mapStateToProps)(ConnectionError)
