import React, {Component} from 'react'
import {connect} from "react-redux";
import ModalWindow from "../ModalWindow/ModalWindow";
import PopupMenu from "../PopupMenu";
import InfoManager from "../../managers/InfoManager";
import ServerManager from "../../managers/ServerManager";
import MediaData from "../../models/MediaData";
import ActivatorData from "../../models/ActivatorData";

interface PropsType {
    isActive: boolean,
    onClose: Function,
    mediaId?: string,
    activatorId?: string,
    userId?: string,
    projectId?: string,
    objectId?: string,
    objects: Record<string, any>,
    users: Record<string, any>,
    projects: Record<string, any>
}


class AdditionalTagEditor extends Component <PropsType> {

    state: Record<string, any> = {
      tags: {

      }
    };

    onClose(e?: any) {
        if (e) e.stopPropagation();
        this.setState({
            tags: {}
        });

        this.props.onClose();
    }

    onChange(key: string, value: string | null | boolean) {
        this.setState({
            tags: {
                ...this.state.tags,
                [key]: value
            }
        });
    }

    async onEdit(key: string, value: string) {
        const newValue = await InfoManager.waitTextDialog('Enter new value', value, key + ' value');
        if (!newValue) return;

        this.onChange(key, newValue);
    }

    async onAdd() {
        let key = await InfoManager.waitTextDialog('Enter tag name', '', 'Tag name');
        if (!key) return;
        // key = key[0].toUpperCase() + key.substr(1);
        key = key.split(' ')
                 .map((word: string) => word[0].toUpperCase() + word.substr(1))
                 .join('');

        const value = await InfoManager.waitTextDialog('Enter tag value', '', 'Tag value');
        if (!value) return;

        this.onChange(key, value);
    }

    onSave() {
        const {projectId, userId, objectId, mediaId, activatorId} = this.props;

        const tagList = Object.entries(this.state.tags).map(([key, value]) => ({[key]: value}));

        ServerManager.instance.setTags(objectId, mediaId, activatorId, projectId, userId, tagList)
            .then((answer) => {
                if (answer === true) {

                }
                this.onClose()
            });

    }

    get additionalTags() {
        const {projectId, userId, objectId, mediaId, activatorId, objects, users, projects} = this.props;
        let tags = {...this.state.tags};

        if (projectId) {
            const project = projects[projectId];
            if (!project) return tags;
            tags = project.tags;
        }

        if (userId) {
            const user = users[userId];
            if (!user) return tags;
            tags = user.tags;
        }

        if (mediaId && objectId) {
            const object = objects[objectId];
            if (!object) return tags;

            const media = object.mediaEntries.find((it: MediaData) => it.mediaId === mediaId);
            if (!media) return tags;

            tags = media.tags;
        }

        if (activatorId && objectId) {
            const object = objects[objectId];
            if (!object) return tags;

            const activator = object.activators.find((it: ActivatorData) => it.activatorId === activatorId);
            if (!activator) return tags;

            tags = activator.tags;
        }

        if (!mediaId && !activatorId && objectId) {
            const object = objects[objectId];
            if (!object) return tags;

            tags = object.tags;
            console.log(object)
        }

        return {...tags, ...this.state.tags};
    }

    render() {
        const {isActive} = this.props;
        if (!isActive) return null;
        const tags: Record<string, string> = this.additionalTags;

        return (
            <ModalWindow isActive={isActive} onClose={(e: any) => this.onClose(e)}>
                <div className='SpeekEditorContainer centered' onClick={e => e.stopPropagation()}>
                    <div className='SEHeader'>
                        <h3> Additional tag editor
                            <i className="ProjectAddFlagBtn likeBtn fas fa-plus"
                               onClick={() => this.onAdd()}
                               title="Add tag" />
                        </h3>

                    </div>

                    <div className="SELeft"
                         style={{width: '100%'}}>
                    {
                        Object.entries(tags)
                            .filter(([key, value]) => this.state.tags[key] !== false)
                            .map(([key, value]) =>
                                (
                                    <div className='TagBlock'
                                         key={'additionalTag' + key}>
                                        <div className='TagName'>
                                            {key}
                                        </div>
                                        <div className='TagValue'>
                                            {this.state.tags.hasOwnProperty(key) ? this.state.tags[key] : value}
                                        </div>

                                        <PopupMenu iconClassName='ActivatorMenuIcon SpeekMenuIcon likeBtn fas fa-bars'
                                                   onContextMenu={() => {}}
                                                   className='ItemShortMenu'>
                                            <i className="likeBtn far fa-trash-alt" onClick={() => this.onChange(key, false)} />
                                            <i className="likeBtn fas fa-pencil-alt"  onClick={() => this.onEdit(key, value)} />
                                        </PopupMenu>
                                    </div>
                                ))
                    }
                    </div>

                    <div className='SEFooter'>
                        <div className='SESave likeBtn' onClick={() => this.onSave()}>
                            <i className='far fa-save' /> Save changes
                        </div>
                    </div>

                </div>
            </ModalWindow>
        );
    }

}


const mapStateToProps = ({objects, users, projects}: { objects: Record<string, any>; users: Record<string, any>; projects: Record<string, any> }) => {
    return {
        objects,
        users,
        projects
    }
};
export default connect(mapStateToProps)(AdditionalTagEditor);
