import noPhoto from '../resources/nophoto.gif';
import TaggableData, {Rights} from "./TaggableData";


export default class UserData extends TaggableData {

    static get rightList() {
        return [Rights.CREATE, Rights.EXTEND, Rights.EDIT, Rights.ADMIN, Rights.DEVELOPER, Rights.TRUSTED];
    }

    get avatarData() {
        return (this._data.avatar) ? ('data:image/png;base64,' + this._data.avatar) : noPhoto;
    }

    get fullName() {
        return (this._data.firstName || this._data.lastName) ? (this._data.firstName || '') + ' ' + (this._data.lastName || '') : this._data.nickname || this._data.email.split('@')[0];
    }

    get name() {
        return this._data.nickname || this._data.email.split('@')[0];
    }

    get firstName() {
        return this._data.firstName || '';
    }

    get lastName() {
        return this._data.lastName || '';
    }

    get homePage() {
        return this._data.homePage || '';
    }

    get aboutText() {
        return this._data.about || '';
    }

    get isPublic() {
        return this._data.public || false;

    }

    get userObjectId() {
        return this._data.userObjectId || null;

    }

    get userId() {
        return this._data.userId;
    }

    get email() {
        return this._data.email;
    }

    get lastVisitDate() {
        return TaggableData.isoDate(this._data.lastAccess) + ' ' +  TaggableData.isoTime(this._data.lastAccess);
    }

    get registrationDate() {
        return TaggableData.isoDate(this._data.registrationTS) + ' ' +  TaggableData.isoTime(this._data.registrationTS);
    }

    get isOnlineApp() {
        return this._data.onlineApp;
    }

    get isOnlineWeb() {
        return this._data.onlineWeb;
    }

    get access() {
        return this._data.access || {};
    }

    hasRight(right: string): boolean {
        return this._data.rights.includes(right);
    }

    get isSuperAdmin(): boolean {
        return !!this._data.superAdmin;
    }

    isFavouriteObject(objectId: string): boolean {
        try {
            return this._data.favourites && this._data.favourites.includes(objectId);
        } catch (e) {
            console.log(e);
            return false;
        }
    }

}
