import React, {Fragment} from "react";
import './Activator/Activator.css';
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import PopupMenu from "../PopupMenu";
import PopupInputDialog from "../PopupInputDialog/PopupInputDialog";


export default class DescriptionMenu extends React.Component {

    state = {
        isDescriptionDialogOpen: false,
        descriptionInputData: [
            {
                id: "newDescription",
                type: "text",
                header: "Description",
                defaultValue: this.props.description
            }
        ]
    };

    onShowDescriptionDialog(show = true, e) {
        this.setState({
            isDescriptionDialogOpen: show
        });

        if (e) e.preventDefault();
    }

    onRemove() {
        const {object, language} = this.props;
        console.log('remove', object.objectId, `Description_${language}`);

        InfoManager.confirmDialog('Are you sure ?').then(() => {
            ServerManager.instance.setTags(object.objectId, null, null, null, null, [{
                [`Description_${language}`]: false
            }]).then();
        }).catch(() => {});

    }

    onEdit({newDescription}) {
        const {object, language} = this.props;
        if (!newDescription) InfoManager.showMsg("You should enter description text." );

        this.setState({
            descriptionInputData: [
                {
                    id: "newDescription",
                    type: "text",
                    header: "Description",
                    defaultValue: newDescription
                }
            ]
        });

        const tag = [{
            [`Description_${language}`]: newDescription
        }];
        ServerManager.instance.setTags(object.objectId, null, null, null, null, tag).then();

    }

    render() {
        return (
            <Fragment>
                <PopupMenu className='ItemShortMenu'
                           iconClassName='ActivatorMenuIcon likeBtn fas fa-bars'
                           onContextMenu={(e) => this.onShowDescriptionDialog(true, e)}>
                    <i className="likeBtn far fa-trash-alt" onClick={() => this.onRemove()}/>
                    <i className="likeBtn fas fa-pencil-alt" onClick={() => this.onShowDescriptionDialog()}/>
                </PopupMenu>

                <PopupInputDialog isActive={this.state.isDescriptionDialogOpen}
                                  header={"Edit description"}
                                  submitText={'Save changes'}
                                  onClose={ () => this.onShowDescriptionDialog(false) }
                                  inputDataList={this.state.descriptionInputData}
                                  onSubmit={ data => this.onEdit(data) } />
            </Fragment>
        )
    }

}
