import React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from  'react-redux';

import App from "./components/App";
import Utils from "./managers/Utils";
import {store} from "./store";
import ConnectionError from "./components/Additional/ConnectionError";

console.log("App started")

ReactDOM.render(
    <Provider store={store}>
        <ConnectionError/>
    </Provider>
    , document.getElementById('root')
);

Utils.checkSSL();

Utils.startAuth(Utils.sessionCookie).then(() => {
    ReactDOM.render(
        <Provider store={store}>
            <App/>
        </Provider>
        , document.getElementById('root')
    );
});


// Agreements:
// `Container -> `Block -> `Item
