import React from 'react';
import {connect} from "react-redux";
import './ProjectList.css';
import ProjectListItem from "./ProjectListItem";


class ProjectList extends React.Component {

    render() {
        const {projects, history} = this.props;

        console.log(this.props);

        return (
            <div className="UserListBlock">
                {
                    (Object.values(projects)).map((item) => {
                        return (
                            <ProjectListItem history={history} project={item} key={'projectItem_' + item.projectId} />
                        )
                    })
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const {projects} = state;

    return {
        projects
    };
};

export default connect(mapStateToProps)(ProjectList);