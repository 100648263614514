import React from "react";
import ContentFlagList from "../../models/ContentFlagList";
import InfoManager from "../../managers/InfoManager";
import ServerManager from "../../managers/ServerManager";


export default class FilterPresetItem extends React.Component {

    state = {};

    onDescriptionContextMenu(lang, value = true) {
        this.setState({['ctx' + lang]: value});
    }

    onPresetRemove() {
        InfoManager.confirmDialog('Do you want to delete this preset ?').then(() => {
            const {project: {projectId}, preset: {id: presetId}} = this.props;
            ServerManager.instance.removePreset(projectId, presetId).then(answer =>
                InfoManager.showMsg(answer.status ? 'Preset removed' : answer.msg || 'Server error')
            );
        }).catch(() => {});
    }

    async onDescriptionAdd() {
        const description = await InfoManager.waitTextLanguageDialog('Enter description and choose language', '', 'Description text');
        if (!description[0] || description[0].length < 2) {
            InfoManager.showMsg("Wrong description");
            return;
        }

        const {project: {projectId}, preset: {id: presetId}} = this.props;
        const changes = {['description_' + description[1]]: description[0]};

        ServerManager.instance.editPreset(projectId, presetId, changes).then(answer =>
            InfoManager.showMsg(answer.status ? 'Description assigned' : answer.msg || 'Server error')
        );
    }

    onDescriptionRemove(lang) {
        InfoManager.confirmDialog('Do you want to delete this description ?').then(() => {
            const {project: {projectId}, preset: {id: presetId}} = this.props;
            const changes = {['description_' + lang]: null};

            ServerManager.instance.editPreset(projectId, presetId, changes).then(answer =>
                InfoManager.showMsg(answer.status ? 'Description removed' : answer.msg || 'Server error')
            );
        }).catch(() => {});
    }

    async onDescriptionEdit(lang) {
        const description = await InfoManager.waitTextDialog('Enter description text', '', 'Description text');
        if (!description || description.length < 2) {
            InfoManager.showMsg("Wrong description");
            return;
        }

        const {project: {projectId}, preset: {id: presetId}} = this.props;
        const changes = {['description_' + lang]: description};

        ServerManager.instance.editPreset(projectId, presetId, changes).then(answer =>
            InfoManager.showMsg(answer.status ? 'Description changed' : answer.msg || 'Server error')
        );
    }

    onFlagFilterChange(flagId, state) {
        const {project: {projectId}, preset} = this.props;
        const nextState = ContentFlagList.nextState(state);

        const changes = {
            flags: {
                ...preset.flags,
                [flagId]: nextState
            }
        };

        ServerManager.instance.editPreset(projectId, preset.id, changes).then();
    }

    render() {
        const {project, preset} = this.props;
        if (!project || !preset) return <React.Fragment />;

        return(
            <div className="DoubleMiddleBlock DoubleSmallBlock">
                <div className="DoubleSmallBlockHead">
                    <b>{ContentFlagList.flagDescription(preset)}</b>
                    <i className="likeBtn DSBlockHeadBtn far fa-trash-alt"
                       onClick={() => this.onPresetRemove()}
                       title="Remove preset"/>
                </div>

                <b>Identifier:</b> <br/>
                <input type="text" readOnly={true} defaultValue={preset.id} /><br/>

                <div className="ProjectFlagDescBlock">
                    <b>Descriptions:</b>
                    <i className="ProjectFlagAddDesc likeBtn fas fa-plus"
                       onClick={() => this.onDescriptionAdd()}
                       title="Add description"/>
                    <br/>
                    {
                        Object.keys(preset).map(key => {
                            if (!key.includes('description_')) return null;

                            const lang = key.split('description_')[1];
                            const value = preset[key];

                            const ctxMenuOn = this.state['ctx' + lang];

                            return (
                                <div className="ProjectFlagDescItem"
                                     key={preset.id + key}>
                                    <img src={`/flags/${lang}.png`} alt=''/>
                                    {value}
                                    <i className="ProjectFlagDescMenu likeBtn fas fa-bars"
                                       onClick={() => this.onDescriptionContextMenu(lang)}/>

                                    <div className={`ProjectItemContextMenu ${ctxMenuOn ? '' : 'hided'}`}
                                         onMouseLeave={() => this.onDescriptionContextMenu(lang, false)}>

                                        <i className="FlagDescriptionRemove likeBtn far fa-trash-alt"
                                           onClick={() => this.onDescriptionRemove(lang)}/>

                                        <i className="FlagDescriptionEdit likeBtn fas fa-pencil-alt"
                                           onClick={() => this.onDescriptionEdit(lang)}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="ProjectFilterPresetBlock">
                    <b>Filter presets:</b>
                    <br/>
                    {
                        project.contentFlagList.list.map(flag => {
                            const state = preset.flags && preset.flags[flag.id] ? preset.flags[flag.id] : undefined;
                            const stateName = ContentFlagList.states[state] || 'Undefined';
                            const stateClass = ContentFlagList.statesEmotion[state];

                            if (!flag) return <React.Fragment/>;

                            const presetDescription = flag ? flag.description_en || flag.id : flag.id;

                            return (
                                <div className="ProjectPresetItem" key={'presetItem' + flag.id}>
                                    {presetDescription}
                                    <i className={'likeBtn ProjectPresetFilterBtn ' + stateClass} title={stateName}
                                       onClick={() => this.onFlagFilterChange(flag.id, state)}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}