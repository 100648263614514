import React, {Component} from "react";
import './Invitation.css';
import ServerManager from "../../managers/ServerManager";
import ProjectData from "../../models/ProjectData";
import InfoManager from "../../managers/InfoManager";


export default class Invitation extends Component {
    state = {
        group: null
    };

    componentDidMount() {
        if (!this.state.group) {
            ServerManager.instance.getProjectData(null, this.props.code)
                .then(answer => {
                    if (answer.groupId) {
                        const group = new ProjectData(answer);
                        this.setState({
                            group
                        })
                    } else {
                        InfoManager.showMsg(answer.msg || 'Server error');
                    }
                })
        }
    }

    onConfirmInvitation(choice = true) {
        const {code} = this.props;

        ServerManager.instance.joinGroup(code, choice)
            .then(completed => {
                if (completed && choice) {
                    document.location.href = "/projects";
                } else {
                    document.location.href = "/";
                }
            });
    }

    render() {
        const {loggedIn, code, me} = this.props;
        const {group} = this.state;

        if (loggedIn && code && group) {

            return (
                <div className="messageBlock">
                    <div className="invitationAvatarBlock">
                        <img src={me.avatarData} alt=""/>
                    </div>
                    <b>{me.fullName}</b>, do you want to enter the group "{group.description}"? &nbsp;

                    <div className="invitationAvatarBlock">
                        <img src={group.avatarData} alt=""/>
                    </div>

                    <button onClick={() => this.onConfirmInvitation(true)}>Yes</button> &nbsp;
                    <button onClick={() => this.onConfirmInvitation(false)}>No</button>

                </div>
            )
        }

        if (code && !loggedIn) return (
            <div className="messageBlock">
                Please login or register to enter the group!
            </div>
        );

        return (
            <div className="messageBlock">
                Something wrong
            </div>
        );
    }
}
