import React from "react";
import ReactCrop from 'react-image-crop';
import noPhoto from '../../resources/nophoto.gif';
import 'react-image-crop/dist/ReactCrop.css';
import './AvatarEditor.css';
import InfoManager from "../../managers/InfoManager";

type PropsType = {
    onSave: Function,
    onClose: Function,
    isActive: boolean,
    src: string | null
};
const initState = {
    crop: {
        aspect: 1,
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25
    },
    src: null
};


export default class AvatarEditor extends React.Component<PropsType> {

    imageRef: any = null;

    state = {...initState};

    changeCrop = (crop: Record<string, any>) => this.setState({crop});

    onImageLoaded = (image: any) => this.imageRef = image;

    onSelectFile = () => {
        InfoManager.waitFileDialog2(false, false, 'image/*')
            .then(data => this.setState({src: data})).catch(()=>{})
    };

    getCroppedImg() {
        const {crop} = this.state;
        const image = this.imageRef;

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext('2d');

        if (ctx) ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        console.log(crop, canvas.width);
        return canvas.toDataURL('image/jpeg');
    }

    getRotatedImg = () => {
        const image = this.imageRef;

        const canvas = document.createElement('canvas');
        canvas.width = image.naturalHeight;
        canvas.height = image.naturalWidth;
        const ctx = canvas.getContext('2d');

        if (ctx) {
            ctx.translate(canvas.width/2,canvas.height/2);
            ctx.rotate(- Math.PI / 2);
            ctx.drawImage(image, - image.naturalWidth / 2, - image.naturalHeight / 2);
            ctx.restore();
        }

        return canvas.toDataURL('image/jpeg');
    };

    onRotateImg = () => {
        const img = this.getRotatedImg();
        this.imageRef = img;
        return this.setState({src: img});
    };

    onSave = () => {
        try {
            this.props.onSave(this.getCroppedImg());
            this.onClose();
        } catch (e) {}
    };

    onClose = () => {
        const {onClose} = this.props;
        this.setState({...initState});
        try {
            onClose();
        } catch (e) {}
    };

    render() {
        const {isActive} = this.props;
        if (!isActive) return <React.Fragment />;

        return (
            <div className='modalWindow' onMouseDown={this.onClose}>


                <div className='AvatarEditorBlock centered' onMouseDown={e => e.stopPropagation()}>
                    <div className='AEHeader'>
                        Avatar editor
                        <i className='closeButton fas fa-times' onClick={this.onClose}/>
                    </div>
                    <br/>

                    <ReactCrop src={this.state.src || this.props.src || noPhoto}
                               className='ReactCropBlock'
                               keepSelection={true}
                               minHeight={100}
                               imageStyle={{height: '100%'}}
                               minWidth={100}
                               // @ts-ignore
                               crop={this.state.crop}
                               onImageLoaded={this.onImageLoaded}
                               onChange={this.changeCrop} />
                    <br/>
                    <div className="AEFooter">
                        <button className='AEButton likeBtn' onClick={this.onSelectFile}>
                            <i className="far fa-folder-open"/>
                            Select
                        </button>

                        <button className='AEButton likeBtn' onClick={this.onSave}>
                            <i className="far fa-save"/>
                            Save
                        </button>

                        <button className='AEButton likeBtn' onClick={this.onRotateImg}>
                            <i className="fas fa-undo"/>
                            Rotate
                        </button>
                    </div>


                </div>
            </div>
        )
    }
}