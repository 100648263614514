import React from "react";
import '../ObjectList.css';

export default class DescriptionItem extends React.Component{

    componentDidCatch(error, errorInfo) {
        console.log(error);
    }

    render() {
        const {/*object,*/ description, language} = this.props;

        const childWithProps = this.props.children ?
            React.cloneElement(this.props.children, this.props)
            : <React.Fragment/>;

        return (
            <div className="ObjectLIDescriptionContainer " title={`${language} description`} >
                <img src={`/flags/${language}.png`} alt='' />
                <span>{description}</span>
                {childWithProps}
            </div>
        )
    }
}