import React from 'react';
import OptionsBar from "../components/OptionsBar";
import {connect} from "react-redux";
import SuperAdmin from "../components/SuperAdmin";


class SuperAdminPage extends React.Component {

    render() {
        const {me} = this.props;

        return (
            <React.Fragment>
                <OptionsBar />
                <div className='MainContainer'>
                    <SuperAdmin me={me} />
                </div>
            </React.Fragment>
        )
    }

}


const mapStateToProps = ({me}) => ({me});
export default connect(mapStateToProps)(SuperAdminPage);