import React, {Fragment} from "react";
import MediaMenu from "../MediaMenu";
import SpeekList from "../../ObjectList/standard-mode/SpeekList";
import AvatarList from "./AvatarList";
import LinkList from "./LinkList";
import RelatedObjectList from "./RelatedObjectList";
import IconList from "./IconList";
import TextDocList from "./TextDocList";


export default class MediaList extends React.Component {


    render() {
        const {object} = this.props;

        return (
            <Fragment>
                <AvatarList object={object}>
                    <MediaMenu />
                </AvatarList>


                <TextDocList object={object}>
                    <MediaMenu />
                </TextDocList>

                <RelatedObjectList object={object}>
                    <MediaMenu />
                </RelatedObjectList>

                <LinkList object={object}>
                    <MediaMenu />
                </LinkList>

                <SpeekList object={object}  keySuffix="_editor_speak_list_" >
                    <MediaMenu />
                </SpeekList>

                <IconList  object={object}  keySuffix="_editor_icon_list_" >
                    <MediaMenu />
                </IconList>

            </Fragment>
        )
    }

}

