import React from "react"
import {connect} from 'react-redux'
import './SpeekEditor.css'
import MediaData, {
    MediaType,
    mediaTypeName,
    RelationType,
    relationTypeName,
    WebLinkType
} from "../../../models/MediaData"
import storeDispatcher from "../../../storeDispatcher"
import InfoManager from "../../../managers/InfoManager"
import AudioManager from "../../../managers/AudioManager"
import ObjectData from "../../../models/ObjectData"
import ServerManager from "../../../managers/ServerManager"
import LanguageSelector from "../LanguageSelector"
import {SimpleHint} from "../../Additional"
import CommonPreferences, {TargetType} from "./CommonPreferences"
import ContentFlagList from "./ContentFlagList"
import TargetPlatformList from "./TargetPlatformList"
import ObjectSelector from "../../ObjectSelector"
import AdditionalTagEditor from "../../AdditionalTagEditor/AdditionalTagEditor"
import AvatarEditor from "../../AvatarEditor/AvatarEditor"
import ObservableSelect from "../ObservableSelect/ObservableSelect"
import {Rights} from "../../../models/TaggableData";
import DescriptionEditor from "../DescriptionsEditor";


interface PropsType {
    media?: any,
    parentId?: any,
    mediaId: any,
    mediaType: any,
    object: ObjectData,
    me: any,
    project: any
}


class MediaEditor extends React.Component<PropsType>
{
    getInitialState = () =>({
        tags: {},
        // fileData: null,
        fileData: undefined,
        textData: undefined,
        fileName: undefined,
        isAvatarEditorActive: false,
        _processing: false,
        _bitRate: 48,
        _audioDuration: 0,
        isTagsEditorActive: false
    })

    state: Record<string, any> = this.getInitialState()

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (!prevProps.parentId && this.props.parentId) {
            this.setState({
                tags: {
                    ...this.state.tags,
                    ParentSoundtrack: this.props.parentId
                }
            })
        }

        if (!prevProps.media && this.props.media) {
            console.log(this.props.media)
        }
    }

    onTagsEditorShow(value = true) {
        this.setState({
            isTagsEditorActive: value
        })
    }

    onAvatarEditorShow(value = true) {
        this.setState({
            isAvatarEditorActive: value
        })
    }

    onClose(e?: any) {
        if (e) {
            e.stopPropagation()
        }

        this.setState(this.getInitialState())
        storeDispatcher.mediaEdit(null)
    }

    onSelectIconFile = () => {
        InfoManager.waitFileDialog2(false, false, 'image/png')
            .then(data => {
                if (!data) {
                    return
                }
                const fileData = data.split('base64,')[1]
                this.setState({fileData})
            }).catch(()=>{})
    }

    onPersonObjectSelect = (objectId: any) => {
        this.setState({
            tags: {
                ...this.state.tags,
                AuthorObjectId: objectId
            }
        })
    }

    onFileOpen() {
        InfoManager.waitFileDialog2(false, false, 'audio/*, .m4a', true).then(([data, file]) => {

            const fileData = data && data.includes(';base64,') ? data.split(';base64,')[1] : null
            const fileName = file.name || null
            const fileSize = file.size || null

            this.setState({
                ...this.state,
                fileData,
                fileName,
                fileSize
            })

            AudioManager.getAudioDuration(fileData).then(
                duration => {
                    this.setState({
                        _audioDuration: duration
                    })
                }
            )
        })
    }

    onRemoveSoundtrackData() {
        this.setState({
            ...this.state,
            fileData: null,
            fileName: null
        })
    }

    onPlayAudio = () => {
        const {media} = this.props
        const fileData = this.state.fileData || media.fileData

        AudioManager.play({...media, fileData}).then()
    }

    onInputCheck(name: string, event: any, onState: any, offState: any) {
        try {
            const nextState = event.target.checked ? onState : offState
            console.log(name, nextState, onState, offState)

            this.setState({
                ...this.state,
                tags: {
                    ...this.state.tags,
                    [name]: nextState || null
                }
            })

        } catch (e) {
            console.log(e)
        }
    }

    onTagChange(e: any, tagName: any) {
        this.setState({
            ...this.state,
            tags: {
                ...this.state.tags,
                [tagName]: e.target ? e.target.value || null : e
            }
        })
    }

    onChange(e: any, name: any) {
        this.setState({
            ...this.state,
            [name]: e.target.value
        })
    }

    onLanguageSelect(code: any) {
        this.setState({
            tags: {
                ...this.state.tags,
                Language: code || null
            }
        })
    }

    onImageFileDataChange(data: any) {
        this.setState({
            ...this.state,
            fileData: data.includes('base64') ? data.split('base64,')[1] || null : data
        })
    }

    async onSave(e?: any) {
        const {object, me, mediaId, mediaType} = this.props
        const transferPartialThreshold = 1000000

        this.setState({_processing: true})
        const eventHandlerId = ServerManager.instance.dispatchEventHandler('disconnect', () => {
            this.setState({_processing: false})
            InfoManager.showMsg('Connection error! Please try again')
        })

        const bitRate = mediaType === MediaType.speak ? this.state._bitRate * 1 : undefined

        if (bitRate && bitRate !== 777 && (this.state._audioDuration * bitRate * 1024 / 8 * 1.33 > 1000000)) {
            InfoManager.showMsg('File size limit exceeded!')
            this.setState({_processing: false})
            ServerManager.instance.dispatchEventHandler('disconnect', false, eventHandlerId)

            return
        }

        if (mediaId) {

            const changes = {...this.state}
            if (changes.textData === "") changes.textData = false
            if (changes.fileData === "") changes.fileData = false

            if (changes.fileData && changes.fileData.length > transferPartialThreshold) {
                const success = await ServerManager.instance.sendFileByParts(changes.fileData, transferPartialThreshold)

                if (!success) {
                    ServerManager.instance.dispatchEventHandler('disconnect', false, eventHandlerId)
                    InfoManager.showMsg('File transfer error.')
                    this.setState({_processing: false})
                    return
                }

                changes.fileData = true
            }

            ServerManager.instance.setMediaChanges(object.objectId, mediaId, changes, bitRate).then(
                (answer) => {
                    console.log(answer)
                    if (!answer.status) {
                        InfoManager.showMsg(answer.msg || 'Error')
                    }
                    this.onClose()

                    ServerManager.instance.dispatchEventHandler('disconnect', false, eventHandlerId)
                }
            )
        }
        else if (mediaId === false) {

            //  Data complete checks for all MediaTypes
            let isDataComplete = true
            if (   (mediaType === MediaType.speak && !this.state.textData && !this.state.fileData && !this.state.tags.Language)
                || (mediaType === MediaType.avatar && !this.state.fileData)
                || (mediaType === MediaType.icon && !this.state.fileData)
                || (mediaType === MediaType.webLink && !this.state.tags.LinkUrl)
                || (mediaType === MediaType.relatedObj && !this.state.tags.RelatedObjectId)
                || (mediaType === MediaType.textDoc && !this.state.textData && !this.state.tags.Language)
            ){
                isDataComplete = false
            }


            if (isDataComplete) {
                const {textData, fileData = null, tags} = this.state
                const objectId = object.objectId

                // tags.Language = tags.Language ? tags.Language : (tags.ParentSoundtrack ? null : 'en')
                if (!tags.Language) {
                    delete tags.Language
                }

                const speekData: any = {
                    objectId,
                    tags: {
                        ...tags,
                        CreatorId: me.userId,
                        MediaType: mediaType
                    }
                }
                if (textData) speekData.textData = textData
                if (fileData) speekData.fileData = fileData

                if (fileData && fileData.length > transferPartialThreshold) {
                    const success = await ServerManager.instance.sendFileByParts(fileData, transferPartialThreshold)

                    if (!success) {
                        ServerManager.instance.dispatchEventHandler('disconnect', false, eventHandlerId)
                        InfoManager.showMsg('File transfer error.')
                        this.setState({_processing: false})
                        return
                    }

                    speekData.fileData = true
                }

                ServerManager.instance.addNewSpeek(speekData, bitRate).then(
                    (answer) => {
                        console.log(answer)
                        if (!answer.status) {
                            InfoManager.showMsg(answer.msg || 'Error')
                        }
                        this.onClose()
                        ServerManager.instance.dispatchEventHandler('disconnect', false, eventHandlerId)
                    }
                )
            }
            else {
                let incompleteMessageText = "Fill the important data please"

                if (mediaType === MediaType.speak) {
                    incompleteMessageText = "Select language and enter the text for speech or attach an audio file"
                }
                else if (mediaType === MediaType.avatar) {
                    incompleteMessageText = "You should attach an image file"
                }
                else if (mediaType === MediaType.webLink) {
                    incompleteMessageText = "You should specify URL"
                }
                else if (mediaType === MediaType.textDoc) {
                    incompleteMessageText = "You should add a text data and select a language"
                }
                else if (mediaType === MediaType.icon) {
                    incompleteMessageText = "You should attach an .png file"
                }
                else if (mediaType === MediaType.relatedObj) {
                    incompleteMessageText = "You should select an object"
                }

                InfoManager.showMsg(incompleteMessageText)
                this.setState({_processing: false})
                ServerManager.instance.dispatchEventHandler('disconnect', false, eventHandlerId)

            }
        }


    }

    renderParentList(mediaEntries: Array<MediaData> = [], parentID = null, mediaId: any) {

        return (
            <select value={this.state.tags.ParentSoundtrack === undefined ? parentID || '' : this.state.tags.ParentSoundtrack || '' }
                onChange={e => this.onTagChange(e, 'ParentSoundtrack')}>

                <option value={''} key={'parentSelect_null'}>No parent</option>
                {
                    mediaEntries.filter(it => it.isSpeak).map(media => {
                        if (media.parentSpeekId || media.mediaId === mediaId ) return null

                        console.log(111, media.language)
                        return (
                            <option value={media.mediaId}
                                key={'parentSelect_' + media.mediaId} >
                            {media.getDescription || media.isoDateTime}
                            </option>
                        )
                    })
                }
            </select>
    )
    }

    renderAvatarList() {
        const {object, media} = this.props

        const tagName = 'AvatarMediaId'
        const currentAvatarId = this.state.tags[tagName] !== undefined
            ? this.state.tags[tagName] || ''
            : media.tags[tagName] || ''

        return (
            <select value={currentAvatarId}
                    onChange={e => this.onTagChange(e, tagName)}>

                <option value={''} key={tagName}>Undefined</option>
                {
                    object.mediaEntries
                        .filter(it => it.isAvatar)
                        .map(media => {
                            return (
                                <option value={media.mediaId}
                            key={tagName + media.mediaId} >
                                {media.getDescription || media.comment || media.isoDateTime}
                                </option>
                        )
                        })
                }
            </select>
        )
    }

    renderPreferredSelection() {
        const {object, media} = this.props

        const tagPrevName = 'PrefPrevSpeakId'
        const tagNextName = 'PrefNextSpeakId'

        const currentPrevId = (this.state.tags[tagPrevName] !== undefined
            ? this.state.tags[tagPrevName]
            : media.tags[tagPrevName]) || ''

        const currentNextId = (this.state.tags[tagNextName] !== undefined
            ? this.state.tags[tagNextName]
            : media.tags[tagNextName]) || ''

        const conformingMediaList = object.mediaEntries
            .filter(it => it.mediaType === media.mediaType && it.mediaId !== media.mediaId)

        return (
            <>
                <u>Preferred previous media:</u><br/>
                <select value={currentPrevId}
                        onChange={e => this.onTagChange(e, tagPrevName)}>

                    <option value={''} key={tagPrevName}>Undefined</option>
                    {
                        conformingMediaList.map(media => {
                                return (
                                    <option value={media.mediaId}
                                            key={tagPrevName + media.mediaId}>
                                        {media.getDescription || media.comment || media.isoDateTime}
                                    </option>
                                )
                            })
                    }
                </select>

                <u>Preferred next media:</u><br/>
                <select value={currentNextId}
                        onChange={e => this.onTagChange(e, tagNextName)}>

                    <option value={''} key={tagNextName}>Undefined</option>
                    {
                        conformingMediaList.map(media => {
                                return (
                                    <option value={media.mediaId}
                                            key={tagNextName + media.mediaId}>
                                        {media.getDescription || media.comment || media.isoDateTime}
                                    </option>
                                )
                            })
                    }
                </select>


            </>
        )
    }

    renderLectorVisibilitySelection() {
        const {media} = this.props

        const tagName = 'LectorVisibility'

        const currentState = (this.state.tags[tagName] !== undefined
            ? this.state.tags[tagName]
            : media.tags[tagName]) || ''

        const valueList = [
                {value: "", text: "Invisible"},
                {value: "showMin", text: "Show minimum"},
                {value: "showAll", text: "Show all"}
            ]

        return (
            <select value={currentState}
                    onChange={e => this.onTagChange(e, tagName)}>
                {
                    valueList.map(({value, text}) => {
                        return (
                            <option value={value}
                                    key={tagName + value}>
                                {text}
                            </option>
                        )
                    })
                }
            </select>
        )
    }

    renderSaveButton = () => {
        const {mediaId} = this.props
        const {_processing} = this.state

        if (_processing) return (
            <div className='SESave'>
                {/*Uploading <i className="fas fa-spinner rotate" />*/}
        {/*Uploading <i className="far fa-hourglass" />*/}
        Uploading <i className="fas fa-hourglass-half" />
            </div>
    )

        if (mediaId) return (
            <div className='SESave likeBtn' onClick={e => this.onSave(e)}>
        <i className='far fa-save' /> Save changes
        </div>
    )

        return (
            <div className='SESave likeBtn' onClick={e => this.onSave(e)}>
        <i className='fas fa-plus' /> Add media
        </div>
    )
    }

    onCommentChange(e: any) {
        this.setState({
            ...this.state,
            tags: {
                ...this.state.tags,
                Comment: e.target.value || null
            }
        })
    }

    renderBitrateSelect = () => {
        const {me} = this.props
        const {_bitRate, fileData, fileName} = this.state


        if ((me.hasRight(Rights.ADMIN) || me.isSuperAdmin) && fileData) return (
            <select name="bitRate"
        className="MEBitrateSelect"
        onChange={e => this.onChange(e, '_bitRate')}
        value={_bitRate}>
        <option value="24">24 kbps</option>
        <option value="32">32 kbps</option>
        <option value="48">48 kbps</option>
        <option value="64">64 kbps</option>
        <option value="96">96 kbps</option>
        <option value="128">128 kbps</option>
        {
            fileName.includes('.m4a')
                ? <option value="777">Original quality</option>
        : null
        }
        </select>
    )

        return null
    }

    render() {
        const {mediaId, parentId, media, project, object, me} = this.props
        if (!media && mediaId === null) {
            return null
        }

        const {fileData, textData, tags, fileName, fileSize} = this.state
        const speekText = typeof textData !== 'undefined' ? textData || '' : media.textData || ''
        const isFileAttached = !!fileData || (typeof fileData === 'undefined' && !!media.fileData)

        const selectedObjectId = this.state.tags.hasOwnProperty('AuthorObjectId')
            ? this.state.tags.AuthorObjectId
            : media.tags.AuthorObjectId

        const hasParent = tags.hasOwnProperty('ParentSoundtrack') ? !!tags.ParentSoundtrack : !!media.tags.ParentSoundtrack
        const parentSpeek = hasParent ? object.getSpeek(tags.ParentSoundtrack || media.tags.ParentSoundtrack) : null

        const estimatedFileSize = this.state._bitRate !== '777'
            ? InfoManager.bytes(this.state._audioDuration * this.state._bitRate * 1024 / 8)
            : InfoManager.bytes(this.state.fileSize)

        const mediaTypeHeader = mediaTypeName[media.mediaType]

        const relationType = tags.RelationType === undefined ? media.tags.RelationType : tags.RelationType
        const relationTypeList = [
            {id: '', name: 'Undefined'},
            ...Object.entries(RelationType).map(([id, name]) => ({id, name: relationTypeName[name]}))
        ]

        const selectorCurrentLanguage = tags.hasOwnProperty('Language')
            ? tags.Language
            : (hasParent && parentSpeek)
                ? parentSpeek.language
                : media.language

        // Language dependent descriptions
        const isLangIgnoring = this.state.tags.hasOwnProperty('IgnoreLang')
            ? this.state.tags.IgnoreLang
            : media.tags.IgnoreLang
        const  isLanguageDependent = this.state.tags.hasOwnProperty('Language')
            ? this.state.tags.Language
            : media.tags.Language
        const showLanguageDependentDescriptions = isLangIgnoring || !isLanguageDependent

        const descriptionMap = Object.entries({...media.tags, ...this.state.tags})
            .filter(([tagName, _]) => {
                return tagName.includes("Description_")
            })
            .reduce((acc:  Record<string, any>, [tagName, value]) => {
                const l = tagName.split("Description_")[1]
                return {
                    ...acc,
                    [l]: value
                }
            }, {})

        return (
            <>
                <div className='modalWindow' onClick={(e) => this.onClose(e)} >
                    <div className='SpeekEditorContainer centered' onClick={e => e.stopPropagation()}>
                        <div className='SEHeader'>
                            <h3> {mediaId ? `${mediaTypeHeader} editor` : `Add new ${mediaTypeHeader.toLowerCase()}`}</h3>
                        </div>

                        <i className={"MediaTagsEditorIcon likeBtn fas fa-tags" + (me.isSuperAdmin ? '' : ' hided')}
                           onClick={() => this.onTagsEditorShow(true)}
                           title="Tags"/>

                        <div className='SELeft'>
                            {
                                media.mediaType === MediaType.speak &&
                                    <>
                                        <u>Parent media:</u><br/>
                                        {this.renderParentList(object.mediaEntries, parentId || media ? parentId || media.parentSpeekId : null, mediaId)}<br/>
                                    </>
                            }

                            <u>Language:</u><br/>
                            <LanguageSelector onSelect={(code: any) => this.onLanguageSelect(code)}
                                              readOnly={false /*hasParent*/}
                                              allowUndefined={media.mediaType !== MediaType.speak}
                                              initLanguageCode={selectorCurrentLanguage} />
                            <br/>
                            <br/>

                            <u>Description:</u><br/>
                            <input type="text"
                                   className='SEDescription'
                                   value={this.state.tags.Description === undefined ? media.tags.Description || '' : this.state.tags.Description || ''}
                                   onChange={e => this.onTagChange(e, 'Description')} /><br/>


                            {
                                showLanguageDependentDescriptions &&
                                    <>
                                        <u>Descriptions in different languages :</u><br/>
                                        <DescriptionEditor
                                            descriptions={descriptionMap}
                                            onChangeDescription={(l: string, d: any) => this.onTagChange(d, "Description_" + l)}
                                        />
                                        <br/>
                                    </>
                            }
                            <u>Hidden comment:</u><br/>
                            <input type="text"
                                   className=''
                                   value={this.state.tags.Comment === undefined ? media.getComment || '' : this.state.tags.Comment || ''}
                                   onChange={e => this.onCommentChange(e)} /><br/>



                            {
                                media.mediaType === MediaType.speak &&
                                    <>
                                        <u>Preferred avatar:</u>
                                        <br/>
                                        {
                                            this.renderAvatarList()
                                        }

                                        <u>Text content:</u>
                                        <SimpleHint>Audio will be generated automatically from the text below, in case if no audio was attached to an object</SimpleHint>
                                        <br/>
                                        <textarea value={speekText}
                                                  onChange={e => this.onChange(e, 'textData')}/>
                                        <br/>

                                        <u>Audio content:</u> <this.renderBitrateSelect />
                                        <br/>
                                        <div style={{display: 'flex', marginTop: 5}}>
                                            <div className={isFileAttached ? 'SEAudioControl likeBtn' : 'hided'}
                                                 onClick={e => this.onPlayAudio()}>
                                                <i className='fas fa-play' />
                                            </div>

                                            <div className={isFileAttached ? 'SEAudioControl likeBtn' : 'hided'}
                                                 onClick={() => this.onRemoveSoundtrackData()}>
                                                <i className='far fa-trash-alt' />
                                            </div>

                                            <div className="SEUploadAudio likeBtn SEAudioControl" onClick={() => this.onFileOpen()}>
                                                <i className={fileName ? 'far fa-file-audio' : 'fas fa-upload'} /> {
                                                fileName ? `${fileName} (original ${InfoManager.bytes(fileSize)}, compressed ~ ${estimatedFileSize})` : 'Upload audio file'
                                            }
                                            </div>
                                        </div>
                                        <br/>

                                        <u>Lector visibility:</u>
                                        <br/>
                                        {
                                            this.renderLectorVisibilitySelection()
                                        }
                                </>
                            }

                            {
                                (media.mediaType !== MediaType.speak && media.mediaType !== MediaType.textDoc && media.mediaType !== MediaType.icon) &&
                                    <>
                                        <u>Avatar image data:</u><br/>
                                        <div className="AIImgBlock">
                                            <img src={MediaData.imageDataFrom(this.state.fileData) || media.imageData} className='AvatarItemImg' alt='' />
                                        </div>
                                        <div className="likeBtn MEAvatarChangeBtn"
                                             onClick={this.onAvatarEditorShow.bind(this, true)}>
                                            <i className="far fa-image" />
                                            Update image data
                                        </div>

                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </>
                            }

                            {
                                media.mediaType === MediaType.webLink &&
                                    <>
                                        <u>Link URL:</u><br/>
                                        <input type="text"
                                               className='OptionSelect'
                                               value={tags.LinkUrl === undefined ? media.tags.LinkUrl || '' : tags.LinkUrl || ''}
                                               onChange={e => this.onTagChange(e, 'LinkUrl')} /><br/>

                                        <u>Type:</u><br/>
                                        <ObservableSelect name='LinkType'
                                                          value={tags.LinkType === undefined ? media.tags.LinkType || '' : tags.LinkType || ''}
                                                          optionList={Object.entries(WebLinkType).map(([id, name]) => ({id, name}))}
                                                          onChange={(name: any, value: any) => this.onTagChange(value, name)}
                                                          keyPrefix={'EditorLinkType'} />

                                        <u>Link key:</u><br/>
                                        <input type="text"
                                               className='OptionSelect'
                                               value={tags.LinkKey === undefined ? media.tags.LinkKey || '' : tags.LinkKey || ''}
                                               onChange={e => this.onTagChange(e, 'LinkKey')} /><br/>
                                    </>
                            }

                            {
                                media.mediaType === MediaType.textDoc &&
                                    <>
                                    <u>Text content:</u>
                                    <SimpleHint>Text will be displayed under the avatar</SimpleHint>
                                    <br/>
                                    <textarea value={speekText}
                                              onChange={e => this.onChange(e, 'textData')}/>

                                    <br/>
                                </>
                            }

                            {
                                media.mediaType === MediaType.icon &&
                                    <>
                                        <u>Image data:</u><br/>
                                        <div className="AIImgBlock">
                                            <img src={MediaData.imageDataFrom(this.state.fileData) || media.imageData} className='AvatarItemImg' alt='' />
                                        </div>
                                        <div className="likeBtn MEAvatarChangeBtn"
                                             onClick={this.onSelectIconFile.bind(this)}>
                                            <i className="far fa-image" />
                                            select icon
                                        </div>
                                    </>
                            }

                            {
                                media.mediaType === MediaType.relatedObj &&
                                    <>
                                        <u>Relation type:</u><br/>
                                        <ObservableSelect name='RelationType'
                                                          className=''
                                                          value={relationType}
                                                          optionList={relationTypeList}
                                                          onChange={(name:any, value: any) => this.onTagChange(value, name)}
                                                          keyPrefix={'EditorRelationType'} />

                                        <u>Relation key:</u><br/>
                                        <input type="text"
                                               className=''
                                               value={tags.RelationKey === undefined ? media.tags.RelationKey || '' : tags.RelationKey || ''}
                                               onChange={e => this.onTagChange(e, 'RelationKey')} /><br/>

                                        <u>Related object:</u><br/>
                                        <ObjectSelector selectedObjectId={tags.RelatedObjectId === undefined ? media.tags.RelatedObjectId || '' : tags.RelatedObjectId || ''}
                                                        filterTags={relationType === RelationType.author ? {ObjectClass: 'person'} : undefined}
                                                        placeholder={(relationTypeName[relationType] || 'Select') + ' object'}
                                                        onSelect={(value: any) => this.onTagChange(value, 'RelatedObjectId')} />

                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </>
                            }

                            {
                                media.mediaType !== MediaType.icon && media.mediaType !== MediaType.relatedObj &&
                                    this.renderPreferredSelection()
                            }

                        </div>


                        <div className='SERight'>
                            <CommonPreferences
                                usageTargetType={TargetType.media}
                                onInputCheck={this.onInputCheck.bind(this)}
                                keySuffix={'SpeekEditor'}
                                tags={{...media.tags, ...this.state.tags}} />

                            <br/>
                            <br/>

                            <ContentFlagList contentFlagList={project ? project.contentFlagList : null}
                                             tags={{...media.tags, ...this.state.tags}}
                                             keySuffix='SpeekEditor'
                                             onInputCheck={this.onInputCheck.bind(this)} />
                            <br/>
                            <br/>

                            <TargetPlatformList tags={{...media.tags, ...this.state.tags}}
                                                onInputCheck={this.onInputCheck.bind(this)} />
                        </div>


                        <div className='SEFooter'>
                            <this.renderSaveButton />

                            {
                                media.mediaType === MediaType.speak &&
                                    <>
                                        <div className='SEPersonObjectBlock'>
                                            <ObjectSelector selectedObjectId={selectedObjectId}
                                                            filterTags={{ObjectClass: 'person'}}
                                                            placeholder="Choose object with lector description"
                                                            onSelect={this.onPersonObjectSelect.bind(this)} />


                                        </div>
                                    </>
                            }

                        </div>
                    </div>

                    <i className='fas fa-times closeButton likeBtn'
                       onClick={(e) => this.onClose(e)} />
                </div>


                <AdditionalTagEditor mediaId={mediaId}
                                     objectId={media.objectId}
                                     isActive={this.state.isTagsEditorActive}
                                     onClose={() => this.onTagsEditorShow(false)}/>

                <AvatarEditor onSave={this.onImageFileDataChange.bind(this)}
                              onClose={this.onAvatarEditorShow.bind(this, false)}
                              isActive={this.state.isAvatarEditorActive}
                              src={media.imageData}/>
            </>
        )
    }

}


const mapStateToProps = (state: Record<string, any>) => {
    const {objects, editingMediaId: mediaId, newMediaType: mediaType, projects, me} = state
    if (!mediaId && mediaId !== false) return {mediaId}

    const object = objects[state.editingObjectId]
    const project = Object.values(projects).find((item: any) => item.projectId === object.projectId)

    const media = mediaId ? object.getSpeek(mediaId) : new MediaData({objectId: object.objectId, tags: {MediaType: mediaType}})
    media.parent = object

    return {
        mediaId,
        mediaType,
        media,
        object,
        me,
        project
    }
}
export default connect(mapStateToProps)(MediaEditor)
