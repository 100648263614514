import React from 'react';
import {connect} from 'react-redux';
import storeDispatcher from '../../storeDispatcher';
import ServerManager from "../../managers/ServerManager";
import CommonMap from "../CommonMap/CommonMap";
import {serializeLocationTag} from "../../modelControllers/TaggableControl"


class ObjectLocationMap extends React.Component {

    state = {};

    onSavePosition = (location) => {
        const ObjectCoords = serializeLocationTag(location);
        ServerManager.instance.setTags(this.props.objectId, null, null, null, null, [{ObjectCoords}])
            .then(
                () => this.onClose()
            );
    };

    onClose = (e) => {
        try {
            e.stopPropagation();
            e.preventDefault();
        } catch (e) {
        }

        this.setState({
            rad: undefined,
            lat: undefined,
            lng: undefined
        });

        storeDispatcher.updateMapParams({
            ...this.props.map,
            isActive: false
        });
    };

    render() {
        const {isActive, position} = this.props.map;

        return <CommonMap isActive={isActive}
                          onClose={e => this.onClose(e)}
                          location={position}
                          onSubmit={(data) => this.onSavePosition(data)} />
    }

}


const mapStateToProps = ({map, editingObjectId: objectId}) => ({map, objectId});
export default connect(mapStateToProps)(ObjectLocationMap);