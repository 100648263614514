import React from 'react';
import OptionsBar from "../components/OptionsBar";
import {connect} from "react-redux";
import StoreManager from "../managers/StoreManager";
import UserList from "../components/UserList";

class UserListPage extends React.Component {

    componentDidMount() {
        console.log(this.props.userList);
        StoreManager.updateUserList();
    }

    render() {
        const {userList, history} = this.props;

        return (
            <React.Fragment>
                <OptionsBar>
                    Users ({userList.length})
                </OptionsBar>
                <div className='MainContainer'>
                    <UserList userList={userList} history={history} />
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    userList: Object.values(state.users)
});
export default connect(mapStateToProps)(UserListPage);