import React from "react"
import {SelectOptionType} from '../../../models/CustomTypes'
type PropsType = {
    name: string,
    value: any,
    optionList: Array<SelectOptionType>,
    onChange: Function,
    keyPrefix: string,
    className?: string
}


export default class ObservableSelect extends React.Component {

    constructor(props: PropsType) {
        super(props);
        this.props = props;
    }

    props: PropsType;

    render() {
        const {name, value, optionList, onChange, keyPrefix, className="OptionSelect"} = this.props;
        
        return (
            <select className={className}
                    onChange={(e) => onChange(name, e.target.value)}
                    value={value || ''}>
                {
                    optionList.map( (item: SelectOptionType) => {
                        return (
                            <option value={item.id}
                                    key={keyPrefix + item.id}>
                                {item.name || item.id}
                            </option>
                        )
                    })
                }
            </select>
        );
    }
}