import React from "react";
import './Project.css';
import {connect} from "react-redux";
import Checkbox from "react-three-state-checkbox";


class ProjectMemberAccessBlock extends React.Component {

    state = {

    };

    onChange(rule) {
        const {onChange: callBack, userAccess = {}, readOnly} = this.props;
        if (readOnly) return;

        let currentState = this.state[rule];

        if (currentState === undefined) {
            currentState = 2 - (2 * !this.isIndeterminate(rule)) + (1 * this.isChecked(rule));
        }

        const nextState = currentState >= 2 ? 0 : currentState + 1;

        this.setState({
            [rule]: nextState
        });

        const modifiedAccess = {...userAccess, ...this.state, [rule]: nextState};
        for (let key in modifiedAccess) {
            if (!modifiedAccess.hasOwnProperty(key)) continue;

            if (modifiedAccess[key] === 2) {
                delete modifiedAccess[key];
                continue;
            }

            modifiedAccess[key] = !!modifiedAccess[key];
        }

        callBack(modifiedAccess);
    }

    isChecked(rule) {
        const {userAccess = {}} = this.props;
        const byUser = userAccess[rule];

        // return byUser !== undefined ? byUser : false;
        return this.state[rule] === undefined ? (byUser !== undefined ? byUser : false) : (this.state[rule] === 1);
    }

    isIndeterminate(rule) {
        const {userAccess = {}} = this.props;
        const byUser = userAccess[rule];

        return this.state[rule] === undefined ? byUser === undefined : this.state[rule] === 2;
    }

    isOn(rule) {
        const {defaultAccess = '', userAccess = {}, isAdmin} = this.props;
        if (isAdmin) return true;

        const byDefault = defaultAccess.includes(rule);
        const byUser = userAccess[rule];
        const valueOnInit = userAccess.hasOwnProperty(rule) ? byUser : byDefault;

        return this.state[rule] === undefined ? valueOnInit : (this.state[rule] === 2 ? byDefault : this.state[rule] === 1);
    }

    render() {
        const {accessList = []} = this.props;

        return(
            <div className="ProjectMABlock">
                {
                    accessList.map(rule => {
                        return (
                            <React.Fragment key={`AccessBox${rule}`}>
                            <Checkbox checked={this.isChecked(rule)}
                                      indeterminate={this.isIndeterminate(rule)}
                                      onChange={() => this.onChange(rule)} />

                            <span style={{cursor: 'pointer', color: this.isOn(rule) ? 'darkcyan' : 'orangered'}}
                                  onClick={() => this.onChange(rule)}
                                  title={this.isOn(rule) ? 'Rule is enabled' : 'Rule is disabled'}>
                                {' ' + rule}
                            </span>
                                <br/>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({

});
export default connect(mapStateToProps)(ProjectMemberAccessBlock);