

export default class HierarchicalObject {
    _parent?: any | null;

    get parent(): Record<string, any> {
        return this._parent;
    }

    set parent(value: Record<string, any>) {
        this._parent = value;
    }

}