import React from 'react';
import './Header.css';
import logo from '../../resources/logo.svg'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Utils from "../../managers/Utils";
import {Rights} from "../../models/TaggableData";

class Header extends React.Component {

    render() {
        const {isLoggedIn, me, pendingData, groupInvitations: {hasInvitation}} = this.props;
        const isAdmin = me && me._data && me.hasRight(Rights.ADMIN);
        const isDeveloper = me && me._data && me.hasRight(Rights.DEVELOPER);
        const isSuper = me && me._data && me.isSuperAdmin;
        const hasPendingObject = !!pendingData.objectId;

        return (
            <div id='Header'>
                <div id='MainLogo'>
                    <img id='LogoImg' src={logo} alt='Explainit' />
                    <br />
                    Explore art in a simple way
                </div>
                <div id='MainMenu' className={isLoggedIn ? '' : 'hided'}>
                    <Link to='/' className='likeBtn MainMenuItem'>
                        <i className='fal fa-object-group' />
                        Object list
                    </Link>

                    <Link to='/projects' className='likeBtn MainMenuItem'>
                        <i className='fad fa-users-class' />
                        Groups
                        {
                            hasInvitation ?
                                <div className='MMNotificationLabel'>
                                    {
                                        hasInvitation
                                    }
                                </div>
                                : null
                        }
                    </Link>

                    <Link to='/users' className={'likeBtn MainMenuItem' + (isAdmin?'':' hided')}>
                        <i className='fad fa-users' />
                        Users
                    </Link>

                    <Link to='/profile' className='likeBtn MainMenuItem'>
                        <i className='fas fa-user' />
                        Profile
                    </Link>

                    <Link to='/pending' className={'likeBtn MainMenuItem' + (isAdmin?'':' hided')}>
                        <i className='fas fa-gavel' />
                        Pending
                        {
                            hasPendingObject ? <i className='fas fa-bell MMPendingAlert'/> : null
                        }
                    </Link>

                    <Link to='/admin' className={'likeBtn MainMenuItem' + (isSuper?'':' hided')}>
                        <i className='fas fa-user-shield' />
                        SuperAdmin
                    </Link>

                    <Link to='/repository' className={'likeBtn MainMenuItem' + (isDeveloper?'':' hided')}>
                        <i className='fad fa-folder-tree' />
                        Repository
                    </Link>

                    <div className='likeBtn MainMenuItem' onClick={Utils.signOut}>
                        <i className='fad fa-sign-out-alt' />
                        Sign out
                    </div>
                </div>
            </div>
        )
    }

}



const mapStateToProps = ({isLoggedIn, me, pendingData, groupInvitations}) => ({
    isLoggedIn,
    me,
    pendingData,
    groupInvitations
});
export default connect(mapStateToProps)(Header);
