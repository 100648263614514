export const defaultAction = (value) => {
    return {
        type: 'DEFAULT_ACTION',
        value
    }
};

export const logIn = (value) => {
    return {
        type: 'LOG_IN',
        value
    }
};

export const projectsUpdate = (value) => {
    return {
        type: 'PROJECTS_UPDATE',
        value
    }
};

export const objectsUpdate = (value) => {
    return {
        type: 'OBJECTS_UPDATE',
        value
    }
};

export const objectEdit = (value) => {
    return {
        type: 'OBJECT_EDIT',
        value
    }
};

export const mediaEdit = (value) => {
    const mediaId = value && typeof value === 'object' ? value.mediaId : value;
    const parentId = value && typeof value === 'object' ? value.parentId : null;
    const mediaType = value && typeof value === 'object' ? value.mediaType : null;

    return {
        type: 'SPEEK_EDIT',
        value: {mediaId, parentId, mediaType}
    }
};

export const speekPlay = (value) => {
    return {
        type: 'SPEEK_PLAY',
        value
    }
};

export const sightView = (value) => {
    return {
        type: 'SIGHT_VIEWER',
        value
    }
};

export const userListUpdate = (value) => {
    return {
        type: 'USERS_UPDATE',
        value
    }
};

export const userUpdate = (value) => {
    return {
        type: 'USER_UPDATE',
        value
    }
};

export const setFilter = (value) => {

    return {
        type: 'SET_FILTER',
        value
    }
};

export const cleanFilter = (value) => {
    return {
        type: 'CLEAN_FILTER',
        value
    }
};

export const objectCounter = (value) => {
    return {
        type: 'OBJECT_COUNT',
        value
    }
};

export const selectObject = (value) => {
    return {
        type: 'SELECT_OBJECT',
        value
    }
};

export const setObjectSelection = (value) => {
    return {
        type: 'SET_OBJECT_SELECTION',
        value
    }
};

export const setObjectSelectorMap = (value) => {
    return {
        type: 'SET_OBJECT_SELECTOR_MAP',
        value
    }
};

export const updateMe = (value) => {
    return {
        type: 'UPDATE_ME',
        value
    }
};

export const updateAuthor = (value) => {
    return {
        type: 'UPDATE_AUTHOR',
        value
    }
};

export const updateImportingStructure = (value) => {
    return {
        type: 'UPDATE_IMPORTING_STRUCTURE',
        value
    }
};

export const updateImportingChecks = (value) => {
    return {
        type: 'UPDATE_IMPORTING_CHECKS',
        value
    }
};

export const updateMapParams = (value) => {
    return {
        type: 'UPDATE_MAP_PARAMS',
        value
    }
};

export const updatePendingData = (value) => {
    return {
        type: 'UPDATE_PENDING_DATA',
        value
    }
};

export const updateCreators = (value) => {
    return {
        type: 'UPDATE_CREATORS',
        value
    }
};

export const updateGroupInvitations = (value) => {
    return {
        type: 'UPDATE_GROUP_INVITATIONS',
        value
    }
};

export const updateExtendableObjects = (value) => {
    return {
        type: 'UPDATE_EXTENDABLE_OBJECTS',
        value
    }
};

