import React, {Ref} from "react";
import './LanguageSelector.css';
import InfoManager from "../../../managers/InfoManager";


export default class LanguageSelector extends React.Component<any> {

    state = {
        isActive: false,
        filter: null
    }

    searchInput: any = null

    onShowHide() {
        if (!this.props.readOnly) {
            this.setState({
                isActive: !this.state.isActive,
                filter: null
            });

            if (this.searchInput) {
                this.searchInput.value = ''
            }
        }
    }

    onChangeLanguage(code: any) {
        const langIndex = InfoManager.usedLanguages.indexOf(code)
        if (langIndex > -1) {
            InfoManager.usedLanguages.splice(langIndex, 1)
        }
        InfoManager.usedLanguages.push(code)

        this.props.onSelect(code)
    }

    onChangeFilter(e: any) {
        if (e.keyCode === 13 || 1) {
            const f = this.searchInput.value ? this.searchInput.value.toLowerCase() : null
            const list = InfoManager.sortedLanguageList.filter(language => {
                return !(
                    f
                    && !language.name.toLowerCase().includes(f)
                    && !language.nativeName.toLowerCase().includes(f)
                )
            });

            if (list.length === 1) {
                this.onChangeLanguage(list[0].code)
                this.onShowHide()
            }
        }

        this.setState({
           filter: this.searchInput.value || null
        });
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.state.isActive && this.searchInput) {
            this.searchInput.focus();
        }
    }

    render() {
        const {initLanguageCode, className, selectorClassName, allowUndefined = false} = this.props
        const {filter} = this.state

        const languages = allowUndefined ?
            [
                {
                    'code': undefined,
                    'name': 'Undefined',
                    'nativeName': 'No language'
                },
                ...InfoManager.sortedLanguageList
            ]
            : InfoManager.sortedLanguageList

        return (
            <React.Fragment>
                {/*Language icon block*/}
                <div className={className || 'LSIconBlock'} onClick={() => this.onShowHide()} >
                    {
                        initLanguageCode
                            ? <img src={`/flags/${initLanguageCode}.png`} alt=""/>
                            : <i className="fad fa-language" />
                    }

                </div>

                {/*Language selector block*/}
                <div className={(this.state.isActive ? (selectorClassName || 'LSSelectBlock') : 'hided')}
                     onClick={() => this.onShowHide()}>
                    <div className='LSHeader'>
                        <input className="LSSearchInput"
                               ref={input => this.searchInput = input}
                               onClick={e => e.stopPropagation()}
                               onKeyUp={(e) => this.onChangeFilter(e)}
                               type="text"
                               placeholder="Language search"/>
                        <i className='fas fa-times closeButton' onClick={() => this.onShowHide()} />
                    </div>
                    <div className='LSItemList'>
                        {
                            languages.filter(language => {
                                return !(
                                    filter
                                    && !language.name.toLowerCase().includes((filter || "").toLowerCase())
                                    && !language.nativeName.toLowerCase().includes((filter || "").toLowerCase())
                                )
                            }).map(language => {
                                 return (
                                     <div key={`LS_${language.code}`}
                                          className='LSItem'
                                          onClick={e => this.onChangeLanguage(language.code)}>
                                         <div className='LSItemFlag'>
                                             <img src={`/flags/${language.code}.png`}
                                                  className='LSItemFlagImg'
                                                  alt=''/>
                                         </div>
                                         {language.name} {<small>({language.nativeName})</small>}
                                     </div>
                                 )
                            })
                        }
                    </div>
                </div>

            </React.Fragment>
        );
    }

}
