import React, {Component} from 'react'
import ModalWindow from "../ModalWindow/ModalWindow";
import {SimpleHint} from "../Additional";
import InfoManager from "../../managers/InfoManager";
import ServerManager from "../../managers/ServerManager";


interface PropsType {
    isActive: boolean,
    list: Array<any>,
    onClose: Function
}


export default class RestoreBackupManager extends Component <PropsType> {

    state = {
        backupFile: null,
        dbName: 'test',
        _processing: false
    };

    onClose(e?: any) {
        if (e) e.stopPropagation();
        this.setState({
            _processing: false,
            dbName: 'productiveTriglav',
        });
        this.props.onClose();
    }

    onRestore() {
        // adminRestoreBackup
        const {backupFile, dbName} = this.state;
        console.log({backupFile, dbName});
        this.setState({_processing: true});

        if (!this.state.backupFile) {
            InfoManager.showMsg('Please select backup from list!');
            this.setState({_processing: false});
            return;
        }

        console.log('You should disable security in mongo before. Check /etc/mongod.conf');

        InfoManager.confirmDialog('Are you sure ?')
            .then(() => {
                ServerManager.instance.restoreBackup(backupFile, dbName)
                    .then(answer => {
                        console.log(answer);

                        if (answer.staus !== false) {
                            this.onClose();
                            InfoManager.showMsg('Done');
                        } else {
                            InfoManager.showMsg(answer.msg || 'Server error');
                        }
                    })
            })
            .catch(e => {
                this.setState({_processing: false});
            });
    }

    onChange(e: any, name: string) {
        this.setState({
            [name]: e.target.value
        });
    }

    render() {
        const {isActive, list} = this.props;
        if (!isActive) return null;

        return (
            <ModalWindow isActive={isActive} onClose={(e: any) => this.onClose(e)}>
                <div className='SpeekEditorContainer centered' onClick={e => e.stopPropagation()}>
                    <div className='SEHeader'>
                        <h3> Backup restore manager
                        </h3>

                    </div>

                    <div className="SELeft" style={{width: '100%'}}>
                        <u>Backup file:</u>
                        <select className='SEDescription' onChange={(e) => this.onChange(e, 'backupFile')}>
                            {
                                list.map(it => {
                                    const time = (new Date(it.name.split('_')[0] * 1000)).toLocaleString();
                                    const db = it.name.split('_')[2].split('.')[0];

                                    return (
                                        <option key={'BackupOption' + it.name} value={it.name}>
                                            {db} -> {time}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <br/>

                        <u>Database name:</u>
                        <SimpleHint>Enter here the name of db to restore backup</SimpleHint>
                        <br/>
                        <input type='text'
                               className='SEDescription'
                               onChange={(e) => this.onChange(e, 'dbName')}
                               value={this.state.dbName} />


                    </div>

                    <div className='SEFooter'>
                        <div className='SESave likeBtn' onClick={() => this.state._processing ? null : this.onRestore()}>
                            <i className='fas fa-history' /> {!this.state._processing ? "Restore selected" : "PROCESSING ..."}
                        </div>
                    </div>

                </div>
            </ModalWindow>
        );
    }

}

