import React from "react";
import {connect} from "react-redux";
import storeDispatcher from "../../../storeDispatcher";


class SightViewer extends React.Component {

    onClose(e) {
        e.stopPropagation();
        storeDispatcher.sightView(null);
    }

    render() {
        const {activator} = this.props;
        if (!activator) return <React.Fragment/>;

        return (
            <div className='SightViewerContainer'
                 onClick={(e) => this.onClose(e)}>

                <img className='SightViewerImg'
                     alt=''
                     src={activator.srcData} />

                <a target="_blank"
                   rel='noopener noreferrer'
                   href={activator.fullSizeImg}
                   download={`${activator.activatorId}.jpg`} >

                    <img className='SightViewerImg'
                         alt=''
                         src={activator.fullSizeImg} />
                </a>
                <i className='closeButton likeBtn fas fa-times'
                   onClick={(e) => this.onClose(e)}
                   title='Close' />

                <div className='sightViewStatisticBlock'
                     title={`Likes - ${activator.statistic.good}, dislikes - ${activator.statistic.bad}`}
                >
                    <i className='far fa-eye'/>{activator.statistic.views}
                    <i className='far fa-star' />{activator.rank || 50}%
                </div>
            </div>
        )
    }


}


const mapStateToProps = (state) => {
    const activatorId = state.viewerActivatorId;
    if (!activatorId) return {activator: null};

    const activator = Object.values(state.objects).reduce( (acc, object) => {
        return object.getActivator(activatorId) || acc;
    }, null);

    return {activator}
};
export default connect(mapStateToProps)(SightViewer);