import React from "react";
import ProjectData from "../../models/ProjectData";
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import UserData from "../../models/UserData";


export default class ProfileAdministrative extends React.Component {

    ref = {
        message: React.createRef(),
    };

    rights = {};

    sendMsg() {
        const {user: {userId}} = this.props;
        const message = this.ref.message.current.value;

        if (!message || message.length < 2) {
            InfoManager.showMsg('Message is to short');
            return;
        }

        ServerManager.instance.sendMsg(userId, message)
            .then(done => {
                if (done) {
                    InfoManager.showMsg('Message was sent');
                    this.ref.message.current.value = '';
                } else {
                    InfoManager.showMsg('Error');
                }
            });
    }

    acceptRights() {
        const {user: {userId}} = this.props;

        const rights = Object.keys(this.rights)
            .filter(key => this.rights[key].current.checked)
            .map(key => key)
            .join(';');

        console.log(userId, rights);
        ServerManager.instance.acceptRights(userId, rights).then();
    }

    render() {
        const {user, isActive} = this.props;
        if (!isActive) return <React.Fragment />;

        return (
            <div className="DoubleRightSide">
                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon fas fa-user-shield"/> <b>Administrative page </b>
                    <br/>
                    <div className='DoubleInputBlock'>
                        <b>Global rights</b>
                        <br/>
                        <br/>
                        {
                            UserData.rightList.map(rule => {
                                this.rights[rule] = React.createRef();
                                return (
                                    <React.Fragment key={'GlobalRights' + rule}>
                                        <input type='checkbox'
                                               ref={this.rights[rule]}
                                               className='ProjectAccessCheckbox'
                                               defaultChecked={user.hasRight(rule)}/>
                                        {' '}{rule}
                                        <br/>
                                    </React.Fragment>
                                )
                            })
                        }

                        <div className="likeBtn DoubleButton" onClick={() => this.acceptRights()}>
                            Save changes
                        </div>
                    </div>

                    <div className='DoubleInputBlock hided'>
                        <b>Group extra access</b>
                        <br/>
                        <br/>
                        {
                            ProjectData.visitorPermissionList.map(rule => {
                                return (
                                    <React.Fragment key={'UserExtraAccess' + rule}>
                                        <input type='checkbox'
                                               className='ProjectAccessCheckbox'
                                               defaultChecked={true} />
                                        {' '}{rule}
                                        <br/>
                                    </React.Fragment>
                                )
                            })
                        }

                        <div className="likeBtn DoubleButton" onClick={() => this.onSave()}>
                            Save changes
                        </div>
                    </div>
                </div>

                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon far fa-envelope"/> <b>Send message </b>
                    <br/>

                    <div className="DoubleInputBlock nonFloat">
                        Enter text
                        <br/>
                        <input id="ProfileFirstNameInput" className="DoubleInput" type="text"
                               ref={this.ref.message}
                               placeholder="Message text" />
                    </div>

                    <div className="likeBtn DoubleButton" onClick={() => this.sendMsg()}>
                       Send
                    </div>
                </div>
            </div>
        )
    }
}
