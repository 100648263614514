import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ObjectList from "../../pages/object-list";
import ProjectList from "../../pages/project-list";
import ProjectPage from "../../pages/project";
import Profile from "../../pages/profile";
import NotFound from "../../pages/notFound";
import UserListPage from "../../pages/user-list";
import PendingPage from "../../pages/pending";
import SuperAdminPage from "../../pages/superAdmin";
import RepositoryPage from "../../pages/repository";
import Header from "../Header";
import LoginPage from "../Authorisation";
import Invitation from "../../pages/invitation";
import VerificationPage from "../../pages/verification";
import ConnectionError from "../Additional/ConnectionError";


export default class App extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Router>
                    <ConnectionError/>
                    <Header />
                    <LoginPage />
                    <Switch>
                        <Route
                            path='/'
                            exact
                            component={ObjectList}/>
                        <Route
                            path='/objects/:objectId'
                            exact
                            component={ObjectList}/>
                        <Route
                            path='/objects/'
                            exact
                            component={ObjectList}/>
                        <Route
                            path='/projects'
                            exact
                            component={ProjectList}/>
                        <Route
                            path='/project/:projectId'
                            exact
                            component={ProjectPage}/>
                        <Route
                            path='/profile'
                            exact
                            component={Profile}/>
                        <Route
                            path='/profile/:userId'
                            exact
                            component={Profile}/>
                        <Route
                            path='/users'
                            exact
                            component={UserListPage}/>
                        <Route
                            path='/pending'
                            exact
                            component={PendingPage}/>
                        <Route
                            path='/admin'
                            exact
                            component={SuperAdminPage}/>
                        <Route
                            path='/repository'
                            exact
                            component={RepositoryPage}/>
                        <Route
                            path='/invitation/:code'
                            exact
                            component={Invitation}/>
                        <Route
                            path='/verification/:code'
                            exact
                            component={VerificationPage}/>
                        <Route
                            path='/dump'
                            exact
                            component={NotFound}/>

                         <Route
                            component={NotFound}/>
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}
