import LinkItem from "./LinkItem";
import React from "react";


export default class LinkList extends React.Component {

    render() {
        const {object,  keySuffix=""} = this.props;

        return(
            object.mediaEntries
                .filter(item => item.isLink)
                .map(item => {
                    return (
                        <React.Fragment key={'Fragment_' + keySuffix + item.mediaId}>
                            <LinkItem media={item}>
                                {this.props.children}
                            </LinkItem>
                        </React.Fragment>
                    )
                })
        )
    }
}
