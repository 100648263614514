import React from "react";
import {connect} from "react-redux";
import ServerManager from "../managers/ServerManager";


class VerificationPage extends React.Component {

    componentDidMount() {
        const {me, match: {params: {code}}} = this.props;
        if (me.userId) return;

        ServerManager.instance.confirmEmail(code)
            .then();

    }

    render() {
        return null;
    }
}


const mapStateToProps = ({me}) => ({me});
export default connect(mapStateToProps)(VerificationPage);