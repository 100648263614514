import React, {Fragment} from "react";
import './MediaList.css';
import AvatarEditor from "../../AvatarEditor/AvatarEditor";
import ServerManager from "../../../managers/ServerManager";


export default class AvatarItem extends React.Component {
    state = {
        isAvatarEditorActive: false,
    };

    onAvatarEditorShow(value = true) {
        if (!this.props.media.isMainAvatar) return;

        this.setState({
            isAvatarEditorActive: value
        });
    }

    uploadAvatar(data) {
        const {media, media: {objectId}} = this.props;
        if (!media.isMainAvatar) return;

        ServerManager.instance.uploadObjectAvatar(objectId, data.split('base64,')[1])
            .then(
                answer => console.log(answer)
            );
    }

    render() {
        const {media} = this.props;
        if (!media || !media.isAvatar) return null;

        const childWithProps = this.props.children ?
            React.cloneElement(this.props.children, this.props)
            : <Fragment/>

        const language = media.language
        const flagImg = language ? <img className='SpeekFlag' src={`/flags/${language}.png`} alt='' /> : null

        return(
            <Fragment>
                <div className={'ObjectLISpeekContainer AvatarItemContainer' + (!media.isEnabled ? ' disabledBG' : '')}>
                    <div className='AIImgBlock likeBtn' onClick={() => this.onAvatarEditorShow(true)}>
                        <img className='AvatarItemImg' alt='' src={media.imageData} />
                    </div>
                    {flagImg}

                    {
                        media.isMainAvatar
                            ? <b style={{color: "goldenrod"}}> {media.getDescription || 'Main avatar'}</b>
                            : media.getDescription || 'Additional avatar'
                    }

                    {childWithProps}
                </div>

                <AvatarEditor
                    onSave={data => this.uploadAvatar(data)}
                    onClose={() => this.onAvatarEditorShow(false)}
                    isActive={this.state.isAvatarEditorActive}
                    src={media.imageData}
                />
            </Fragment>
        )
    }
}
