import React from "react";
import '../Profile/Profile.css';
import './Project.css';
import ProjectInfoSubPage from "./ProjectInfoSubPage";
import ProjectFlagsSubPage from "./ProjectFlagsSubPage.tsx";
import ProjectMembersSubPage from "./ProjectMembersSubPage";
import ProjectAdministrativeSubPage from "./ProjectAdministrativeSubPage";
import StoreManager from "../../managers/StoreManager";
import defaultAvatar from '../../resources/projectAvatar.jpg';
import InfoManager from "../../managers/InfoManager";
import FilterManager from "../../managers/FilterManager";
import {Link} from "react-router-dom";
import AvatarEditor from "../AvatarEditor";
import ServerManager from "../../managers/ServerManager";
import AdditionalTagEditor from "../AdditionalTagEditor/AdditionalTagEditor";
import {Rights} from "../../models/TaggableData";


export default class Project extends React.Component {

    state = {
        activePage: 'info',
        isAvatarEditorActive: false,
        isTagsEditorActive: false
    };

    onAvatarEditorShow(value = true) {
        this.setState({
            isAvatarEditorActive: value
        });
    }

    onTagsEditorShow(value = true) {
        this.setState({
            isTagsEditorActive: value
        });
    }

    changePage(name) {
        this.setState({activePage: name});
    }

    showQRCode() {
        const {project: {projectId}} = this.props;
        InfoManager.showProjectQRCode(projectId);
    }

    filterByProject() {
        const {project: {projectId}} = this.props;
        FilterManager.set('filterObjectGroup', projectId);
    }

    uploadAvatar(data) {
        const {project: {projectId}} = this.props;
        ServerManager.instance.uploadProjectAvatar(projectId, data.split('base64,')[1])
            .then();
    }

    render() {

        const {project, me} = this.props;

        if (!me.userId) return null;

        if (!project) {
            StoreManager.updateProjectList();
            return <React.Fragment/>;
        }

        const isAdmin = me.hasRight(Rights.ADMIN) || project.isUserAdmin(me.userId);

        return (
            <div className='MainContainer'>

                <div className="DoubleLeftSide">
                    <div className="DoubleLeftHead">
                        <span className="DoubleAvatar" onClick={() => this.onAvatarEditorShow(true)}>
                            <img src={project.avatarData || defaultAvatar} alt='' />
                        </span>

                        <div className="DoubleHeadInfo">
                            <b>{project.description}</b> <br/>
                            <small>
                                {project.isPublic ? 'Public' : 'Private'} project ~ {project.memberCount}
                                &nbsp;<i className='fas fa-user'/>
                            </small>
                            <br/>
                            <small>
                                Registered {project.registeredDate}
                            </small>
                        </div>

                        <i id='ProfileAuthQRCode'
                           className={"likeBtn fas fa-qrcode" + (isAdmin?'':' hided')}
                           onClick={() => this.showQRCode()}
                           title="QR Code for inviting new members"/>

                        <Link to='/objects' id="ProfileAddFilter" onClick={() => this.filterByProject()}>
                            <i title="Show project objects" className="likeBtn fas fa-filter"/>
                        </Link>

                        <i id='ProfileTagsEditorIcon'
                           className={"likeBtn fas fa-tags" + (me.isSuperAdmin ? '' : ' hided')}
                           onClick={() => this.onTagsEditorShow(true)}
                           title="Tags"/>


                    </div>

                    <div onClick={() => this.changePage('info')}
                         className=" likeBtn DoubleLeftBlock">
                        <i className="fas fa-pencil-alt ProfileLeftIcon"/>
                        Group info
                    </div>

                    <div onClick={() => this.changePage('flags')}
                         className={isAdmin ? "likeBtn DoubleLeftBlock" : "hided"}>
                        <i className="fas fa-flag ProfileLeftIcon"/>
                        Content Flags
                    </div>

                    <div onClick={() => this.changePage('members')}
                         className="likeBtn DoubleLeftBlock">
                        <i className="fas fa-users ProfileLeftIcon"/>
                        Members
                    </div>

                    <div onClick={() => this.changePage('administrative')}
                         className={"likeBtn DoubleLeftBlock" + (isAdmin ? '' : ' hided')}>
                        <i className="fas fa-user-shield ProfileLeftIcon"/>
                        Administrative page
                    </div>
                </div>

                <AdditionalTagEditor projectId={project.projectId}
                                     isActive={this.state.isTagsEditorActive}
                                     onClose={() => this.onTagsEditorShow(false)}/>

                <AvatarEditor src={project.avatarData}
                              isActive={isAdmin && this.state.isAvatarEditorActive}
                              onSave={data => this.uploadAvatar(data)}
                              onClose={() => this.onAvatarEditorShow(false)} />

                <ProjectInfoSubPage isAdmin={isAdmin} project={project} isActive={this.state.activePage === 'info'} />
                <ProjectFlagsSubPage isAdmin={isAdmin} project={project} isActive={this.state.activePage === 'flags'} />
                <ProjectMembersSubPage isAdmin={isAdmin} project={project} isActive={this.state.activePage === 'members'} />
                <ProjectAdministrativeSubPage isAdmin={isAdmin} project={project} isActive={this.state.activePage === 'administrative'}  />

            </div>
        );
    }

}
