import React from 'react';
import OptionsBar from "../components/OptionsBar";
import {connect} from "react-redux";
import Profile from "../components/Profile";
import StoreManager from "../managers/StoreManager";


class ProfilePage extends React.Component {

    render() {
        const {me, users} = this.props;
        const userId = this.props.match.params.userId || me.userId;
        const user = users[userId];

        if (!user) StoreManager.updateUser(userId);

        return (
            <React.Fragment>
                <OptionsBar>
                    Profile
                </OptionsBar>

                <Profile user={user} me={me} itsMe={user && me.userId === user.userId} />
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    const {me, users} = state;
    return {me, users};
};

export default connect(mapStateToProps)(ProfilePage);