import ContentFlagList from "./ContentFlagList";
import TaggableData, {Rights} from "./TaggableData";
import UserData from "./UserData";

type MembershipRequestType = {
    userId: string,
    projectId: string,
    reason: string
}


export default class ProjectData extends TaggableData
{
    constructor(groupData: Record<string, any>) {
        super(groupData);
        this.contentFlagList = new ContentFlagList(groupData.contentFlagList || []);
        this.contentFlagList.parent = this;
    }

    static get accessList() {
        return [Rights.CREATE, Rights.EXTEND, Rights.EDIT, Rights.TRUSTED, Rights.COOPERATIVE];
    }

    get description(): string {
        return this._data.description || 'No description';
    }

    get comment(): string {
        return this._data.comment || '';
    }

    get projectId(): string {
        return this._data.groupId || '';
    }

    get groupId(): string {
        return this._data.groupId || '';
    }

    get avatarData (): string {
        return this._data.avatar ? 'data:image/png;base64,' + this._data.avatar : '';
    }

    get isPublic (): boolean {
        return this._data.publicAccess;
    }

    get registeredDate (): string {
        return TaggableData.isoDate(this._data.registrationTS) + ', ' + TaggableData.isoTime(this._data.registrationTS);
    }

    get memberCount (): number {
        return this._data.members.length || 1;
    }

    get members (): string[] {
        return this._data.members || [];
    }

    get admins (): string[] {
        return this._data.admins || [];
    }

    get requestCount (): number {
        return (this._data.requests && this._data.requests.length > 0) ? this._data.requests.length : 0
    }

    get requests (): Array<MembershipRequestType> {
        return (this._data.requests || [])
            .map((it: Record<string, string>) => ({
                userId: it.userId,
                projectId: it.groupId,
                reason: it.reason
            }));
    }

    get memberAccess (): string {
        return this._data.memberAccess || '';
    }

    get access (): string {
        return this._data.access || '';
    }

    static get memberPermissionList() {
        return [Rights.CREATE, Rights.EXTEND, Rights.EDIT, Rights.TRUSTED, Rights.COOPERATIVE];

    }

    static get visitorPermissionList(): Array<string> {
        return [Rights.READ, Rights.CREATE, Rights.EXTEND, Rights.EDIT, Rights.TRUSTED];
    }

    contentFlagList: ContentFlagList;

    isUserAdmin(userId: string): boolean {
        return this.admins.includes(userId);
    }

    isUserMember(userId: string): boolean {
        return this.members.includes(userId);
    }

    canUserDo(user: UserData, action: string): boolean {
        if (!user || !action || !user.hasRight) {
            return false
        }

        if (user.hasRight(action) || user.hasRight(Rights.ADMIN) || user.isSuperAdmin) {
            // check global rights
            return true
        }

        if (this.isUserAdmin(user.userId)) {
            return true
        }

        if (this.isUserMember(user.userId)) {
            const hasExtraAccess = user.access[this.groupId]
                ? user.access[this.groupId][action] || null
                : null

            // check member permission
            if (this.memberAccess.includes(action) && hasExtraAccess === null) {
                return true
            }

            // check user extra access
            if (hasExtraAccess) {
                return true
            }
        }
        else {
            // check common user permissions
            if (this.access.includes(action)) {
                return true
            }
        }

        return false
    }

    canUserAddObject(user: UserData): boolean {
        return this.canUserDo(user, Rights.CREATE)
    }

    canUserEditObject(user: UserData): boolean {
        return this.canUserDo(user, Rights.EDIT)
    }

    canUserExtendObject(user: UserData): boolean {
        return this.canUserDo(user, Rights.EXTEND)
    }


    get filterPresets(): Array<Record<string, any>> {
        return this._data.filterPresets || [];
    }

    get projectObjectId(): string {
        return this._data.projectObjectId || null;
    }

    get invitations(): string {
        return this._data.invitations || [];
    }

}
