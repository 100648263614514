import React from "react";
import './LoginPage.css';
import Utils from "../../managers/Utils";
import {connect} from "react-redux";


class LoginPage extends React.Component {

    onLogin() {
        const email = this.SIEmailRef.current.value;
        const password = this.SIPasswordRef.current.value;

        Utils.startAuth(null, email, password).then();
    }

    onForgot() {
        const email = this.SIEmailRef.current.value;
        Utils.forgotPassword(email);
    }

    onSignUp() {
        const email = this.SUEmailRef.current.value;
        const password = this.SUPasswordRef.current.value;
        const password2 = this.SUPassword2Ref.current.value;
        const nickname = this.SUNicknameRef.current.value;

        Utils.signUp(email, password, password2, nickname).then();
    }

    SIEmailRef = React.createRef();
    SIPasswordRef = React.createRef();
    SUEmailRef = React.createRef();
    SUPasswordRef = React.createRef();
    SUPassword2Ref = React.createRef();
    SUNicknameRef = React.createRef();

    render() {
        if (this.props.isLoggedIn || this.props.isLoggedIn === undefined) {
            return null
        }

        return (
            <div className='LPContainer'>

                <div className='LPSignInBlock'>
                    <div className="LoginBlockHeader">
                        LOGIN
                    </div>
                    <br/>
                    <form>
                        <div className="LPInputBlock">
                            <span className="LPInputHeader">
                                <i className="fas fa-at"/>
                            </span>
                                <span className="LPInputBody">
                                <input className="LPInput" type="email" autoComplete="email" ref={this.SIEmailRef}
                                       placeholder="Email"/>
                            </span>
                        </div>

                        <div className="LPInputBlock">
                            <span className="LPInputHeader">
                                <i className="fas fa-key"/>
                            </span>
                            <span className="LPInputBody">
                                <input className="LPInput" type="password" autoComplete="current-password"
                                       id="LoginPassword" ref={this.SIPasswordRef}
                                       placeholder="Password"/>
                            </span>
                        </div>
                    </form>

                    <div id="LoginButton" className="likeBtn LPButton" onClick={() => this.onLogin()}>
                        LOGIN
                    </div>
                    <div className="likeBtn" id="generatePassword" onClick={() => this.onForgot()}>Forgot password</div>
                </div>



                <div className='LPSignUpBlock'>
                    <div className="LoginBlockHeader">
                        SIGN UP
                    </div>
                    <br/>
                    <form>
                        <div className="LPInputBlock">
                            <span className="LPInputHeader">
                                <i className="fas fa-at"/>
                            </span>
                            <span className="LPInputBody">
                                <input className="LPInput" type="email" autoComplete="email" id="SignUpEmail"
                                       placeholder="Email" ref={this.SUEmailRef} />
                            </span>
                        </div>

                        <div className="LPInputBlock">
                            <span className="LPInputHeader">
                                <i className="fas fa-key"/>
                            </span>
                            <span className="LPInputBody">
                                <input className="LPInput" type="password" autoComplete="new-password" id="SignUpPassword"
                                       placeholder="Password" aria-autocomplete="list" ref={this.SUPasswordRef} />
                            </span>
                        </div>

                        <div className="LPInputBlock">
                            <span className="LPInputHeader">
                                <i className="fas fa-key"/>
                            </span>
                            <span className="LPInputBody">
                                <input className="LPInput" type="password" autoComplete="new-password" id="SignUpRetypePassword"
                                       placeholder="Retype password" ref={this.SUPassword2Ref} />
                            </span>
                         </div>

                         <div className="LPInputBlock">
                            <span className="LPInputHeader">
                                <i className="fas fa-user"/>
                            </span>
                            <span className="LPInputBody">
                                <input className="LPInput" type="text" autoComplete="nickname"
                                       placeholder="Nickname" ref={this.SUNicknameRef} />
                            </span>
                        </div>
                    </form>
                    <div className="likeBtn LPButton" onClick={() => this.onSignUp()}>
                        SIGN UP
                    </div>
                </div>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    isLoggedIn: state.isLoggedIn
});
export default connect(mapStateToProps)(LoginPage);
