import React from "react";
import './ObjectLIInfoBlock.css';


export default class ObjectLIInfoBlock extends React.Component{

    render() {
        const {object} = this.props;
        const webViews = object.webViewsCount;
        const views = object.viewsCount;

        return (
            <React.Fragment>

                <div className="ObjectLIInfoBlock">
                    <i className="far fa-calendar-check" title="Creation time"/> {object.isoDateTime}
                    <i className="fas fa-code-branch" title="Revision"/> {object.revision}

                    <i className="far fa-eye" title="Activations count"/> {webViews ? `${views} + ${webViews}` : views}
                    <i className="far fa-star" title="Ranking (0 likes, 0 dislikes)"/>
                    {Math.round(object.rank * 100) / 100}%

                </div>

                <div className="ObjectLIStatusBlock">
                    <i title={'Object class - ' + object.classDescription}
                       className={object.classIconName}/>
                </div>
            </React.Fragment>
        )
    }
}