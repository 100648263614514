import React, {Fragment} from "react";
import './style.css';
import AudioManager from "../../../managers/AudioManager";
import {connect} from "react-redux";
import StoreManager from "../../../managers/StoreManager";


class SpeekItem extends React.Component {

    onPlay(media) {
        AudioManager.play(media).then();
    }

    playButton = () => {
        return <i className='fas fa-play centered' />
    };

    description = () => {
        const {media} = this.props;
        const language = media.isSubTrack ? 'independent' : media.language;

        const flag = !media.isSubTrack ? <img className='SpeekFlag' src={'/flags/' + language + '.png'} alt='' /> : null;
        const description = media.getDescription || 'Unnamed';

        return (
            <React.Fragment>
                {flag}
                {description}
            </React.Fragment>
        )
    };

    _infoLine() {
        const {media, creators, children} = this.props;
        const creator = creators[media.creatorId];

        if (media.viewsCount && children) return (
            <Fragment>
                <b>
                    <i className="far fa-eye" title="Activations count"/>
                    {' ' + media.viewsCount  + '  '}
                </b>
                {media.isoDate + ' ' + media.creatorName(creator).toUpperCase()}

            </Fragment>
        );

        return (
            <Fragment>
                {
                    media.isoDate + ' ' + media.creatorName(creator).toUpperCase()
                }
            </Fragment>
        );
    }

    render() {
        const {media, creators} = this.props;
        if (!media) return null;
        const creator = creators[media.creatorId];
        if (!creator) StoreManager.updateCreator(media.creatorId);

        const childWithProps = this.props.children ?
            React.cloneElement(this.props.children, this.props)
            : <React.Fragment/>;

        return (
            <div className={`
                    ${media.isSubTrack ? 'ObjectLISubSpeek ObjectLISpeekContainer' : 'ObjectLISpeekContainer'} 
                    ${media.isEnabled ? '' : 'disabledBG'} 
                `}>
                <div className='likeBtn ObjectLISpeekPlayBtn' onClick={() => this.onPlay(media)} >
                    {this.playButton()}
                </div>

                <div className='ObjectLISpeekAuthor'>
                    {this.description()}
                </div>

                <div className='ObjectLISpeekDate'>
                    {this._infoLine()}
                </div>
                {
                    childWithProps
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    creators: state.creators,
});
export default connect(mapStateToProps)(SpeekItem);