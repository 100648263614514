import React, {Fragment} from "react";
import './MediaList.css';


export default class LinkItem extends React.Component {

    render() {
        const {media} = this.props;
        if (!media || !media.isLink) return null;

        const childWithProps = this.props.children ?
            React.cloneElement(this.props.children, this.props)
            : <Fragment/>;

        const language = media.language
        const flagImg = language ? <img className='SpeekFlag' src={`/flags/${language}.png`} alt='' /> : null

        return(
            <div className={'ObjectLISpeekContainer LinkItemContainer' + (!media.isEnabled ? ' disabledBG' : '')}>
                <div className='LIIconBlock'>
                    <i className="fas fa-link" />
                </div>

                <div className='LITextBlock'>
                    {flagImg}
                    {media.tags.LinkUrl || 'Links is empty'}
                </div>

                {childWithProps}
            </div>
        )
    }
}
