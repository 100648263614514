import HierarchicalObject from "./HierarchicalObject";


export default class ContentFlagList extends HierarchicalObject {

    constructor(contentFlagList: Array<any>) {
        super();
        this.list = contentFlagList || [];
    }

    list: Array<Record<string, any>>;

    static get states() {
        return {'prefer': 'Prefer', 'irrelevant': 'Irrelevant', 'avoid': 'Avoid', 'exclude': 'Exclude', 'obligatory': 'Obligatory'};
    }

    static get statesEmotion() {
        return {'prefer': 'fas fa-thumbs-up', 'irrelevant': 'fas fa-meh', 'avoid': 'fas fa-thumbs-down'
            , 'exclude': 'fas fa-times-circle', 'obligatory': 'fas fa-exclamation-circle', undefined: 'fas fa-question-circle'};
    }

    static nextState(state: string): string {
        let index = ContentFlagList.stateList.indexOf(state);

        index++;
        if (index >= ContentFlagList.stateList.length) index = 0;

        console.log(state, ContentFlagList.stateList[index]);
        return ContentFlagList.stateList[index];
    }

    static get stateList() {
        return Object.keys(this.states);
    }

    static flagDescription(flag: Record<string, any>, lang: string = 'en') {
        return flag[`description_${lang}`]
            || flag[`description_en`]
            || Object.keys(flag).reduce((acc, cur) => {
                return (!acc && cur.indexOf('description_')) ? flag[cur] : acc
            }, null)
            || 'No description';
            // || {id: flag.id, description: 'no description'};
    }

    static flagDefaultFilter(flag: Record<string, any>) {
        return flag.defaultFilter;
    }

    static get standardFlagList(): Array<Record<string, string>> {
        return [
            {id: "forChildren", description_en: "For children"},
            {id: "forTeens", description_en: "For teens"},
            {id: "forAdults", description_en: "For adults"},
            {id: "forSeniors", description_en: "For seniors"},
            {id: "amateurish", description_en: "Amateurish"},
            {id: "fun", description_en: "Fun"},
            {id: "religious", description_en: "Religious"},
            {id: "scientifical",  description_en: "Scientifical"},
            {id: "inappropriate", description_en: "Inappropriate"},
            {id: "professional", description_en: "Professional"},
            {id: "leftist", description_en: "Leftist"},
            {id: "rightWing", description_en: "Right wing"}
        ];
    }

    static get standardPresetList(): Array<Record<string, any>> {
        return [
            {
                id: "forChildren",
                description_en: "For children",
                flags: {forChildren: 'prefer', forAdults: 'exclude'}
            },
            {
                id: "forAdults",
                description_en: "For adults",
                flags: {forAdults: 'prefer', forChildren: 'avoid'}

            },
            {
                id: "fun",
                description_en: "Fun",
                flags: {'fun': 'prefer'}

            },
            {
                id: "professional",
                description_en: "Professional",
                flags: {'professional': 'prefer', fun: 'avoid'}
            },
        ];
    }

    get flagIdList() {
        return this.list.map(item => item.id);
    }

    flagsMap(lang = 'en') {
        return this.list.map(item => {
            return {
                id: item.id,
                description: ContentFlagList.flagDescription(item, lang)
            }
        });
    }

    getFlag(id: string) {
        return this.list.reduce((acc: any, cur: any) => (cur.id === id) ? cur : acc, null);
    }

}