import React from 'react';
import ServerManager from "../../managers/ServerManager";
import StoreManager from "../../managers/StoreManager";
import ModalWindow from "../ModalWindow/ModalWindow";
import InfoManager from "../../managers/InfoManager";


export default class ProjectCreateForm extends React.Component {

    ref = {
        description: React.createRef(),
        comment: React.createRef(),
        isPublic: React.createRef()
    };

    onCreate() {
        const description = this.ref.description.current.value;
        const comment = this.ref.comment.current.value;
        const isPublic = this.ref.isPublic.current.checked;

        if (!description || !comment) {
            InfoManager.showMsg('Please enter description and comment');
            return;
        }

        ServerManager.instance.createNewProject(description, comment, isPublic)
            .then(() => {
                this.ref.description.current.value = '';
                this.ref.comment.current.value = '';
                this.props.onClose();
                StoreManager.updateProjectList();
            });
    }

    render() {

        return (
            <ModalWindow {...this.props}>
                <div className="centered ProjectCreateForm" onClick={e => e.stopPropagation()}>
                    <b>
                        Creation of new group
                    </b>

                    <div className="DoubleFullWidth DoubleInputBlock">
                        Group description
                        <br/>
                        <input className="DoubleInput"
                               ref={this.ref.description}
                               placeholder="Your project name" type="text"/>
                    </div>

                    <div className="DoubleFullWidth DoubleInputBlock">
                        Comment
                        <span className="DoubleIBRightText">
                            Public: <input ref={this.ref.isPublic}
                                           type="checkbox"/>
                        </span>
                        <br/>
                        <textarea className="DoubleInput" ref={this.ref.comment}/>
                    </div>

                    <br/>
                    <div className="likeBtn DoubleButton" onClick={() => this.onCreate()}>
                        <i className="far fa-plus-square"/> Create
                    </div>
                </div>
            </ModalWindow>
        )
    }
}
