import TaggableData from "./TaggableData";


export enum ActivatorType {
    SIGHT = "sight",
    CODE = "code",
    LOCATION = "location",
    DIRECTION = "direction",
    DRIVE_THRU = "driveThru"
}


const activatorTypeIcon = {
    "sight": "fal fa-camera-alt",
    "code": "fad fa-barcode-read",
    "location": "fad fa-map-marked-alt",
    "direction": "fad fa-bullseye-pointer",
    "driveThru": "fad fa-car-bus"
};


export default class ActivatorData extends TaggableData {

    static get Types() {
        return ActivatorType
    }

    get objectId(): string {
        return this._data.objectId || this.parent.objectId
    }

    get activatorId(): string {
        return this._data.activatorId
    }

    get srcData(): string | null {
        return this._data.recogImage ? `data:image/jpeg;base64,${this._data.recogImage}` : null;
    }

    get locationData(): any {
        return this._data.location;
    }

    get isSight(): boolean {
        return !!(this._data.acquisitionData);
    }

    get fullSizeImg(): string {
        return `https://admin.explainitapp.com/activator.php?activatorId=${this.activatorId}&quality=1000`
    }

    get location(): Array<number> | null {
        if (!this._data.acquisitionData) return null;
        const {latitude, longitude} = this._data.acquisitionData.deviceLocation;

        return [latitude, longitude];
    }

    get recogImage() {
        return this._data.recogImage;
    }

    set recogImage(val) {
        this._data.recogImage = val;
    }

    get type(): ActivatorType {
        if (this.tags.ActivatorType) return this.tags.ActivatorType as ActivatorType;
        return this._data.acquisitionData ? ActivatorType.SIGHT : ActivatorType.LOCATION;
    }

    get typeIconClass(): string {
        return activatorTypeIcon[this.type] || '';
    }

}
