import io from 'socket.io-client';
import ObjectData from "../../models/ObjectData.ts";
import Utils from "../Utils";
import InfoManager from "../InfoManager";
import StoreManager from "../StoreManager";
import {uniqueID} from '../Utils'


export const ServerSocketEvents = {
    CONNECT: "connect",
    DISCONNECT: "disconnect",
    CONNECT_ERROR: "connect_error"
}

export default class ServerManager {

    constructor(address) {
        this._socket =  io(address, {
            secure: true
        });
        this.address = address;
        this.sessionId = null;

        this.connecting = new Promise((resolve) => {
            this._socket.on(ServerSocketEvents.CONNECT, () => {
                console.log('connected');
                // InfoManager.showMsg('Connected');
                this.isConnected = true;

                if (this.sessionId) {
                    this.webSessionEnter(this.sessionId).then();
                }

                if (resolve) resolve(true);
                this._runEventHandlers(ServerSocketEvents.CONNECT);
            });

            this._socket.on(ServerSocketEvents.DISCONNECT, () => {
                // InfoManager.showMsg('Disconnect');
                this._runEventHandlers(ServerSocketEvents.DISCONNECT);
            });

            this._socket.on(ServerSocketEvents.CONNECT_ERROR, () => {
                // InfoManager.showMsg('connect_error');
                // console.log('connect_error');
                this._runEventHandlers(ServerSocketEvents.CONNECT_ERROR);
            });
        });

        this.addUpdateHandlers();
    }

    static serverURL = 'https://admin.explainitapp.com';
    static serverSBURL = 'https://adminsb.explainitapp.com';
    static productivePort = 36145;
    static developPort = 3000;
    static productive = () => new ServerManager(`${ServerManager.serverURL}:${ServerManager.productivePort}/`);
    static develop = () => new ServerManager(`${ServerManager.serverSBURL}:${ServerManager.developPort}/`);
    static instance = Utils.devMode ? this.develop() : this.productive();

    isConnected = false;
    _eventHandlerList = {}; // {<id>: handler()}

    addUpdateHandlers() {
        this._socket.on('notifyAdmin', (header, msg) => {
            console.log('notifyAdmin received', header, msg);

            if (header === 'update') {
                StoreManager.updateObject(msg).then();
                InfoManager.showMsg('Object updated');
            }

            if (header === 'remove') {
                StoreManager.removeObject(msg);
                InfoManager.showMsg('Object removed');
            }

            if (header === 'add') {
                if (window.scrollY <= 256) StoreManager.addObject(msg).then();
                InfoManager.showMsg('Object added');
            }

            if (header === 'updateProject') {
                StoreManager.updateProject(msg);
                InfoManager.showMsg('Group information updated');
            }

            if (header === 'removeProject') {
                StoreManager.removeProject(msg);
                InfoManager.showMsg('Group was removed');
            }

            if (header === 'updateUser') {
                StoreManager.updateUser(msg);
                InfoManager.showMsg('User information updated');
            }

            if (header === 'removeUser') {
                StoreManager.removeUser(msg);
                InfoManager.showMsg('User account was removed');
            }
        });

        this._socket.on('automaticLogin', ({user, groups}) => {
            console.log("Automatic login", user);
            Utils.saveAuthData(user);

            if (document.location.href.includes('/invitation')) {
                document.location.reload();
            } else {
                document.location.href = "/profile";
            }
        })
    }

    dispatchEventHandler(event = ServerSocketEvents.DISCONNECT, handler = null, id = uniqueID()) {
        if (!this._eventHandlerList[event]) this._eventHandlerList[event] = {};

        if (handler) {
            // add
            this._eventHandlerList[event][id] = handler;
        } else {
            // remove
            delete this._eventHandlerList[event][id];
        }

        return id;
    }

    _runEventHandlers(event = 'disconnect') {
        if (!this._eventHandlerList[event]) return;

        for (let id in this._eventHandlerList[event]) {
            if (!this._eventHandlerList[event].hasOwnProperty(id)) continue;

            try {
                this._eventHandlerList[event][id]();
            } catch (e) {}

            delete this._eventHandlerList[event][id];
        }
    }

    /* _emitAsync(request, data) {
        return new Promise((resolve) => {
            this._socket.emit(request, data, resolve);
        });
    }*/

    async _emitAsync(request, data, mute = false) {

        const answer = await new Promise((resolve) => {
            this._socket.emit(request, data, resolve);
        });

        if (!mute && answer && !answer.status && answer.msg && answer.msg.length > 2) {
            InfoManager.showMsg(answer.msg)
        }

        return answer
    }

    async webLogin(email, password) {
        if (!this.isConnected) await this.connecting;

        const answer = await this._emitAsync('webLogin', {email, password, version: 2});
        if (answer.status) {

        }
        return answer;
    }

    async webSignUp(email, password, nickname) {
        if (!this.isConnected) await this.connecting;

        return  await this._emitAsync('webSignUp', {email, password, nickname});
    }

    async webLogout() {
        if (!this.isConnected) await this.connecting;

        const answer = await this._emitAsync('webLogout', {});
        return answer.status;
    }

    async webSessionEnter(sessionId) {
        if (!this.isConnected) await this.connecting;
        this.sessionId = sessionId;

        const answer = await this._emitAsync('webSessionEnter', {sessionId, needGroups: false, version: 2});
        if (answer.status) {
            console.log('Logged in');
        }

        return answer;
    }

    async webPasswordChange(email) {
        if (!this.isConnected) await this.connecting;
        return  await this._emitAsync('webPasswordChange', {email});
    }

    async getObjectList(filterObject = {filterObjectGroup: 'all'}) {
        return await this._emitAsync('webGetObjectList', filterObject);
    }

    async getLightObject(objectId) {

        const answer = await this._emitAsync('adminGetLightObject', {objectId});
        if (answer && answer.tags) {
            return new ObjectData(answer);
        } else {
            return false;
        }
    }

    async getUserInfo(userId) {
        return await this._emitAsync('adminGetUserInfo', userId);
    }

    async getUserList(searchLine = null) {
        return await this._emitAsync('adminUsersList', {searchLine});
    }

    async getProjectList() {
        return  await this._emitAsync('webGroupsList', {});
    }

    async setTags(objectId = null, mediaId = null, activatorId = null, projectId = null, userId = null, tagList = []) {
        tagList = tagList.map(item => {
            const key = Object.keys(item)[0];
            let val = ['null', 'undefined', 'unassigned', undefined].includes(Object.values(item)[0]) ? false : Object.values(item)[0];

            return {[key]: val};
        });

        const answer = await this._emitAsync('webSetTags', {objectId, mediaId, activatorId, projectId, userId, tags: tagList});
        console.log(objectId, answer);
        return !!(answer);
    }

    async removeItem(type, id, objectId) {
        // type: [activator, track, object]
        const answer = await this._emitAsync('adminRemoveItem', {type, id, objectId});

        return !!(answer);
    }

    async setMediaChanges(objectId, mediaId, changes, bitRate = 48) {
        return await this._emitAsync('webSaveSpeekChanges', {objectId, mediaId, changes, bitRate});

    }

    async addNewSpeek(speekData, bitRate = 48) {
        return await this._emitAsync('webAddNewSpeek', {speekData, bitRate});
    }

    async getQRCode(line) {
        const QRCodeData = await this._emitAsync('getQRCode', {line});
        return 'data:image/svg+xml;base64,' + btoa(QRCodeData.toString());
    }

    async enableDisable(enableList = [], disableList = []) {
        const answer = await this._emitAsync('webEnableObjects', {enableList, disableList});
        InfoManager.showMsg(answer.status ? "Done" : answer.status || "Server error");
        return answer.status;
    }

    async exportObjects(uids) {
        return await this._emitAsync('adminExportObjects', {uids});
    }

    async setMemberAccess(userId, projectId, accessRights) {
        return await this._emitAsync('webUserSetAccess',{groupId: projectId, userId, accessRights});
    }

    async addContentFlag(projectId, flag) {
        return await this._emitAsync('webGroupAddContentFlag', {
            groupId: projectId,
            flag
        });
    }

    async removeContentFlag(projectId, flagId) {
        return await this._emitAsync('webGroupRemoveContentFlag', {
            groupId: projectId,
            id: flagId
        });
    }

    async editContentFlag(projectId, flagId, changes) {
        return await this._emitAsync('webGroupEditContentFlag', {
            groupId: projectId,
            id: flagId,
            changes
        });
    }

    async getProjectData(projectId, code = null) {
        return await this._emitAsync('webGetProjectData', {projectId, code});
    }

    async editProject(projectId, changes) {
        // comment, description, publicAccess, memberAccess, access
        return await this._emitAsync('webGroupEdit', {...changes, groupId: projectId});
    }

    async removeProject(projectId, removeObjects) {
        return await this._emitAsync('webGroupRemove', {groupId: projectId, removeObjects});
    }

    async setProjectAdministrator(projectId, userId) {
        return await this._emitAsync('webGroupSetAdmin', {userId, groupId: projectId});
    }

    async excludeProjectMember(projectId, userId) {
        return await this._emitAsync('webGroupExclude', {userId, groupId: projectId});
    }

    async setMemberDefaultRights(projectId, userId) {
        return await this._emitAsync('webUserSetDefaultAccess', {userId, groupId: projectId});
    }

    async acceptMembership(projectId, userId) {
        return await this._emitAsync('webAcceptMembership', {userId, groupId: projectId});
    }

    async declineMembership(projectId, userId) {
        return await this._emitAsync('webDeclineMembership', {userId, groupId: projectId});
    }

    async addPreset(projectId, preset) {
        return await this._emitAsync('webGroupAddFilterPreset', {
            groupId: projectId,
            preset
        });
    }

    async editPreset(projectId, presetId, changes) {
        return await this._emitAsync('webGroupEditFilterPreset', {
            groupId: projectId,
            id: presetId,
            changes
        });
    }

    async removePreset(projectId, presetId) {
        return await this._emitAsync('webGroupRemoveFilterPreset', {
            groupId: projectId,
            id: presetId
        });
    }

    async getBackupList() {
        const answer = await this._emitAsync('adminGetDumpList', {});
        return answer.status ? answer.list : [];
    }

    async restoreBackup(backupFile, dbName) {
        return  await this._emitAsync('adminRestoreBackup', {backupFile, dbName});
    }

    async repositoryList(path) {
        const answer = await this._emitAsync('adminRepoList',{catalog: path});
        return answer || [];
    }

    async repositoryAddCatalog(catalog, name) {
        return await this._emitAsync('adminRepoCreateCatalog', {catalog, name});
    }

    async repositoryStartUpload() {
        return await this._emitAsync('adminRepoStartUpload', {});
    }

    async repositoryUploadChunk(part) {
        return await this._emitAsync('adminRepoUpload', part);
    }

    async repositoryFinishUpload() {
        return await this._emitAsync('adminRepoFinishUpload', {});
    }

    async repositoryDelete(catalog, name) {
        return await this._emitAsync('adminRepoRemove', {catalog, name});
    }

    async repositoryRename(catalog, oldName, name) {
        return await this._emitAsync('adminRepoRename', {catalog, oldName, name});
    }

    async editUserInfo(changes, userId) {
        return await this._emitAsync('webEditProfile', {changes, userId});
    }

    async changeUserPassword(password, newPassword) {
        return await this._emitAsync('webChangePassword', {password, newPassword});
    }

    async authQRCode(userId) {
        const answer =  await this._emitAsync('webGetAuthQRCode', {userId});
        answer.qrcode = answer.qrcode || '';
        return answer.status ? 'data:image/svg+xml;base64,' + btoa(answer.qrcode) : null;
    }

    async removeUser(userId) {
        return await this._emitAsync('adminRemoveUser', {userId});
    }

    async sendEmail(to, topic, text) {
        return await this._emitAsync('adminSendEmail', {to, topic, text});
    }

    async sendMsg(userId, message) {
        return await this._emitAsync('adminDeviceMsg', {uid: userId, msg: message});
    }

    async acceptRights(userId, rights) {
        const answer = await this._emitAsync('adminSetRights', {userId, rights});
        if (answer.status === false) InfoManager.showMsg(answer.msg || 'Server error');
        return answer;
    }

    async uploadProjectAvatar(projectId, data) {
        return await this._emitAsync('webSaveGroupAvatar', {avatar: data, groupId: projectId});
    }

    async uploadUserAvatar(userId, data) {
        return await this._emitAsync('webSaveUserAvatar', {avatar: data, userId});
    }

    async uploadObjectAvatar(objectId, data) {
        return await this._emitAsync('webSetAvatarImage', {data, objectId});
    }

    async createNewProject(description, comment, publicAccess) {
        const answer =  await this._emitAsync('webGroupCreate', {description, comment, publicAccess});
        if (!answer.status) {
            InfoManager.showMsg(answer.msg || 'Error');
        }
    }

    async inviteUserToTheProject(email, groupId, isAdmin, reason) {
        const answer =  await this._emitAsync('webGroupInvite', {email, groupId, isAdmin, reason});
        InfoManager.showMsg(answer.status ? 'Invitation has been sent' : answer.msg || 'Error');
    }

    async getObjectSelectorMap(objectClass) {
        return await this._emitAsync('webGetObjectSelectorList', {objectClass}, true);
    }

    async getExtendableObjects() {
        return await this._emitAsync('webGetExtendableObjects', {}, true)
    }

    async assignObject(selectedObjectId, userId = null, projectId = null, mediaId = null, toObjectId = null) {
        const {status} = await this._emitAsync('webAssignObject',
            {objectId: selectedObjectId, userId, groupId: projectId, mediaId, toObjectId});
        return status;
    }

    async addToFavourite(objectId, favourite = true) {
        const {status} = await this._emitAsync('setFavourites', {
            [objectId]: favourite,
        });
        return status;
    }

    async getAuthorInfo(userId) {
        return await this._emitAsync('webCreatorInfo', userId);
    }

    async checkImportingStructure(structure) {
        return await this._emitAsync('adminCheckBeforeImport', {structure});
    }

    async startImportStructure(structure) {
        return await this._emitAsync('adminImportStructure', {structure});
    }

    async setPublicId(objectId, publicId) {
        return await this._emitAsync('webSetPublicId', {objectId, publicId});
    }

    async getPendingObjectList() {
        console.log('Pending object list requested');
        const answer = await this._emitAsync('adminGetPendingList', {});
        return answer.objectList || [];
    }

    async getPendingActions(objectId) {
        return await this._emitAsync('adminGetPendingListOfObject', {objectId});
    }

    async getSoundtrackData(fileId) {
        const answer =  await this._emitAsync('getSoundtrackData', {fileId});
        return answer.data || null;
    }

    async getTextData(textFileId) {
        const answer =  await this._emitAsync('getTextData', {textFileId});
        return answer.data || null;
    }

    async getSightImage(activatorId) {
        const answer =  await this._emitAsync('getSightImage', {activatorId, reduceData: true});
        return answer.data || null;
    }

    async getCreatorsInfo(userId) {
        return await this._emitAsync('webGetCreatorsInfo', {userId});
    }

    async acceptPending(id) {
        return await this._emitAsync('adminAcceptPending', {id});
    }

    async declinePending(id) {
        return await this._emitAsync('adminDeclinePending', {id});
    }

    async addNew(dataObject) {
        const answer = await this._emitAsync('webAddNew', dataObject);
        if (!answer.status) InfoManager.showMsg(answer.msg || 'Server error');

        return answer;
    }

    async getRequestDataList() {
        const answer = await this._emitAsync('adminGetRequestDataList', {});
        if (!answer.status) InfoManager.showMsg(answer.msg || 'Server error');

        return answer;
    }

    async manageRequestDataList(data) {

        const answer = await this._emitAsync('adminManageRequestDataList', data);
        if (!answer.status) InfoManager.showMsg(answer.msg || 'Server error');

        return answer.status;
    }

    async joinGroup(code, choice) {
        const answer = await this._emitAsync('webGroupJoin', {code, choice});
        if (!answer.status) alert(answer.msg || 'Server error');

        return answer.status;
    }

    async confirmEmail(confirmCode) {
        const answer = await this._emitAsync('userConfirmEmail', {confirmCode});
        if (!answer) alert('Something wrong or you already activated account.');

        return answer;
    }

    async getGroupInvitations() {
        return await this._emitAsync('webGetGroupInvitations');
    }

    async partialFileUpload(command = "push", dataPart) { // "start" || "push" or any || "finish"
        const answer =  await this._emitAsync('webPartialFileUpload', {command, dataPart})

        if (!answer.status) {
            InfoManager.showMsg(answer.msg || 'Server error')
        }
        return answer.status
    }

    async sendFileByParts(data, transferPartialThreshold) {
        const count = Math.ceil(data.length / transferPartialThreshold)
        const length = Math.ceil(data.length / count)
        const pattern = new RegExp(".{1," + length + "}", "ig")
        let dataParts = data.match(pattern)

        for (const i in dataParts) {
            const command = (i == '0')
                ? "start"
                : (i == (dataParts.length - 1).toString())
                    ? "finish"
                    : "push"
            const success = await ServerManager.instance.partialFileUpload(command, dataParts[i])
            if (!success) {
                return false
            }
        }

        return true
    }


}
