import React from 'react';
import './UserList.css';
import UserListItem from "./UserListItem";


export default class UserList extends React.Component {

    componentDidMount() {

    }

    render() {
        const {userList = [], history} = this.props;

        return (
            <div className="UserListBlock">
                {
                    (userList).map((item) => {
                        return (
                            <UserListItem user={item} history={history} key={item.userId} />
                        )
                    })
                }
            </div>
        )
    }
}
