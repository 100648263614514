import React from "react";
import ServerManager from "../../managers/ServerManager";


export default class ProjectInvitationItem extends React.Component {

    onResend() {
        const {project: {projectId}, invitation: {email}} = this.props;
        ServerManager.instance.inviteUserToTheProject(email, projectId, undefined, undefined).then();
    }

    onDelete() {
        const {project: {projectId}, invitation: {email}} = this.props;
        ServerManager.instance.inviteUserToTheProject(email, projectId, undefined, false).then();
    }

    render() {

        const {project, invitation} = this.props;
        if (!project || !invitation) return null;

        return(
            <div className="DoubleSmallBlock">
                <div className="DoubleSmallBlockHead">
                    <b>{invitation.email}</b>
                </div>

                <b>Email:</b> <br/>
                <input type="text" readOnly={true} defaultValue={invitation.email} />
                <br/>

                <b>Reason:</b> <br/>
                <textarea readOnly={true} defaultValue={invitation.reason} />
                <br/>

                {invitation.isAdmin
                    ? <b>Invited as administrator</b>
                    : <b>Regular membership</b>
                }

                <br/>
                <div className='likeBtn ProjectMembershipAccept'
                     onClick={() => this.onResend()}>
                    <i className="fas fa-redo" />&nbsp;
                    Resend letter
                </div>

                <div className='likeBtn ProjectMembershipDecline'
                     onClick={() => this.onDelete()}>
                    <i className="far fa-trash-alt" />&nbsp;
                    Delete invitation
                </div>

            </div>
        )
    }
}
