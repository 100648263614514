import React from 'react'
import PopupMenu from '../../PopupMenu'

interface PropsType {
    onRemovePressed: (language: string) => any
    onEditPressed: (language: string) => any
    language: string
}


export default class DescriptionEditorContextMenu extends React.Component<PropsType>
{

    render() {
        const {onRemovePressed, onEditPressed, language} = this.props

        return <>
            <PopupMenu className='ItemShortMenu'
                       iconClassName='ActivatorMenuIcon likeBtn fas fa-bars'
                       onContextMenu={(e: any) => {
                           if (e) e.preventDefault()
                           onEditPressed(language)
                       }}>
                <i className="likeBtn far fa-trash-alt" onClick={() => onRemovePressed(language)}/>
                <i className="likeBtn fas fa-pencil-alt" onClick={() => onEditPressed(language)}/>
            </PopupMenu>

        </>
    }
}
