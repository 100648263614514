import React, {Fragment} from "react";
import './Project.css';
import {connect} from "react-redux";
import StoreManager from "../../managers/StoreManager";
import ProjectMemberAccessBlock from "./ProjectMemberAccessBlock";
import ProjectData from "../../models/ProjectData";
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";


class ProjectMembersItem extends React.Component {

    state = {
      isContextMenuActive: false
    };

    activateContextMenu(value = true) {
        this.setState({
            isContextMenuActive: value
        })
    }

    saveModifiedAccess(modifiedAccess) {
        if (!this.user) return;
        ServerManager.instance.setMemberAccess(this.user.userId, this.props.project.projectId, modifiedAccess)
            .then();
    }

    checkAndPreloadUserData() {
        const {users, userId} = this.props;
        const user = users[userId] || null;

        if (!user) {
            StoreManager.updateUser(userId);
        } else {
            this.user = user;
        }
    }

    get isAdmin() {
        const {project} = this.props;
        const {userId} = this.user;
        if (!project || !userId) return false;

        return project.isUserAdmin(userId);
    }

    onSetAdministrator() {
        const {project: {projectId}} = this.props;
        const {userId} = this.user;

        ServerManager.instance.setProjectAdministrator(projectId, userId).then();
    }

    onExcludeMember() {
        const {project: {projectId}} = this.props;
        const {userId} = this.user;

        InfoManager.confirmDialog('Do you want to exclude this member from project ?')
            .then(() => ServerManager.instance.excludeProjectMember(projectId, userId).then())
            .catch(() => {});
    }

    onSetDefaultRights() {
        const {project: {projectId}} = this.props;
        const {userId} = this.user;

        InfoManager.confirmDialog('Do you want to set default access rights for this member ?')
            .then(() => ServerManager.instance.setMemberDefaultRights(projectId, userId).then())
            .catch(() => {});
    }

    render() {
        this.checkAndPreloadUserData();

        const {project, isAdmin} = this.props;
        if (!this.user || !project) return <React.Fragment />;

        const userNameColor = project.isUserAdmin(this.user.userId) ? "var(--object-author-color)" : "var(--input-color)";

        return(
            <div className="DoubleSmallBlock">
                <i className={isAdmin ? "ObjectLIMenuBtn likeBtn fas fa-bars" : ""}
                   onClick={() => this.activateContextMenu(true)}/>

                <div className='DoubleSmallBlockAvatar' >
                    <img src={this.user.avatarData} alt=''/>
                </div>

                <div style={{textAlign: "center"}}>
                    <b style={{color: userNameColor}}>{this.user.fullName}</b>
                </div>
                {
                    isAdmin ?
                        <Fragment>
                            <div style={{textAlign: "center"}}>
                                <u>Extra group rights:</u>
                            </div>
                            <ProjectMemberAccessBlock
                                accessList={ProjectData.accessList}
                                defaultAccess={project.memberAccess}
                                readOnly={!isAdmin}
                                isAdmin={project.isUserAdmin(this.user.userId)}
                                onChange={access => this.saveModifiedAccess(access)}
                                userAccess={this.user.access[project.projectId]}/>
                        </Fragment>: null
                }
                <div className={`ProjectMemberContextMenu ObjectLIContextBlock ${this.state.isContextMenuActive ? '' : 'hided'}`}
                     onMouseLeave ={() => this.activateContextMenu(false)}>

                    <div className="ObjectLIContextItem likeBtn"
                         onClick={() => this.onSetAdministrator()}>

                        <i className="fas fa-user-shield"/> {this.isAdmin ? 'Unset' : 'Make'} administrator
                    </div>

                    <div className="ObjectLIContextItem likeBtn"
                         onClick={() => this.onExcludeMember()}>

                        <i className="fas fa-user-slash"/> Exclude member
                    </div>

                    <div className="ObjectLIContextItem likeBtn"
                         onClick={() => this.onSetDefaultRights()}>

                        <i className="fas fa-tasks"/> Set default rights
                    </div>

                </div>

            </div>
        )
    }

}


const mapStateToProps = state => ({
    users: state.users
});
export default connect(mapStateToProps)(ProjectMembersItem);
