import TaggableData from "./TaggableData";
import MediaControl from "../modelControllers/MediaControl";
import ObjectData from "./ObjectData";


export enum MediaType {
    speak = 'speak',
    textDoc = 'textDoc',
    avatar = 'avatar',
    plan = 'plan',
    icon = 'icon',
    webLink = 'webLink',
    relatedObj = 'relatedObj',
}

export const mediaTypeName: Record<string, string> = {
    'speak': 'Soundtrack',
    'textDoc': 'Text document',
    'avatar': 'Avatar',
    'plan': 'Plan',
    'icon': 'Icon',
    'webLink': 'Web link',
    'relatedObj': 'Related object',
}

export enum WebLinkType {
    homepage = 'homepage',
    wikipedia = 'wikipedia',
    encyclopedia = 'encyclopedia',
    socialMedia = 'socialMedia',
    video = 'video',
    image = 'image',
    sendMessage = 'sendMessage',
    openingTime = 'openingTime',
    navigate = 'navigate'
}

export enum RelationType {
    author = 'author',
    style = 'style',
    prefPrevious = 'prefPrevious',
    prefNext = 'prefNext'
}

export const relationTypeName: Record<string, string> = {
    'author': 'Author',
    'style': 'Style',
    'prefPrevious': 'Preferred previous',
    'prefNext': 'Preferred next'
}


export default class MediaData extends TaggableData {

    static get mainAvatarMediaId() {return "DEFA617A-0000-0000-0000-000000000000"}

    static imageDataFrom(base64: string): string | null {
        return base64 ? 'data:image/png;base64,' + base64 : null;
    }

    get objectId(): string {return this._data.objectId};

    get mediaId(): string {return this._data.mediaId};

    get mediaType():MediaType {return this.tags.MediaType ||this._data.mediaType || MediaType.speak}

    get textData() {return this._data.textData || ''}

    get fileData() {
        // Should init filedData updating if has fileId & data is empty
        if (!this.isSpeak && this._data.fileId && !this._data.fileData) MediaControl.updateFileData(this).then();

        return this._data.fileData || null
    }

    get imageData() {return this.fileData ? (this.fileData.includes('base64') ? this.fileData : 'data:image/png;base64,' + this.fileData) : null}

    get textFileId() {return this._data.textFileId || ''}

    get fileId() {return this._data.fileId || null}

    set textData(val: string | null) {this._data.textData  = val}

    set fileData(val: string | null) {this._data.fileData = val}

    get isSubTrack() {return !!this.tags.ParentSoundtrack}

    get hasChildren() {
        if (!this.parent || !this.parent.mediaEntries) return false;
        return !!this.parent.mediaEntries.find((it: MediaData) => it.tags.ParentSoundtrack === this.mediaId)
    }

    get isSpeak() {return this.mediaType === MediaType.speak}

    get isAvatar() {return this.mediaType === MediaType.avatar}

    get isIcon() {return this.mediaType === MediaType.icon}

    get isLink() {return this.mediaType === MediaType.webLink}

    get isTextDoc() {return this.mediaType === MediaType.textDoc}

    get isRelatedObject() {return this.mediaType === MediaType.relatedObj}

    get isMainAvatar() {
        const object: ObjectData = this.parentObject

        if (this.mediaType !== MediaType.avatar || !object) {
            return false
        }

        const mainAvatarId = object.tags.AvatarMediaId || MediaData.mainAvatarMediaId
        return this.mediaId === mainAvatarId
    }

    isChildOf(parentTrack: any) {return this.tags.ParentSoundtrack === parentTrack.mediaId}

    get parentObject() {
        return this.isSubTrack ? (this.parent || {}).parent : this.parent
    }

    get language(): string {return this.isSubTrack ? this.parent.language : this.tags.Language}

    get getDescription(): string {
        return this.tags.Description || (this.parent ? this.parent.tags.Description || '' : '');
    }

    get getComment(): string {
        return this.tags.Comment || '';
    }

    get parentSpeekId(): string | null {return this.tags.ParentSoundtrack || null}

    static sortByPrefPrevTag(list: Array<MediaData>): Array<MediaData> {
        const sort = () => {
            let listAffected = false;

            list.forEach((it, ind, list) => {
                if (!it.tags.PrefPrevSpeakId) return;

                const prefIndex = list.findIndex(s => s.mediaId === it.tags.PrefPrevSpeakId);
                if (prefIndex < 0) return;
                if (prefIndex + 1 === ind) return;

                listAffected = true;
                list.splice(ind, 1);
                list.splice(prefIndex + 1, 0, it);
            });
            return listAffected;
        };

        let attemptCount = 0;
        do {
            if (attemptCount > list.length) {
                // console.log('WRONG ORDERING', list);
                break;
            }
            attemptCount++;
        } while (sort());

        return list;
    }

    nextSpeek(shift = 1): MediaData | null {
        const mediaEntries = this.isSubTrack ? this.parent.parent.mediaEntries : this.parent.mediaEntries;
        const list = MediaData.sortByPrefPrevTag(mediaEntries
            .filter((item: MediaData) => this.isSubTrack === item.isSubTrack));

        const currentPosition = list.findIndex((item: MediaData) => item.mediaId === this.mediaId);

        return list[currentPosition + shift] || null;
    }

    static get avatarMediaId() {return "DEFA617A-0000-0000-0000-000000000000"}


}
