import noPhoto from "../resources/nophoto.gif";
type CreatorDataStructure = {
    userId: string,
    avatar: string,
    email: string,
    nickname: string,
    _id?: any
}


export default class CreatorData {

    constructor(data: CreatorDataStructure) {
        this._data = data;
        delete this._data._id;
    }

    _data: CreatorDataStructure;

    get userId(): string {
        return this._data.userId;
    }

    get avatarImageData(): string {
        return this._data.avatar ? 'data:image/png;base64,' + this._data.avatar : noPhoto;
    }

    get name(): string {
        return this._data.nickname || this._data.email.split('@')[0];
    }

    get email(): string {
        return this._data.email;
    }

}