import React from "react";
import ProjectMembersItem from "./ProjectMemberItem";
import MembershipRequestItem from "./MembershipRequestItem";
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import PopupInputDialog from "../PopupInputDialog/PopupInputDialog";
import ProjectInvitationItem from "./ProjectInvitationItem";


export default class ProjectMembersSubPage extends React.Component {

    state = {
        isInviteWindowOpen: false,
        inviteInputData: [
            {
                id: 'email',
                type: 'email',
                header: 'Enter user email'
            },
            {
                id: 'reason',
                type: 'textarea',
                header: 'Specify the reason'
            },
            {
                id: 'isAdmin',
                type: 'checkbox',
                header: 'Invite user as administrator',
                defaultValue: false
            }
        ]
    };

    onInvitePressed(value = true) {
        this.setState({
            isInviteWindowOpen: value
        });
    }

    async onInviteSubmitted(data) {
        if (data.email) {
            await ServerManager.instance.inviteUserToTheProject(data.email, this.props.project.projectId, data.isAdmin || false, data.reason);
        } else {
            InfoManager.showMsg('Please enter correct email address');
        }
    }

    render() {
        const {project, isActive, isAdmin} = this.props;
        if (!isActive) return <React.Fragment />;

        return(
            <div className="DoubleRightSide">
                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon fas fa-pencil-alt"/> <b>Group members </b>
                    <i className={isAdmin ? "ProjectAddFlagBtn likeBtn fas fa-plus" : "hided"}
                       onClick={() => this.onInvitePressed()}
                       title="Add preset"/>

                    <br/>
                    <br/>
                    {
                        project.members.map(userId => (
                            <ProjectMembersItem isAdmin={isAdmin} userId={userId} project={project} key={`ProjectMember${userId}`}/>
                        ))
                    }
                </div>

                <div className={isAdmin ? "DoubleRightBlock" : "hided"}>
                    <i className="DoubleTextIcon far fa-bell"/> <b>Membership requests </b>
                    <br/>
                    <br/>
                    {
                        project.requests.map(it => (
                            <MembershipRequestItem
                                request={it}
                                project={project}
                                key={`ProjectMembership${it.userId}`}/>
                        ))
                    }
                </div>

                <div className={isAdmin ? "DoubleRightBlock" : "hided"}>
                    <i className="DoubleTextIcon far fa-paper-plane"/> <b>Invitations </b>
                    <br/>
                    <br/>
                    {
                        project.invitations.map(it => (
                            <ProjectInvitationItem
                                invitation={it}
                                project={project}
                                key={`ProjectInvitation${it.email}`}/>
                        ))
                    }
                </div>

                <PopupInputDialog
                    isActive={this.state.isInviteWindowOpen}
                    inputDataList={this.state.inviteInputData}
                    header={"New invitation"}
                    submitText={"Send"}
                    onSubmit={(data) => this.onInviteSubmitted(data)}
                    onClose={() => this.onInvitePressed(false)} />
            </div>
        )
    }
}
