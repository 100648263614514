import React from "react";

const platformList = [['web', 'Web'], ['ios', 'iOS'], ['android', 'Android'], ['androidAuto', 'Android Auto'], ['appleCar', 'Apple CarPlay']];


export default class TargetPlatformList extends React.Component {

    render() {
        const {onInputCheck, tags} = this.props;
        if (!onInputCheck || !tags) return <React.Fragment/>;

        return (
            <React.Fragment>
                <b><u>Target platforms:</u></b><br/>
                {
                    platformList.map(platform => {
                        const name = 'TargetType_' + platform[0];
                        const onState = undefined;
                        const onState2 = null;
                        const offState = 'false';

                        return (
                            <React.Fragment key={'TargetPlatform_' + platform[0]}>
                                <span className='OptionKey'>
                                    {platform[1]}
                                </span>
                                <span className='OptionValue'>
                                    <input
                                        type='checkbox'
                                        checked={tags[name] === onState || tags[name] === onState2}
                                        onChange={(e) => {
                                            onInputCheck(name, e, onState, offState)
                                        }}
                                    />
                                </span>
                            </React.Fragment>
                        )
                    })
                }
            </React.Fragment>
        )
    }
}