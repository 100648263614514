import React, {Fragment} from "react";
import './MediaList.css';
import ObjectSelector from "../../ObjectSelector";
import MediaControl from "../../../modelControllers/MediaControl";
import {RelationType} from "../../../models/MediaData";


export default class RelatedObjectItem extends React.Component {

    render() {
        const {media} = this.props;
        if (!media || !media.isRelatedObject) return null;

        const {RelatedObjectId, RelationType: relationType} = media.tags;

        const childWithProps = this.props.children ?
            React.cloneElement(this.props.children, this.props)
            : <Fragment/>

        return(
            <div className={'ObjectLISpeekContainer AvatarItemContainer' + (!media.isEnabled ? ' disabledBG' : '')}>
                <ObjectSelector
                    selectedObjectId={RelatedObjectId}
                    placeholder={(media.tags.RelationType || 'Select') + ' object'}
                    filterTags={media.tags.RelationType === RelationType.author ? {ObjectClass: 'person'} : undefined}

                    onSelect={(value) => MediaControl.setRelatedObjectId(media, value)} />

                {childWithProps}
            </div>
        )
    }
}
