import React from "react";


export default class ContentFlagList extends React.Component {

    render() {
        const {contentFlagList, tags, onInputCheck, keySuffix = ''} = this.props;
        if (!contentFlagList || !tags || !onInputCheck) return <React.Fragment/>;

        return (
            <React.Fragment>
                <b><u>Content flags:</u></b><br/>
                {
                    contentFlagList.flagsMap().map(flag => {
                        const name = 'ContentFlag_' + flag.id;
                        const onState = 'true';
                        const offState = undefined;

                        return (
                            <React.Fragment key={'ContentFlag_'+keySuffix+'_' + flag.id}>
                                <span className='OptionKey'>
                                    {flag.description}
                                </span>
                                <span className='OptionValue'>
                                    <input
                                        type='checkbox'
                                        checked={tags[name] === onState}
                                        onChange={(e) => {
                                            onInputCheck(name, e, onState, offState)
                                        }}
                                    />
                                </span>
                            </React.Fragment>
                        )
                    })
                }
            </React.Fragment>
        )
    }
}