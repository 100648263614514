import React from 'react';
import './ProjectList.css';


export default class ProjectListItem extends React.Component {

    openProject = () => {
        console.log(this.props.project);
        this.props.history.push(`/project/${this.props.project.projectId}`);
    };

    render() {
        const {project} = this.props;

        const iconElement = () => {
            if (!project.avatarData) return (
                <i className={`fas fa-user${project.isPublic ? 's' : '-secret'}`} />
            );

            return <img alt="" src={project.avatarData} />
        };

        return (
            <div className="ProjectListItem" onClick={this.openProject}>

                <div className="ProjectItemLeft">
                    <div className="ProjectIconBlock">
                    {iconElement()}
                    </div>
                </div>

                <div className="ProjectItemRight">
                    <div className="ProjectItemName">
                        {project.description}
                    </div>

                    <div className="ProjectItemRegistered">
                        {project.isPublic ? 'Public' : 'Private'} project
                        <small> ~ {project.memberCount} <i className="far fa-user" /></small>
                    </div>

                    <div className="ProjectItemLastVisit">
                        {project.comment}
                    </div>
                </div>

                <div className={`likeBtn ProjectItemAlertBlock ${project.requestCount ? '' : 'hided'}`}
                     title="You have new membership requests">
                    {project.requestCount}{' '}
                    <i className="fas fa-bell" />
                </div>

            </div>
        )

    }
}
