import React from "react";
import ContentFlagList from "../../models/ContentFlagList";
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import PopupInputDialog, {DialogInputType} from "../PopupInputDialog/PopupInputDialog";

const descriptionInputDataList = [
    {
        id: "Language",
        type: DialogInputType.LANGUAGE,
        header: "Choose the language"
    },
    {
        id: "Description",
        type: DialogInputType.TEXT,
        header: "Enter description"
    }
]


export default class ContentFlagItem extends React.Component {

    state = {
        isAddDescriptionActive: false,
        descriptionEditDataList: null
    }

    onDescriptionContextMenu(lang, value = true) {
        const {flag: {id: flagId}} = this.props;
        this.setState({[flagId + lang]: value});
    }

    onFlagRemove() {
        InfoManager.confirmDialog('Do you want to delete this flag ?').then(() => {
            const {project: {projectId}, flag: {id: flagId}} = this.props;
            ServerManager.instance.removeContentFlag(projectId, flagId).then(answer =>
                InfoManager.showMsg(answer.status ? 'Flag removed' : answer.msg || 'Server error')
            );
        }).catch(() => {});
    }

    onDescriptionAdd(data) {
        const {Language, Description} = data
        if (!Language || !Description) {
            return InfoManager.showMsg("Please enter both parameters")
        }

        const {project: {projectId}, flag: {id: flagId}} = this.props
        const changes = {['description_' + Language]: Description}

        console.log({projectId, flagId, changes})
        ServerManager.instance.editContentFlag(projectId, flagId, changes).then(answer =>
            InfoManager.showMsg(answer.status ? 'Description assigned' : answer.msg || 'Server error')
        )
    }

    onCheck(fieldName) {
        const {project: {projectId}, flag} = this.props;
        const changes = {[fieldName]: !flag[fieldName]};

        ServerManager.instance.editContentFlag(projectId, flag.id, changes).then(answer =>
            InfoManager.showMsg(answer.status ? 'Value changed' : answer.msg || 'Server error')
        );
    }

    onDescriptionRemove(lang) {
        InfoManager.confirmDialog('Do you want to delete this description ?').then(() => {
            const {project: {projectId}, flag: {id: flagId}} = this.props;
            const changes = {['description_' + lang]: null};

            ServerManager.instance.editContentFlag(projectId, flagId, changes).then(answer =>
                InfoManager.showMsg(answer.status ? 'Description removed' : answer.msg || 'Server error')
            );
        }).catch(() => {});
    }

    onDescriptionEditStart(language) {
        const oldDescription = this.props.flag[`description_${language}`]

        if (!language || !oldDescription) {
            return
        }

        this.setState({
            descriptionEditDataList: [
                {
                    id: "Language",
                    type: DialogInputType.LANGUAGE,
                    defaultValue: language,
                    useDefaultValueOnSubmit: true,
                    readonly: true,
                    header: "Editing description language"
                },
                {
                    id: "Description",
                    type: DialogInputType.TEXT,
                    header: "Enter description",
                    defaultValue: oldDescription
                }
            ]
        })

    }

    onDescriptionEdited(data) {
        const {Language, Description} = data
        if (!Language || !Description) {
            return InfoManager.showMsg('Wrong description or language')
        }

        const {project: {projectId}, flag: {id: flagId}} = this.props
        const changes = {['description_' + Language]: Description}

        ServerManager.instance.editContentFlag(projectId, flagId, changes).then(answer =>
            InfoManager.showMsg(answer.status ? 'Description changed' : answer.msg || 'Server error')
        )
    }

    onChangeDefaultFilter() {
        const value = this.defaultFilterRef.current.value;

        const {project: {projectId}, flag: {id: flagId}} = this.props;
        const changes = {defaultFilter: value};

        ServerManager.instance.editContentFlag(projectId, flagId, changes).then(answer =>
            InfoManager.showMsg(answer.status ? 'Default filter changed' : answer.msg || 'Server error')
        );
    }

    defaultFilterRef = React.createRef()

    render() {

        const {project, flag} = this.props
        if (!project || !flag) return <React.Fragment />

        return(
            <>
                <div className="DoubleSmallBlock">
                    <div className="DoubleSmallBlockHead">
                        <b>{ContentFlagList.flagDescription(flag)}</b>
                        <i className="likeBtn DSBlockHeadBtn far fa-trash-alt"
                           onClick={() => this.onFlagRemove()}
                           title="Remove flag"/>
                    </div>

                    <b>Identifier:</b> <br/>
                    <input type="text" readOnly={true} defaultValue={flag.id}/><br/>

                    <b>Default filter:</b>

                    <select defaultValue={ContentFlagList.flagDefaultFilter(flag)}
                            ref={this.defaultFilterRef}
                            onChange={() => this.onChangeDefaultFilter()}>
                        {
                            Object.entries(ContentFlagList.states).map(([value, name]) =>
                                <option value={value} key={flag.id + name}>{name}</option>
                            )
                        }
                    </select>


                    <br/>
                    <b>Commercial </b>
                    <input type='checkbox'
                           onChange={() => this.onCheck('commercial')}
                           checked={flag.commercial === true}/>

                    <br/>
                    <b>Internal </b>
                    <input type='checkbox'
                           onChange={() => this.onCheck('internal')}
                           checked={flag.internal !== false}/>


                    <br/>
                    <div className="ProjectFlagDescBlock">
                        <b>Descriptions:</b>
                        <i className="ProjectFlagAddDesc likeBtn fas fa-plus"
                           onClick={() => this.setState({isAddDescriptionActive: true})}
                           title="Add description"/>
                        <br/>
                        {
                            Object.keys(flag).map(key => {
                                if (!key.includes('description_')) return null;

                                const lang = key.split('description_')[1];
                                const value = flag[key];

                                const ctxMenuOn = !!this.state[flag.id + lang];

                                return (
                                    <div className="ProjectFlagDescItem"
                                         onMouseLeave={() => this.onDescriptionContextMenu(lang, false)}
                                         key={flag.id + key}>
                                        <img src={`/flags/${lang}.png`} alt=''/>
                                        {value}
                                        <i className="ProjectFlagDescMenu likeBtn fas fa-bars"
                                           onClick={() => this.onDescriptionContextMenu(lang)}/>

                                        <div className={`ProjectItemContextMenu ${ctxMenuOn ? '' : 'hided'}`}>
                                            <i className="FlagDescriptionRemove likeBtn far fa-trash-alt"
                                               onClick={() => this.onDescriptionRemove(lang)}/>
                                            <i className="FlagDescriptionEdit likeBtn fas fa-pencil-alt"
                                               onClick={() => this.onDescriptionEditStart(lang)}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <PopupInputDialog
                    isActive={this.state.isAddDescriptionActive}
                    onClose={() => this.setState({isAddDescriptionActive: false})}
                    inputDataList={descriptionInputDataList}
                    onSubmit={(data) => this.onDescriptionAdd(data)}
                />

                <PopupInputDialog
                    isActive={!!this.state.descriptionEditDataList}
                    onClose={() => this.setState({descriptionEditDataList: null})}
                    inputDataList={this.state.descriptionEditDataList}
                    onSubmit={(data) => this.onDescriptionEdited(data)}
                />
            </>
        )
    }

}
