import React from 'react';
import './ObjectList.css';
import ObjectListItem from "./standard-mode/ObjectListItem";
import {connect} from "react-redux";
import StoreManager from "../../managers/StoreManager";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import SelectionBar from "./standard-mode/SelectionBar";
import FilterManager from "../../managers/FilterManager";
import ErrorBoundary from "../ErrorBoundary";


class ObjectList extends React.Component {

    componentDidMount() {
        // FilterManager.downloadedObjectCount = 0;
        if (this.props.objectList.length < 12) StoreManager.updateObjectList();
        if (Object.keys(this.props.projects).length < 1) StoreManager.updateProjectList();
        window.addEventListener('scroll', (e) => this.scrollHandler(e));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', (e) => this.scrollHandler(e));
    }

    scrollHandler(e) {
        // console.log(e)

        let st = window.pageYOffset; //$(document).scrollTop();
        let scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );

        if(scrollHeight - (st + document.documentElement.clientHeight) < 1000) {
            //download next objects
            const count = this.props.objectList.length;

            if (count - 11 > FilterManager.downloadedObjectCount) {
                FilterManager.downloadedObjectCount = count;
                StoreManager.updateObjectList(count);
            }
        }
    }

    render() {
        const {objectList, selectedObjectList: selected, historyPush, me, creators} = this.props;

        return (
            <React.Fragment>
                <div className='ObjectListBlock'>
                    {
                        objectList.map( item => {
                            const isSelected = selected.includes(item.objectId);
                            const creator = creators[item.creatorId];
                            if (!creator) StoreManager.updateCreator(item.creatorId);

                            return (
                                <ErrorBoundary className='ObjectListItem'
                                               id={item.objectId}
                                               key={"ObjectItem_" + item.objectId}>
                                    <ObjectListItem object={item}
                                                    historyPush={historyPush}
                                                    me={me}
                                                    creator={creator}
                                                    isSelected={isSelected} />
                                </ErrorBoundary>
                            )
                        })
                    }
                </div>
                <AudioPlayer/>
                <SelectionBar selected={selected} />
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    const objectList = Object.values(state.objects)
        .sort((a, b) => {
            const aIndex = StoreManager.orderingList.indexOf(a.objectId);
            const bIndex = StoreManager.orderingList.indexOf(b.objectId);
            return aIndex > bIndex ? 1 : -1;
        });

    return {
        objectList,
        projects: state.projects,
        creators: state.creators,
        me: state.me,
        selectedObjectList: state.selectedObjectList
    }
};

export default connect(mapStateToProps)(ObjectList);
