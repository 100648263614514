import React from "react";
import ProjectData from "../../models/ProjectData";
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";


export default class ProjectAdministrativeSubPage extends React.Component {

    access = {};

    memberAccess = {};

    analytics = {
        UATrackingId: React.createRef(),
        GATrackingId: React.createRef(),
        GAApiSecret: React.createRef(),
    }

    onSaveAccess() {
        const {project: {projectId}} = this.props;

        const access = Object.keys(this.access)
                        .filter(key => this.access[key].current.checked)
                        .join(';');

        const memberAccess = Object.keys(this.memberAccess)
                        .filter(key => this.memberAccess[key].current.checked)
                        .join(';');

        console.log(access, memberAccess);
        ServerManager.instance.editProject(projectId, {access, memberAccess}).then();
    }

    onRemoveProject() {
        const {project: {projectId}} = this.props;

        InfoManager.confirmDialog('Do you want to remove project ?')
            .then(() => {
                InfoManager.confirmDialog('Delete all project objects ?')
                    .then(() => ServerManager.instance.removeProject(projectId, true).then())
                    .catch(() => ServerManager.instance.removeProject(projectId, false).then());
            })
            .catch(() => {

            });
    }

    onSaveAnalytics() {
        const {project} = this.props
        const changes = []

        const uaKey = this.analytics.UATrackingId.current.value
        if (uaKey !== (project.tags.UATrackingId || '')) {
            changes.push({["UATrackingId"]: uaKey || false})
        }

        const gaKey = this.analytics.GATrackingId.current.value
        const gaApi = this.analytics.GAApiSecret.current.value
        if (gaKey !== (project.tags.GATrackingId || '')) {
            changes.push({["GATrackingId"]: gaKey || false})
        }
        if (gaApi !== (project.tags.GAApiSecret || '')) {
            changes.push({["GAApiSecret"]: gaApi || false})
        }

        if (changes.length > 0) {
            ServerManager.instance.setTags(null, null, null, project.projectId, null, changes)
        }
    }

    render() {
        const {project, isActive} = this.props;
        if (!isActive || !project) return <React.Fragment />;

        return(
            <div className="DoubleRightSide">
                <div className="DoubleRightBlock">

                    <i className="DoubleTextIcon fas fa-lock-open"/> <b>Content restrictions </b>
                    <br/>
                    <div className='DoubleInputBlock'>
                        <b>Not a member</b>
                        <br/>
                        <br/>
                        {
                            ProjectData.visitorPermissionList.map(rule => {
                                this.access[rule] = React.createRef();
                                return (
                                    <React.Fragment key={'visitorPermissionList' + rule}>
                                        <input type='checkbox'
                                               className='ProjectAccessCheckbox'
                                               ref={this.access[rule]}
                                               defaultChecked={project.access.includes(rule)}/>
                                        {' '}{rule}
                                        <br/>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>

                    <div className='DoubleInputBlock'>
                        <b>Common member</b>
                        <br/>
                        <br/>
                        {
                            ProjectData.memberPermissionList.map(rule => {
                                this.memberAccess[rule] = React.createRef();
                                return (
                                    <React.Fragment key={'memberPermissionList' + rule}>
                                        <input type='checkbox'
                                               className='ProjectAccessCheckbox'
                                               ref={this.memberAccess[rule]}
                                               defaultChecked={project.memberAccess.includes(rule)} />
                                        {' '}{rule}
                                        <br/>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>

                    <div className="likeBtn DoubleButton"
                         onClick={() => this.onSaveAccess()}>
                        Save changes
                    </div>
                </div>


                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon far fa-trash-alt"/> <b>Group removal </b>
                    <br/>

                    <div className="likeBtn DoubleButton" onClick={() => this.onRemoveProject()}>
                        Remove group
                    </div>
                </div>


                <div className="DoubleRightBlock">
                    <i className="DoubleTextIcon fad fa-analytics"/> <b>Analytics </b>
                    <br/>

                    <div className="DoubleFullWidth DoubleInputBlock">
                        Google Analytics (Universal Analytics resource id)
                        <br/>
                        <input className="DoubleFullWidthInput DoubleInput"
                               type="text"
                               ref={this.analytics.UATrackingId}
                               placeholder="UA-XXXXXXXXX-Y"
                               defaultValue={project.tags["UATrackingId"]}
                        />
                    </div>

                    <div className="DoubleFullWidth DoubleInputBlock">
                        Google Analytics (GA4 resource id and api key)
                        <br/>
                        <input className="DoubleFullWidthInput DoubleInput"
                               type="text"
                               ref={this.analytics.GATrackingId}
                               placeholder="G-XXXYYYYYXX"
                               defaultValue={project.tags["GATrackingId"]}
                        />
                        <br/>
                        <input className="DoubleFullWidthInput DoubleInput"
                               type="text"
                               ref={this.analytics.GAApiSecret}
                               placeholder="API key"
                               defaultValue={project.tags["GAApiSecret"]}
                        />
                    </div>

                    <div className="likeBtn DoubleButton" onClick={() => this.onSaveAnalytics()}>
                        Save changes
                    </div>
                </div>
            </div>
        )
    }
}
