import React from 'react';


export default class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.log(this.props.id /*, error, errorInfo*/)
    }

    render() {
        if (this.state.hasError) {
            return <div className={this.props.className} style={{border: '1px dashed orangered', 'text-align': 'center'}}>
                <h3>Something went wrong</h3>
            </div>;
        }

        return this.props.children
    }

}


