import React from 'react';
import './Repository.css';
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import OptionsBar from "../OptionsBar/OptionsBar";
import ProgressBar from "../ProgressBar";


export default class Repository extends React.Component {

    // TODO: fix '../' bug !!!

    state = {
        path: [],
        list: [],
        contextMenu: null,
        contextMenuPosition: {
            left: 0,
            top: 0
        },
        progress: null
    };

    getList(path = '') {
        ServerManager.instance.repositoryList(path)
            .then(list => {
                !Array.isArray(list) ? this.setState({list: null}) : this.setState({list})
            });
    }

    goToDirectory(newPath) {
        this.setState({
            path: newPath
        });

        this.getList(newPath.join('/'));
    }

    componentDidMount() {
        this.getList('');
    }

    contextMenu(e, value = true) {
        try {
            this.setState({
                contextMenu: value,
                contextMenuPosition: {
                    left: e.nativeEvent.clientX - 20,
                    top: e.nativeEvent.clientY - 10
                }
            });

            e.preventDefault();

            if (value) {
                this._CTXisCatalog = e.target.getAttribute('data-catalog') === 'true';
                this._CTXitemName = e.target.getAttribute('data-name');
            }
        } catch (e) {
            console.log(e);
        }
    }

    onCreateCatalog(path = null) {
        path = path ? path : this.state.path.join('/');

        InfoManager.waitTextDialog('Catalog name', '', 'Enter name for new catalog ...').then(newName => {
            console.log(newName);

            ServerManager.instance.repositoryAddCatalog(path, newName).then(result => {
                console.log(result);
                this.goToDirectory([...path.split('/'), newName])
            }).catch((e) => {console.log(e)});
        }).catch((e) => {console.log(e)});
    }

    onClick(item) {
        if (!item.isDirectory) {
            const url =(`https://downloads.explainitapp.com/${this.state.path.join('/')}/${item.name}`);
            InfoManager.downloadDataAsFile(url, item.name);
            return;
        }
        this.goToDirectory([...this.state.path, item.name]);
    }

    onReturn() {
        const newPath = [...this.state.path];
        newPath.splice(-1);
        this.goToDirectory(newPath);

        if (this.state.contextMenu) this.setState({contextMenu: false});
    }

    onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();

        const el = event.target;
        if (!el.className.includes('blueBorder')) el.className = el.className + ' blueBorder';
    }

    async onDrop(event) {
        this.onDragLeave(event);

        const isCatalog = event.target.getAttribute('data-catalog') === 'true';
        const catalogName = isCatalog ? event.target.getAttribute('data-name') : null;
        const currentPath = this.state.path;

        const catalog = catalogName ? [...this.state.path, catalogName] : currentPath;
        console.log(catalog.join('/'));


        let files = [];

        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (event.dataTransfer.items[i].kind === 'file') {
                    let file = event.dataTransfer.items[i].getAsFile();
                    files.push(file);
                }
            }
        } else {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                let file = event.dataTransfer.files[i];
                files.push(file);
            }
        }

        const filesDataArr = await Promise.all(files.map(async file => {
            return {
                name: file.name,
                size: file.size,
                type: file.type,
                data: await InfoManager.readFileWithoutRejection(file)
            };
        }));

        await this.sendFiles({catalog: catalog.join('/'), files: filesDataArr});
        this.goToDirectory(catalog);
    }

    onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();

        const el = event.target;
        if (el.className.includes('blueBorder')) el.className = el.className.split(' blueBorder').join(' ');
    }

    async sendFiles(data) {
        const stringData = JSON.stringify(data);

        const parts = stringData.match(/[\s\S]{1,100000}/g) || [];
        const packetCount = parts.length;

        await ServerManager.instance.repositoryStartUpload();
        this.setProgress(0);
        await Promise.all(parts.map(async (part, i) => {
            await ServerManager.instance.repositoryUploadChunk(part);

            const progressValue = Math.round( i / packetCount * 100);
            this.setProgress(progressValue);

            return true;
        }));

        await ServerManager.instance.repositoryFinishUpload();
        this.setProgress(null);
    }

    setProgress(value) {
        this.setState({
            progress: value
        });
    }

    // CTX handlers
    onCopyLink() {
        const path = this.state.path.length > 0 ?
            `https://downloads.explainitapp.com/${this.state.path.join('/')}/`
            : `https://downloads.explainitapp.com/`;
        const linkURL = encodeURI(path + (this._CTXitemName));

        InfoManager.copyToClipboard(linkURL);
        InfoManager.showMsg('Link copied');

        this.setState({contextMenu: false});
    }

    onRename() {
        InfoManager.waitTextDialog('Enter new name please', this._CTXitemName).then(newName => {
            console.log(this.state.path.join('/'), this._CTXitemName, newName);
            ServerManager.instance.repositoryRename(this.state.path.join('/'), this._CTXitemName, newName).then(result => {
                console.log(result);
                if (result) {
                    this.getList(this.state.path.join('/'));
                }
            });
        }).catch(() => {});
    }

    onDelete() {
        InfoManager.confirmDialog('Do you want to remove this ?').then(() => {
            ServerManager.instance.repositoryDelete(this.state.path.join('/'), this._CTXitemName).then(result => {
                if (result) {
                    this.getList(this.state.path.join('/'));
                }
            });
        }).catch(() => {});
    }

    onNewFolderHere() {
        const path = this._CTXisCatalog ? [...this.state.path, this._CTXitemName] : this.state.path;
        console.log(path);
        this.onCreateCatalog(path.join('/'));
        this.setState({contextMenu: false});
        console.log('END');
    }

    async onUploadHere() {
        const catalogName = this._CTXisCatalog ? this._CTXitemName : null;
        const currentPath = this.state.path;

        const catalog = catalogName ? [...this.state.path, catalogName] : currentPath;

        const files = await InfoManager.waitFileDialog2(true);
        const filesDataArr = await Promise.all(Object.keys(files.target.files).map(async i => {
            return {
                name: files.target.files[i].name,
                size: files.target.files[i].size,
                type: files.target.files[i].type,
                data: await InfoManager.readFileWithoutRejection(files.target.files[i])
            };
        }));

        await this.sendFiles({catalog: catalog.join('/'), files: filesDataArr});
        this.goToDirectory(catalog);
    }

    render() {
        if (!this.state.list) return null;

        return (
            <React.Fragment>

                <OptionsBar>
                    <i className='likeBtn fas fa-arrow-left RepositoryReturn' onClick={() => this.onReturn()} />{' '}
                    Repository
                    <span className='RepositoryMenuBtn likeBtn'
                          onClick={() => this.onCreateCatalog()}>
                        <i className="far fa-folder"/>
                        New folder
                    </span>
                    <span className='RepositoryMenuBtn likeBtn'
                          onClick={() => this.onUploadHere()}>
                        <i className="fas fa-arrow-up"/>
                        Upload here
                    </span>
                </OptionsBar>

                <div className='RepositoryContainer'
                     onDrop={e => this.onDrop(e)}
                     onDragLeave={e => this.onDragLeave(e)}
                     onDragOver={e => this.onDragOver(e)}>

                    {
                        this.state.list.map(it => { // {name, isDirectory, size}

                            return (
                                <div className="RepositoryItem"
                                     onClick={() => this.onClick(it)}
                                     data-name={it.name}
                                     data-catalog={it.isDirectory ? 'true' : 'false'}
                                     onContextMenu={e => this.contextMenu(e)}
                                     key={'RepositoryItem' + it.name + it.isDirectory} >

                                    <i className={'far fa-' + (it.isDirectory ? 'folder-open' : 'file-alt')} />
                                    { it.name }
                                    <div className="RepositoryItemSize">
                                        { it.isDirectory ? 'Directory' : InfoManager.bytes(it.size) }
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>

                {/*Context menu*/}
                <div className={"RepositoryContextContainer" + (this.state.contextMenu ? '' : ' hided')}
                     onMouseLeave={e => this.contextMenu(e, false)}
                     style={this.state.contextMenuPosition} >

                    <div className="RepositoryContextItem"
                         onClick={() => this.onReturn()}>
                        <i className="fas fa-arrow-left" />
                        Return
                    </div>

                    <div className="RepositoryContextItem"
                         onClick={() => this.onCopyLink()}>
                        <i className="fas fa-link" />
                        Copy link
                    </div>

                    <div className="RepositoryContextItem"
                         onClick={() => this.onRename()}>
                        <i className="far fa-edit" />
                        Rename
                    </div>

                    <div className="RepositoryContextItem"
                         onClick={() => this.onDelete()}>
                        <i className="far fa-trash-alt" />
                        Delete
                    </div>

                    <div className="RepositoryContextItem"
                         onClick={() => this.onUploadHere()}>
                        <i className="fas fa-arrow-up" />
                        Upload here
                    </div>

                    <div className="RepositoryContextItem"
                         onClick={() => this.onNewFolderHere()}>
                        <i className="far fa-folder" />
                        New folder
                    </div>
                </div>

                <ProgressBar progress={this.state.progress} label='Uploading progress'/>
            </React.Fragment>
        )
    }

}
