import React, {Fragment, Component} from "react";
import './PopupMenu.css';


export default class PopupMenu extends Component {

    state = {
        isMenuActive: false
    };

    onActivateMenu(e, state = true) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({
            isMenuActive: state
        })
    }

    render() {
        const {iconClassName = 'far fa-plus', className = 'PopupMenuContainer', children
            , onContextMenu = ((e) => this.onActivateMenu(e, true))} = this.props;

        if (!this.state.isMenuActive) return (
            <Fragment>
                <i className={iconClassName}
                   onContextMenu={onContextMenu}
                   onClick={e => this.onActivateMenu(e, true)}/>
            </Fragment>
        );

        return (
            <div className={className} onMouseLeave={() => this.onActivateMenu(null, false)}>
                {children}
            </div>
        );
    }
}