import StoreManager from "./StoreManager"
import {store} from '../store'
import storeDispatcher from '../storeDispatcher'


export const filterDefaultMap = {
    userId: null,
    filterParentId: null,
    filterObjectClass: null,
    filterObjectGroup: 'all',
    line: null,
    filterFavourites: null,
    filterDisabled: null
};


export default class FilterManager {

    static downloadedObjectCount = 0;

    static get filterMap() {
        return store.getState().filterMap;
    };

    static set(name, value) {
        if (!name) return;
        if (!value) value = filterDefaultMap[name];
        if (name === 'filterObjectGroup' && value === 'unassigned') value = null;

        storeDispatcher.setFilter({name, value});
        FilterManager.downloadedObjectCount = 0;
        StoreManager.updateObjectList(0, FilterManager.filterMap);
    }

    static unset(name) {
        FilterManager.set(name);
    }

    static clean() {
        storeDispatcher.cleanFilter(filterDefaultMap);
        FilterManager.downloadedObjectCount = 0;
        StoreManager.updateObjectList(0, filterDefaultMap);
    }

    static get isEmpty() {
        const signature = map => Object.values(map).join(',');
        return signature(filterDefaultMap) === signature(FilterManager.filterMap);
    }

    static get isNotEmpty() {
        return !this.isEmpty
    }

    static checkObjectConformity(objectData) {
        if (this.isEmpty) {
            return true
        }

        const {userId, filterGroupId, filterParentId, filterObjectClass, filterObjectGroup,
            filterFavourites, filterDisabled} = this.filterMap

        if (filterObjectClass && objectData.class !== filterObjectClass) {
            return false
        }
        if (filterDisabled && objectData.isEnabled()) {
            return false
        }
        if (filterObjectGroup !== 'all' && objectData.projectId !== filterObjectGroup) {
            return false
        }
        if (filterFavourites) {
            const me = store.getState().me
            if (!me || !me.isFavouriteObject(objectData.objectId)) {
                return false
            }
        }
        if (filterGroupId && objectData.tags.ParentObjectId !== (filterParentId || filterGroupId)) {
            return false
        }
        if (userId && objectData.creatorId !== userId) {
            return false
        }

        return true
    }

}

