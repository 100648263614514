import React from 'react'
import DescriptionItem from '../../ObjectList/standard-mode/DescriptionItem'
import DescriptionEditorContextMenu from './DescriptionEditorContextMenu'
import PopupInputDialog, {
    DialogInputList,
    DialogInputType,
    InputDataType
} from "../../PopupInputDialog/PopupInputDialog";
import InfoManager from "../../../managers/InfoManager";


interface PropsType {
    descriptions: Record<string, any>
    onChangeDescription: (language: string, description: any) => any
}


export class DescriptionEditor extends React.Component<PropsType>
{
    getInitialState() {
        return {
            isInnerEditorActive: false,
            editingDescriptionLanguage: undefined,

            descriptionInputData: new DialogInputList([
                {
                    id: "newDescription",
                    type: DialogInputType.TEXT,
                    header: "Description",
                    defaultValue: ''
                }
            ])
        }
    }

    newDescriptionInputData: DialogInputList = new DialogInputList([
        {
            id: "newLanguage",
            type: DialogInputType.LANGUAGE,
            header: "Language",
        },
        {
            id: "newDescription",
            type: DialogInputType.TEXT,
            header: "Description",
            defaultValue: ''
        }
    ])

    state = this.getInitialState()

    startAddingNew()  {
        this.setState({
            isInnerEditorActive:  true,
            editingDescriptionLanguage: undefined,
        })
    }

    startEditing(language: string)  {
        const {descriptions} = this.props

        const descriptionInputData = this.state.descriptionInputData.map((it: InputDataType) => {
            return {
                ...it,
                defaultValue: descriptions[language] || ''
            }
        })

        this.setState({
            isInnerEditorActive:  true,
            editingDescriptionLanguage: language,
            descriptionInputData
        })
    }

    startRemoving(language: string) {
        InfoManager.confirmDialog("Are you sure want to delete it?")
            .then(() => {
                this.props.onChangeDescription(language, false)
            })
            .catch(() => {})
    }

    onSubmitChanges(data: any) {
        const {newDescription, newLanguage} = data

        if ((!this.state.editingDescriptionLanguage && !newLanguage) || !newDescription) {
            return InfoManager.showMsg("Wrong parameter")
        }
        const language = this.state.editingDescriptionLanguage || newLanguage

        this.props.onChangeDescription(language, newDescription)
    }



    render() {
        const {descriptions} = this.props

        const editorInputDataList = this.state.editingDescriptionLanguage
            ? this.state.descriptionInputData
            : this.newDescriptionInputData

        const editorHeader = this.state.editingDescriptionLanguage
            ? 'Edit description'
            : 'New description'

        return (
            <>
                <div className=''>
                    {
                        Object.entries(descriptions).map(([language, description]) => {
                            return (
                                <DescriptionItem
                                    key={'DEItem_' + language}
                                    language={language}
                                    description={description}
                                >
                                    <DescriptionEditorContextMenu
                                        onEditPressed={this.startEditing.bind(this)}
                                        onRemovePressed={this.startRemoving.bind(this)}
                                        language={language}
                                    />
                                </DescriptionItem>
                            )
                        })
                    }

                    <button
                        className={'RepositoryMenuBtn likeBtn'}
                        style={{margin: "6px 0 0 1px"}}
                        onClick={this.startAddingNew.bind(this)}
                    >
                        <i className={'far fa-plus'} />
                        Add new
                    </button>
                </div>

                <PopupInputDialog
                    isActive={this.state.isInnerEditorActive}
                    header={editorHeader}
                    onClose={() => this.setState({isInnerEditorActive: false})}
                    inputDataList={editorInputDataList}
                    onSubmit={this.onSubmitChanges.bind(this)}
                />
            </>
        )
    }
}
