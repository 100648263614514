import React from 'react';
import * as leaflet from "react-leaflet";
import InputRange from "react-input-range";
import ModalWindow from "../ModalWindow/ModalWindow";
import "react-input-range/lib/css/index.css";
import './Map.css';

const {Map, TileLayer, Marker, Circle} = leaflet;
const accessToken = 'pk.eyJ1IjoiZmlsaXBwMjgwOCIsImEiOiJjanJ6enh3NmUwMng2NDlycDZibDJoMmdrIn0.x2XkqMsSHXryZQqRPCp4aQ';
const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';


interface PropsType {
    location: Array<number>,
    isActive: boolean,
    onClose: Function,
    onSubmit: Function,
    isReadOnly?: boolean
}


export default class CustomMap extends React.Component<PropsType> {

    state = {location: []};

    get location(): Array<number> {
        if (this.state.location.length > 0) return this.state.location;
        return this.props.location || [];
    }

    set location(location: Array<number>) {
        this.setState({
            location
        })
    }

    onMapClick = (e: any) => {
        const {isReadOnly} = this.props;
        if (isReadOnly) return;

        const {lat, lng} = e.latlng;
        this.location = [lat, lng, this.location[2] || 5];
    };

    renderMarker = () => {
        const [latitude, longitude, radius] = this.location;
        if (!latitude || !longitude) return null;

        return (
            <React.Fragment>
                <Circle
                    center={[latitude, longitude]}
                    color = '#00b1e6'
                    fillColor = '#00caff'
                    weight = {1}
                    fillOpacity = {0.2}
                    radius={radius || 5}
                 />
                 <Marker position={[latitude, longitude]} />
        </React.Fragment>
    )
    };

    onSavePosition = (removePosition = false) => {
        this.props.onSubmit(removePosition ? false : this.location);
    };

    onClose = (e: any): void => {
        try {
            e.stopPropagation();
            e.preventDefault();
        } catch (e) {}

        this.location = [];
        this.props.onClose();
    };

    get hasChanges(): boolean {
        return this.state.location.length > 0;
    }

    renderSaveButton = () => {

        if (!this.hasChanges && this.props.location && this.props.location[3]) return (
            <div className="MapSaveButton MapPositionFromLabel">
                <i>This location is taken from the activator</i>
            </div>
        );

        if (!this.hasChanges && (!this.props.location || this.props.location[3])) return null;

        if (this.hasChanges) return (
            <div
                className="likeBtn MapSaveButton"
                onClick={() => this.onSavePosition(false)}>

                <i className="far fa-save"/>
                Save location
            </div>
        );

        return (
            <div
                className="likeBtn MapSaveButton"
                onClick={() => this.onSavePosition(true)}>

                <i className="far fa-trash-alt"/>
                Remove location
            </div>
        )
    };

    render() {
        const {isActive} = this.props;
        const [lat = 50.5, lng = 30.5, rad = 50] = this.location;
        if (!isActive) return null;

        return (
            <ModalWindow isActive={isActive} onClose={(e: any) => this.onClose(e)}>
                <Map className='centered'
                     center={[lat, lng]}
                     onClick={this.onMapClick}
                     zoom={16}>

                    <TileLayer access_token={accessToken}
                               url={tileUrl}/>

                    {this.renderMarker()}
                </Map>

                <div className="MapSlider">
                    <InputRange
                        onChange={(rad) => this.location = [this.location[0], this.location[1], rad as number]}
                        minValue={1}
                        maxValue={1000}
                        formatLabel={val => val < 1000 ? `${val}m` : `${val / 1000}km`}
                        value={rad || 50}
                    />
                </div>

                {this.renderSaveButton()}
            </ModalWindow>
    )
    }

}