import React from "react";
import './Activator/Activator.css';
import './Option.css';
import ServerManager from "../../managers/ServerManager";
import InfoManager from "../../managers/InfoManager";
import Utils from "../../managers/Utils";
import PopupMenu from "../PopupMenu";

const dev = Utils.devMode;
const playURL = `${dev ? 's' : 'p'}.iroll.de/`;


export default class PublicIdMenu extends React.Component {

    onCopy(fullLink = false) {
        const {object} = this.props;

        if (object.publicId) {
            InfoManager.copyToClipboard(fullLink ? `${playURL}${object.publicId}` : object.publicId);
            InfoManager.showMsg(fullLink ? 'Link copied' : 'Public Id copied to clipboard');
        } else {
            InfoManager.showMsg('Object has not Public Id');
        }
    }

    onShowQrcode() {
        const {object} = this.props;

        if (object.publicId) {
            InfoManager.getAndShowQRCode(`${playURL}${object.publicId}`, object.publicId + '.svg');
        } else {
            InfoManager.showMsg('Object has not Public Id');
        }
    }

    onRemove() {
        const {object} = this.props;

        InfoManager.confirmDialog('Are you sure ?').then(() => {
            ServerManager.instance.setPublicId(object.objectId, false).then();
        }).catch(() => {});
    }

    onEdit() {
        const {object} = this.props;

        InfoManager.waitTextDialog('Enter public id', object.publicId || '' , '6 symbols')
            .then((publicId) => {
                ServerManager.instance.setPublicId(object.objectId, publicId).then(
                    answer => {
                        InfoManager.showMsg(answer.status ? 'Public Id was saved' : answer.msg || 'Server error');
                    }

                );
            })
            .catch(()=>{});
    }

    render() {

        return (
            <PopupMenu className='ItemShortMenu PublicIdMenu'
                       iconClassName='ActivatorMenuIcon likeBtn fas fa-bars PublicIdMenuButton'>
                <i className="likeBtn far fa-trash-alt" onClick={() => this.onRemove()}
                   title='Remove Public Id'/>
                <i className="likeBtn fas fa-pencil-alt"  onClick={() => this.onEdit()}
                   title='Enter Public Id manually'/>
                <i className="likeBtn fas fa-qrcode"  onClick={() => this.onShowQrcode()}
                   title='Show object`s QR-Code'/>
                <i className="likeBtn fas fa-link"  onClick={() => this.onCopy(true)}
                   title='Copy Public Id link'/>
                <i className="likeBtn far fa-clipboard" onClick={() => this.onCopy()}
                   title='Copy Public Id to clipboard'/>
            </PopupMenu>
        );

    }

}
