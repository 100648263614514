import React from "react";
import PendingAction from "./PendingAction";


export default class PendingActionList extends React.Component {

    render() {
        const {list, object} = this.props;
        if (!list) return null;

        return (
            <div className='PendingActionList'>
                {
                    list.map((action, i) => <PendingAction {...{action, isActive: i===0, object, key: action.data.id}} />)
                }
            </div>
        )
    }

}


